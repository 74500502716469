import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ChartOptions, ChartType} from "chart.js";
import {Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet} from "ng2-charts";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseService} from "../../../shared/services/course.service";
import {ErrorHandlerService} from "../../../shared/services/error-handler.service";
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-peer-ranking',
  templateUrl: './peer-ranking.component.html',
  styleUrls: ['./peer-ranking.component.scss']
})
export class PeerRankingComponent implements OnInit {
 // @ts-ignore
 subscriptions: Subscription = [];
 isDemo =false;

 totalCorrect = 0;
 totalIncorrect = 0;
 totalOmitted = 0;
 totalQuizzes= 0;
 quizCompleted= 0;
 quizSuspended= 0;
 corr_corr = 0;
 corr_incorr = 0;
 incorr_incorr = 0;
 incorr_corr = 0;
 bestSubject: any;
 worstSubject: any;
 ifAdmin = false;
 subscribedCourses: any;

 contentLoader = false;
 basicData: any;
 basicOptions: any;

 pointersColorClasses = ['eup-green-pointer','eup-red-pointer','eup-blue-pointer','eup-purple-pointer'];

 chartType: ChartType = 'pie';
 // search = faSearch;
 // sortAmount = faSortAmountDown;
 pieChartOptions: ChartOptions = {
   responsive: true,
 };
 chartColors: Array<any> = [
   {
     backgroundColor: [
       '#61ce87',
       '#e14f6a',
       '#389cf2',
       '#7583f0'
     ]
   }
 ];

 pieChartLabels: Label[] = [['Correct to Correct'], ['Correct to Incorrect'], ['Incorrect to Correct'], ['Incorrect to Incorrect']];

 pieChartData: SingleDataSet = [20, 16, 36, 40];
 pieChartType: ChartType = 'pie';
 pieChartLegend = false;
 pieChartPlugins = [];
 cardLabels = [
   {label: 'Correct to Correct', value: 12, bgClass: 'eup-green-card'},
   {label: 'Correct to Incorrect', value: 2, bgClass: 'eup-red-card'},
   {label: 'Incorrect to Correct', value: 14, bgClass: 'eup-blue-card'},
   {label: 'Incorrect to Incorrect', value: 20, bgClass: 'eup-purple-card'},
 ];

 pieChartLabels1: Label[] = [['Total Questions'], ['Used  Questions'], ['Unused Questions']];
 pieChartData1: SingleDataSet = [];
 cardLabels1 = [
   {label: 'Total Questions', value: 12, bgClass: 'eup-green-card'},
   {label: 'Used Questions', value: 2, bgClass: 'eup-red-card'},
   {label: 'Unused Questions', value: 14, bgClass: 'eup-blue-card'}
 ];

 totalCorrectPercentage: any = 40;
 totalUsedPercentage: any = 20;

 tooltipContent="You got a score that is higher than 80% of course subscribers and 20% of course subscribers got a score that is higher than your score.";

 isPeerLoaded = false;
 isCategoryLoaded = false;

 userRank = 0
 peerRank = 0

 xUserValue = 0
 yUserValue = 0

 xPeerValue = 0

 avgQuizScore = 0
 avgPeerQuizScore = 0

 innerWidth: any;
 @HostListener('window:resize', ['$event'])

 barData: any;
 barOptions: any;

 selectedCategory = 'Subject'
 categories: any = []
 myScores: any = []
 peerScores: any = []
 allCategories: any = []
 tempCategories: any = []

 @ViewChild('myChart') barChart: Chart; 
 loader = false
 peerLoader = false;
 categoryLoader = false;

 paginationValue = 0

 performanceOptions = ['Overall', 'Peer Ranking', 'Analysis']

 selectedOption = 'Overall'

onResize(event) {
 this.innerWidth = window.innerWidth;
 if(this.innerWidth < 750){
   document.getElementById('eup_username').innerText = '';
   document.getElementById('eup-navbar-heading').innerText=''
 }
}
removeMouseOptions(e:any){
 e.preventDefault()
}
 constructor(private confirmationService: ConfirmationService,private adminService: AdminService,private globalService: GlobalService,private route:ActivatedRoute, private router:Router, private courseService: CourseService, private errorHandler: ErrorHandlerService) {
   document.addEventListener('contextmenu', this.removeMouseOptions);
   document.onkeydown = function(e) {
     // @ts-ignore
     if(event.keyCode == 123) {
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
     return false;
     }
     if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
     return false;
     }
     }
  }
 ngOnDestroy(): void {
   document.removeEventListener('contextmenu', this.removeMouseOptions);
   document.onkeydown = null;
   this.subscriptions.forEach(item => item.unsubscribe());
 }

 courseId: any = [];
 ngOnInit(): void {
  let index = 0;
  if (localStorage.getItem('subscriptions')) {
    this.subscribedCourses = JSON.parse(localStorage.getItem('subscriptions'))
    this.courseId = this.subscribedCourses.map((item: any) => item.courseID)

    if (sessionStorage.getItem('userSelectedCourse')) {
      index = this.subscribedCourses.findIndex((item: any) => item.courseName == sessionStorage.getItem('userSelectedCourse'))
    }
  }
   this.innerWidth = window.innerWidth;
   if(this.innerWidth>750){
   document.getElementById('eup-navbar-heading').innerText = 'Overall Performance';
 }else{
   document.getElementById('eup-navbar-heading').innerText = '';
 }
   if(localStorage.getItem("ifAdmin")){
     this.ifAdmin = JSON.parse(localStorage.getItem("ifAdmin"))
   }
   this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
   monkeyPatchChartJsTooltip();
   monkeyPatchChartJsLegend();

   if(localStorage.getItem('Id') != '-1'){
    if (this.subscribedCourses.length > 1) {
      this.handleSingleCourse(this.courseId[index]);
    } else {
      this.loader = true
      this.peerLoader = true
      this.categoryLoader = true
      this.getPeerPerformanceStats()
      this.getPerformanceCategoriesGraph()
    }
   }else{
     this.isDemo = true;
     this.getDemoValues();
   }
   // @ts-ignore
   this.subscriptions.push(
     this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
       if(res.status){

       }else{
         this.errorHandler.AutoLogout();
       }
     },
       error => {
         this.errorHandler.handleError(error);
       })
   );

   this.generateBarChart()
 }

 getPerformanceCategoriesGraph() {
   this.courseService.getPerformanceCategoriesGraph(this.selectedCategory, this.courseId).subscribe((res: any) => {
     this.allCategories = res.categories
     this.tempCategories = res.categories
     this.bestSubject = res.bestSubject
     this.worstSubject = res.worstSubject

     this.isCategoryLoaded = true
     this.categoryLoader = false
     if (this.isPeerLoaded) {
       this.loader = false
     }

     this.allCategories = this.tempCategories.slice(0, 10)

     if (this.allCategories.length > 0) {
       for (let i = 0; i < this.allCategories.length; i++) {
         this.categories.push(this.allCategories[i].title)
 
         let mycorrect = this.allCategories[i].user_correct
         let mytotal = this.allCategories[i].user_total
         this.myScores.push(((mycorrect / mytotal) * 100).toFixed(2))
 
         mycorrect = this.allCategories[i].peer_correct
         mytotal = this.allCategories[i].peer_total
         this.peerScores.push(((mycorrect / mytotal) * 100).toFixed(2))
       }

       setTimeout(() => {
         //@ts-ignore
         this.barChart.chart.destroy();
         this.generateBarChart()
       }, 100)
     }
   })
 }

 searchCategories(event: any) {
   if (this.tempCategories.length > 0) {
     this.categories = []
     this.myScores = []
     this.peerScores = []
     if (event.target.value != '') {
       this.allCategories = this.tempCategories.filter(item => item.title.toLowerCase().includes(event.target.value.toLowerCase()))
     }
     else {
       this.allCategories = JSON.parse(JSON.stringify(this.tempCategories))
       this.allCategories = this.tempCategories.slice(0, 10)
     }

     for (let i = 0; i < this.allCategories.length; i++) {
       this.categories.push(this.allCategories[i].title)

       let mycorrect = this.allCategories[i].user_correct
       let mytotal = this.allCategories[i].user_total
       this.myScores.push(((mycorrect / mytotal) * 100).toFixed(2))

       mycorrect = this.allCategories[i].peer_correct
       mytotal = this.allCategories[i].peer_total
       this.peerScores.push(((mycorrect / mytotal) * 100).toFixed(2))
     }

     setTimeout(() => {
       //@ts-ignore
       this.barChart.chart.destroy();
       this.generateBarChart()
     }, 100)
   }
 }

 paginateCategories(event: any) {
   let count = event.first
   for (let i = event.first; i < this.tempCategories.length; i++) {
     if (!this.tempCategories[i]) {
       break;
     }
     count ++
     if (count % 10 == 0) {
       break;
     }
   }

   this.allCategories = this.tempCategories.slice(event.first, count)

   this.categories = []
   this.myScores = []
   this.peerScores = []
   if (this.allCategories.length > 0) {
     for (let i = 0; i < this.allCategories.length; i++) {
       this.categories.push(this.allCategories[i].title)

       let mycorrect = this.allCategories[i].user_correct
       let mytotal = this.allCategories[i].user_total
       this.myScores.push(((mycorrect / mytotal) * 100).toFixed(2))

       mycorrect = this.allCategories[i].peer_correct
       mytotal = this.allCategories[i].peer_total
       this.peerScores.push(((mycorrect / mytotal) * 100).toFixed(2))
     }

     setTimeout(() => {
       //@ts-ignore
       this.barChart.chart.destroy();
       this.generateBarChart()
     }, 100)
   }
 }

 changeCategory(value: any) {
   this.selectedCategory = value
   this.categories = []
   this.myScores = []
   this.peerScores = []
   this.loader = true
   this.categoryLoader = true
   this.getPerformanceCategoriesGraph()
 }

 generateBarChart() {
   this.barData = {
     labels: this.categories,
     datasets: [
         {
           backgroundColor: '#D2D5DD',
           hoverBackgroundColor: '#D2D5DD',
           borderColor: '#D2D5DD',
           barThickness: 20,
           data: this.myScores
         },
         {
           backgroundColor: '#61ce87',
           hoverBackgroundColor: '#61ce87',
           borderColor: '#61ce87',
           barThickness: 20,
           data: this.peerScores
         }
     ]
   };

   this.barOptions = {
     legend: {
       display: false
     },
     scales: {
       xAxes: [{
         ticks: {
           color: '#495057'
         }
       }],
       yAxes: [{
         ticks: {
           color: '#495057',
           beginAtZero: true,
           steps: 10,
           stepValue: 1,
           max: 100
         }
       }]
     }
   };
 }



 makeGraph(peerAvgScore:any,avgScore:any)
   {

    let backgroundColor:any;
    let backgroundColor1:any;
     if (avgScore>=55)
     {
       backgroundColor1='rgba(92, 166, 239,.5)';
       backgroundColor='rgba(134, 207, 131,.5)'

     }
     else if (avgScore<55)
     {
       backgroundColor1='rgba(92, 166, 239,.5)';
       backgroundColor='rgb(237, 28, 36,.5)';
     }


     this. basicData={
       labels: ["Peer","You",''],
        datasets: [
          {
            barPercentage: 0.5,
            borderRadius:10,
            label: "Current Score Overall",
            //backgroundColor: "bl86CF83ue",
            opacity:.6,
            data: [peerAvgScore,avgScore,0],
            backgroundColor: [

             backgroundColor1,
                backgroundColor


            ],

          },
          {
            type: 'line',
            label: "Passing Peer Average",

            borderColor: '#86CF83',

            data:[50,50,50],
            //backgroundColor:"green",
            fill: false,


          }
        ]
      };

      this.basicOptions={
        legend: {
          display: false,

          //usePointStyle: true,
         // align:'left',

        },
        gridLines:{
            display:false,
        },
        title: {
          display: false,

        },
        scales: {

          yAxes: [
            {

              display: true,
              scaleLabel: {

                show: false,
              },
              ticks: {
                beginAtZero: true,
                stepSize: 50,
                max: 100 ,
                min: 0,
              }
            }
          ],
          xAxes: [
            {
                lineThickness: 0,
              ticks: {
                display: true,
                beginAtZero: 0
              }
            }
          ]
        }
      };

   }





 confirm(event: Event) {
   this.confirmationService.confirm({
       target: event.target,
       message: 'All of your data will be reset. Are you sure that you want to proceed?',
       icon: 'pi pi-exclamation-triangle',
       accept: () => {
         if(localStorage.getItem('Id')!= '-1'){
           //confirm action
           // @ts-ignore
 }
       },
       reject: () => {
           //reject action
       }
   });
}

getDemoValues(){
 this.subscribedCourses = ['Free Trail'];
       this.bestSubject = 'Pharmacology';
       this.worstSubject = 'Medicine';
       this.totalCorrect = 5;
       this.totalIncorrect = 2;
       this.totalOmitted = 3;
       this.totalQuizzes = 3;
       this.quizCompleted = 2;
       this.quizSuspended = 1;
       this.pieChartData = [3, 2, 3,2]
       this.corr_corr =3;
       this.corr_incorr = 2;
       this.incorr_corr = 3;
       this.incorr_incorr = 2;
       this.cardLabels[0].value = this.corr_corr;
       this.cardLabels[1].value = this.corr_incorr;
       this.cardLabels[2].value = this.incorr_corr;
       this.cardLabels[3].value = this.incorr_incorr;
       this.totalCorrectPercentage = 60;
       this.totalUsedPercentage = 80;

       this.pieChartData1 = []
       this.pieChartData1.push(10);
       this.pieChartData1.push(8);
       this.pieChartData1.push(10 - 8);
       this.cardLabels1[0].value = 10;
       this.cardLabels1[1].value = 8;
       this.cardLabels1[2].value = 10 - 8;
       this.makeGraph(55.1, 65.3);
}

 getPeerPerformanceStats() {
   this.subscriptions.push(
     this.courseService.getPeerPerformanceStats(this.courseId).subscribe((res: any) => {
       this.userRank = res.userRank.toFixed(0)
       this.peerRank = res.peerRank.toFixed(0)

       this.isPeerLoaded = true
       this.peerLoader = false
       if (!this.categoryLoader) {
        this.loader = false
       }

       this.peerRank = 50

       this.xUserValue = 4.36 * this.userRank

       this.xPeerValue = 4.36 * this.peerRank


       let value = 0, greater = false
       if (this.userRank > 50) {
         greater = true
       }

       if (this.userRank >= 0 && this.userRank < 5) {
         value = this.userRank - 0
         this.yUserValue = 203 - (value * 1)
       }
       else if (this.userRank >= 5 && this.userRank < 10 || this.userRank > 95 && this.userRank <= 100) {
         if (greater) {
           value = this.userRank - 95
           this.yUserValue = 198 + (value * 1)
         }
         else {
           value = this.userRank - 5
           this.yUserValue = 198 - (value * 3)
         }
       }
       else if (this.userRank >= 10 && this.userRank < 15 || this.userRank > 90 && this.userRank <= 95) {
         if (greater) {
           value = this.userRank - 90
           this.yUserValue = 181 + (value * 3)
         }
         else {
           value = this.userRank - 10
           this.yUserValue = 181 - (value * 5)
         }
       }
       else if (this.userRank >= 15 && this.userRank < 20 || this.userRank > 85 && this.userRank <= 90) {
         if (greater) {
           value = this.userRank - 85
           this.yUserValue = 156 + (value * 5)
         }
         else {
           value = this.userRank - 15
           this.yUserValue = 156 - (value * 6)
         }
       }
       else if (this.userRank >= 20 && this.userRank < 25 || this.userRank > 80 && this.userRank <= 85) {
         if (greater) {
           value = this.userRank - 80
           this.yUserValue = 126 + (value * 6)
         }
         else {
           value = this.userRank - 20
           this.yUserValue = 126 - (value * 6)
         }
       }
       else if (this.userRank >= 25 && this.userRank < 30 || this.userRank > 75 && this.userRank <= 80) {
         if (greater) {
           value = this.userRank - 75
           this.yUserValue = 96 + (value * 6)
         }
         else {
           value = this.userRank - 25
           this.yUserValue = 96 - (value * 5)
         }
       }
       else if (this.userRank >= 30 && this.userRank < 35 || this.userRank > 70 && this.userRank <= 75) {
         if (greater) {
           value = this.userRank - 70
           this.yUserValue = 71 + (value * 5)
         }
         else {
           value = this.userRank - 30
           this.yUserValue = 71 - (value * 5)
         }
       }
       else if (this.userRank >= 35 && this.userRank < 40 || this.userRank > 65 && this.userRank <= 70) {
         if (greater) {
           value = this.userRank - 65
           this.yUserValue = 46 + (value * 5)
         }
         else {
           value = this.userRank - 35
           this.yUserValue = 46 - (value * 3)
         }
       }
       else if (this.userRank >= 40 && this.userRank < 45 || this.userRank > 60 && this.userRank <= 65) {
         if (greater) {
           value = this.userRank - 60
           this.yUserValue = 31 + (value * 3)
         }
         else {
           value = this.userRank - 40
           this.yUserValue = 31 - (value * 3)
         }
       }
       else if (this.userRank >= 45 && this.userRank < 50 || this.userRank > 55 && this.userRank <= 60) {
         if (greater) {
           value = this.userRank - 55
           this.yUserValue = 16 + (value * 3)
         }
         else {
           value = this.userRank - 45
           this.yUserValue = 16 - (value * 1)
         }
       }
       else if (this.userRank == 50 || this.userRank > 50 && this.userRank <= 55) {
         value = this.userRank - 50
         if (greater) {
           this.yUserValue = 11 + (value * 1)
         }
         else {
           this.yUserValue = 11
         }
       }

       this.avgQuizScore = res.avgQuizScore.toFixed(1)
       this.avgPeerQuizScore = res.avgQuizPeerScore.toFixed(1)

       this.makeGraph(res.avgQuizPeerScore.toFixed(1), res.avgQuizScore.toFixed(1));
     })
   )
 }

 handleSingleCourse(courseId: any){
    this.courseId = [courseId];
    this.loader = true;
    this.peerLoader = true;
    this.categoryLoader = true;
    this.isPeerLoaded = false;
    this.isCategoryLoaded = false;
    this.categories = []
    this.myScores = []
    this.peerScores = []
    this.getPeerPerformanceStats()
    this.getPerformanceCategoriesGraph()
  }
}
