import { Component, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { AutoLogout } from 'src/app/models/globals';

@Component({
  selector: 'app-library-feedback',
  templateUrl: './library-feedback.component.html',
  styleUrls: ['./library-feedback.component.scss']
})
export class LibraryFeedbackComponent implements OnInit {
// @ts-ignore
  subscriptions: Subscription = [];

  displayModal = false;
  editNoteValue: any;
  feedbacks = [];

  NumofRows = [
    {numOfRows: 5},
    {numOfRows: 10},
    {numOfRows: 15},
    {numOfRows: 20},
    {numOfRows: 25}
];

  selectedNumber: any;
  loader = false;

  constructor( private quizService: QuizServiceService) { }

  ngOnInit(): void {
    document.getElementById('eup-navbar-heading').innerText = 'Notes';
    this.selectedNumber = 5
    this.getFeedbacks()
  }

  getFeedbacks(){
    this.loader = true;
    this.subscriptions.push(
      this.quizService.getArticleFeedbacks().subscribe((res: any) => {
          this.feedbacks = res.feedbacks;
          this.loader = false;
        },
        (err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  selectNum(num: any): void{
    this.selectedNumber = num.numOfRows;
  }
  
  viewNote(note: any){
    this.editNoteValue = note.feedback;
    this.displayModal = true;
  }
}
