import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Editor from '../../../assets/ckeditor/build/ckeditor';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-medlibrary',
  templateUrl: './medlibrary.component.html',
  styleUrls: ['./medlibrary.component.scss']
})
export class MedlibraryComponent implements OnInit, OnDestroy {

  notebookEditor: any;
  ckEditorNotebook: any

  articleName:null;
  medicalLibrary:any;
  subjectName:null;
  subjectID = null;
  articleToView:any;
  showAddForm =false;
  selectedArticle :any;
  editArticleFlag =false;
  loader=true;
  selectedTopic:any;
  showDeleteDialog = false;
  showEditbuttons = false;
  articleExists = false;

  courses: any = [
    {
      "courseID": 115014, 
      "courseName": "USMLE Step 1"
    },
    {
      "courseID": 115015, 
      "courseName": "USMLE Step 2 CK"
    },
    {
      "courseID": 135631, 
      "courseName": "USMLE Step 3"
    }
  ]
  selectedCourse = 115014;

  showImage = ''
  displayImg = false;
  showType = 0;

  displayRelated = false;
  allArticles: any = [];
  selectedRelatedArticle: any;
  miniLoader = false;

  constructor(private userService:UserService) { }

  async ngOnInit(): Promise<void> {
    if (sessionStorage.getItem('medicalLibrary')) {
      const course = sessionStorage.getItem('medicalLibrary');
      this.selectedCourse = JSON.parse(course);
    }

    sessionStorage.setItem('medicalLibrary', JSON.stringify(this.selectedCourse));
   
    await this.getMedicalLibrary(null);
  }

  async changeArticles() {
    this.medicalLibrary = null;
    this.articleExists = false;

    if (this.selectedCourse == 135631) {
      this.selectedCourse = 115015;
    }

    sessionStorage.setItem('medicalLibrary', JSON.stringify(this.selectedCourse));
    await this.getMedicalLibrary(null);
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('medicalLibrary');
  }

  addForm()
  {
    this.showAddForm=true
    this.showEditbuttons=false;
    this.subjectName =null;
    this.subjectID = null;
    this.articleName=null;
    this.editArticleFlag=false;
    this.runQuill()
    
  }
  getMedicalLibrary(id: any)
  { 
   this.loader =true;

    return new Promise((resolve, reject) => {
      {
        const data = {
          courseId: this.selectedCourse
        }
        this.userService.getmedicalLibrary(data).subscribe({
          next:(res:any) => {

            this.medicalLibrary =  res.medicalLibrary

            try {
              this.medicalLibrary = JSON.parse(res.medicalLibrary)
            } catch (e) {
              this.medicalLibrary = res.medicalLibrary
            }

            this.medicalLibrary.sort((a: any, b: any) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime();
            });        
            for (let i = 0; i < this.medicalLibrary.length; i++) {
              this.medicalLibrary[i].subTopics.sort((a: any, b: any) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });        
            }

          
            if ( this.medicalLibrary.length >0){
              this.checkIfArticleExists()
              if (id) {
                let parentArticle = null;
                let childArticle = null;
                for (let i = 0; i < this.medicalLibrary.length; i++) {
                  parentArticle = this.medicalLibrary[i];
                  childArticle = this.medicalLibrary[i]?.subTopics.filter((item: any) => item.articleId == id);
                  if (childArticle.length > 0) {
                    childArticle = childArticle[0]
                    break;
                  }
                }
                this.activeMainCategory(parentArticle);
                this.activeSubcategory(childArticle, parentArticle?.subTopics, parentArticle)
              } else {
                this.activeMainCategory(this.medicalLibrary[0]);
                this.activeSubcategory(this.medicalLibrary[0]?.subTopics[0], this.medicalLibrary[0]?.subTopics, this.medicalLibrary[0])
              }
            }
            this.showAddForm =false;
            this.loader = false;
            resolve(true)
           
          },
          error:(e)=>{
            this.loader = false;
            console.log(e)
          }
        })
      }
    })
   

  }

  activeMain(category)
  {

    this.medicalLibrary.forEach((element:any) => {
      element.status = false;
    });
    category.status = true;
 
  }
  activeMainCategory(category)
  {
    this.medicalLibrary.forEach((element:any) => {
      if (element.article_id !== category.article_id) {
        element.status = false;
      }
    });
    category.status = !category.status;
    this.subjectName = category.title;
    this.subjectID = category.articleId;
  }
  activeSubcategory(subCat ,subTopics , topic) {
    this.selectedTopic = topic
    subTopics.forEach((element:any) => {
      element.status = false;
    });
    subCat.status = true;
    subCat.subTopics?.sort((a: any, b: any) => a.orderBy - b.orderBy);

   this.showAddForm =false;

   this.selectedArticle = subCat;
   this.selectedArticle.content = this.addIDsToFigure(this.selectedArticle.content, false);
   this.selectedArticle.content = this.replaceTableWithLink(this.selectedArticle.content, false);
   try {
    this.selectedArticle.relatedArticles = JSON.parse(this.selectedArticle.relatedArticles);
  } catch (e) {}
   this.articleToView = this.selectedArticle.content;

    this.showEditbuttons =true;
    setTimeout(() => {
      this.scrollToHeading('article-content');
    }, 25)
  }

  runQuill() {
 

    // Destroy already selected CKEditor //
    if (this.ckEditorNotebook) {
      if (this.ckEditorNotebook?.state != 'destroyed') {
        this.ckEditorNotebook.destroy() 
      }
    }

    // Initializing and Configuring new CKEditor //
    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorNotebook"), {
      }).then((editor: any) => {
        this.ckEditorNotebook = editor
        // editor.setData(this.notebookText.replace(/(<br>)/g, "\n"))
        // editor.model.document.selection.on('change', (event) => {
        //   if (event.source._ranges.length > 0) {
        //     this.handleSelection(event.source._ranges[0].start.path)
        //     this.editorPosition = editor.model.document.selection.getFirstPosition();
        //   }
        // })
        
        setTimeout(() => {
          document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
        }, 100)
      })
    }, 100)
  }


  addArticle()
  {
    let articleObject ;

    this.loader =true;
    let subheading = this.extractHeadingsFromHtml(this.ckEditorNotebook.getData())

    articleObject = {
      subjectId : this.subjectID,
      subejectTitle :this.subjectName , 
      articleName : this.articleName,
      parentID:null,
      subcategories: subheading,
      content:this.ckEditorNotebook.getData(),
      courseId: this.selectedCourse
    }

    articleObject.content = this.addIDsToSubcategories(articleObject.content);

    this.userService.addArticle(articleObject).subscribe({
      next:(res:any)=> {
        this.getMedicalLibrary(null)
        this.loader =false;
      },
      error:(e) => {
        this.loader =false;
      }
    })
  }

  extractHeadingsFromHtml(htmlString: string): string[] {
    // Parse the HTML string into a document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    // Get all h3 elements
    const h3Elements = doc.querySelectorAll('h3');
  
    // Extract the text content of each h3 element and return as an array
    const headings: any[] = [];
    let count = 0;
    h3Elements.forEach((element) => {
      if (element.textContent?.trim() !== '') {
        headings.push({
          title: element.textContent?.trim() || '',
          order: count
        });
        count += 1;
      }
    });
  
    return headings;
  }

  addIDsToSubcategories(content: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const headers = doc.querySelectorAll('h3');

    let count = 0;
    headers.forEach((header) => {
      if (header.innerHTML !== '&nbsp;') {
        const id = `heading-${count += 1}`;
        header.id = id;
      }
    });

    return doc.body.innerHTML;
  }

  addIDsToFigure(content: any, value: boolean) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      if (value) {
        link.href = JSON.parse(JSON.stringify(link.id));
      } else {
        if (link.href !== 'javascript:void(0)') {
          link.id = link.href;
          link.setAttribute('data-id', link.href);
          link.href = JSON.parse(JSON.stringify('javascript:void(0)'));
        }
      }
    });

    return doc.body.innerHTML;
  }

  replaceTableWithLink(content: any, value: boolean) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    if (value) {
      const links = doc.querySelectorAll('a');

      links.forEach((link) => {
        if (link.id === 'showTable') {
          const tableHtml = link.getAttribute('data-id');
          if (tableHtml) {
            const figure = document.createElement('figure');
            figure.innerHTML = tableHtml;
            link.replaceWith(figure);
          }
        }
      });
    } else {
      const tables = doc.querySelectorAll('figure');
      if (tables.length > 0) {
        tables.forEach((table) => {
          const tableHtml = table.innerHTML;
          const anchor = document.createElement('a');
          anchor.id = 'showTable';
          anchor.innerText = 'Table';
          anchor.href = 'javascript:void(0)';
          anchor.setAttribute('data-id', tableHtml);
    
          table.replaceWith(anchor);
        })
      }
    }

    return doc.body.innerHTML;
  }

  handleLinkClick(event: Event) {
    const target = event.target as HTMLElement;
  
    if (target.tagName === 'A' && target.hasAttribute('data-id')) {
      const id = target.getAttribute('data-id');
      this.displayImg = true;
      this.showImage = null;
      
      setTimeout(() => {
        this.showImage = id; 
        this.showType = 0;
        if (id.includes('<table>')) {
          this.showType = 1;
        }
        if (id.includes('.mp4')) {
          this.showType = 2;
        }
        if (id.includes('.mp3')) {
          this.showType = 3;
        }
      }, 2000)
    }
  }

  scrollToHeading(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  checkSubject(event)
  {
    if (!event.value)
    {
      this.subjectID=null
    }
  }

  editArticle()
  {
    this.editArticleFlag=true;
    this.showAddForm=true;
    this.articleName = this.selectedArticle.title
    this.selectedArticle.content = this.addIDsToFigure(this.selectedArticle.content, true);
    this.selectedArticle.content = this.replaceTableWithLink(this.selectedArticle.content, true);
   setTimeout(()=>{
    // this.runQuill()
   },50)

   
    // this.
     // Destroy already selected CKEditor //
     if (this.ckEditorNotebook) {
      if (this.ckEditorNotebook?.state != 'destroyed') {
        this.ckEditorNotebook.destroy() 
      }
    }

    // Initializing and Configuring new CKEditor //
    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorNotebook"), {
      }).then((editor: any) => {
        this.ckEditorNotebook = editor
        editor.setData(this.selectedArticle.content.replace(/(<br>)/g, "\n"))
        editor.model.document.selection.on('change', (event) => {
          if (event.source._ranges.length > 0) {
            // this.handleSelection(event.source._ranges[0].start.path)
            // this.editorPosition = editor.model.document.selection.getFirstPosition();
          }
        })
        
        setTimeout(() => {
          document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
        }, 100)
      })
    }, 100)
  }

  updateArticle(){

    this.loader =true;
   
    let subheading = this.extractHeadingsFromHtml(this.ckEditorNotebook.getData())

    var articleObject = {
      subjectId : this.selectedTopic.articleId,
      subejectTitle :this.subjectName , 
      articleName : this.articleName,
      articleId:this.selectedArticle.articleId,
      subcategories: subheading,
      content:this.ckEditorNotebook.getData()
    }

    articleObject.content = this.addIDsToSubcategories(articleObject.content);

    this.userService.updateArticle(articleObject).subscribe({
      next:async (res:any)=>{

        await this.getMedicalLibrary(articleObject.articleId)
        this.showAddForm =false;
        this.loader =false;
      
      },
      error:(e) => {
        this.loader =false;
      }
    })
  }
  showDeletePopup()
  {
    this.showDeleteDialog = true;
  }
  cancelDelete()
  {
    this.showDeleteDialog = false;
  }

  deleteArticle()
  {
    this.loader = true;
    var data = {
      articleId : this.selectedArticle.articleId,
      parentId: this.selectedArticle.parentId
    }
    

    this.userService.deleteArticle(data).subscribe({
      next:(res:any) => {
  
        this.loader = false;
        this.showDeleteDialog =false;

        this.articleToView= null;
        this.showEditbuttons=false
        this.getMedicalLibrary(null)
      },
      error:(e) => {
        console.log(e)
        this.loader = false;
      }
    })

  }

  checkIfArticleExists()
  {
    let articleCount= 0;
    this.articleExists =false;
    // this code is to search for articles 
    // this code will find if articles exists in topics
      this.medicalLibrary.map((subjects) => {
        if (subjects.subTopics?.length>0)
        {
       
          articleCount+=1
        }
      })

      if (articleCount>0)
      {
        this.articleExists =true;
      }
  }

  cancelEditing() {
    this.showAddForm = false;
    this.checkIfArticleExists();
    this.selectedArticle.content = this.addIDsToFigure(this.selectedArticle.content, false);
    this.selectedArticle.content = this.replaceTableWithLink(this.selectedArticle.content, false);
  }

  openRelatedArticles() {
    this.displayRelated = true;
    this.allArticles = [];
    for (let i = 0; i < this.medicalLibrary.length; i++) {
      for (let j = 0; j < this.medicalLibrary[i].subTopics.length; j++) {
        if (this.medicalLibrary[i].subTopics[j].articleId !== this.selectedArticle.articleId) {
          if (this.selectedArticle.relatedArticles) {
            if (!this.selectedArticle.relatedArticles.find((item: any) => item.articleId === this.medicalLibrary[i].subTopics[j].articleId)) {
              this.allArticles.push(this.medicalLibrary[i].subTopics[j])
            }
          } else {
            this.allArticles.push(this.medicalLibrary[i].subTopics[j])
          }
        }
      }
    }
  }

  addRelatedArticles() {
    if (this.selectedArticle.relatedArticles) {
      this.selectedArticle.relatedArticles.push({
        articleId: this.selectedRelatedArticle.articleId,
        title: this.selectedRelatedArticle.title,
        parentId: this.selectedRelatedArticle.parentId
      })
    } else {
      this.selectedArticle.relatedArticles = [
        {
          articleId: this.selectedRelatedArticle.articleId,
          title: this.selectedRelatedArticle.title,
          parentId: this.selectedRelatedArticle.parentId
        }
      ]
    }
    const data = {
      articleId: this.selectedArticle.articleId,
      relatedArticles: this.selectedArticle.relatedArticles 
    }
    this.miniLoader = true;
    this.userService.addRelatedArticles(data).subscribe({
      next:(res:any) => {
        this.miniLoader = false
        this.allArticles.splice(this.allArticles.findIndex((item: any) => item.articleId === this.selectedRelatedArticle.articleId), 1)
        this.selectedRelatedArticle = null;
      },
      error:(e) => {
        console.log(e)
        this.miniLoader = false;
      }
    })
  }

  removeRelatedArticles(index: any) {
    const removedArticle = this.selectedArticle.relatedArticles[index];
    this.allArticles.push(removedArticle);
    this.selectedArticle.relatedArticles.splice(index, 1);
    const data = {
      articleId: this.selectedArticle.articleId,
      relatedArticles: this.selectedArticle.relatedArticles 
    }
    this.miniLoader = true;
    this.userService.addRelatedArticles(data).subscribe({
      next:(res:any) => {
        this.miniLoader = false
        this.selectedRelatedArticle = null;
      },
      error:(e) => {
        console.log(e)
        this.miniLoader = false;
      }
    })
  }

  navigateToSearchedArticle(article: any) {
    const parentArticle = this.medicalLibrary.find((element) => element.articleId === article.parentId)
    const childArticle = parentArticle.subTopics.find((element) => element.articleId === article.articleId);
    this.activeMainCategory(parentArticle);
    this.activeSubcategory(childArticle, parentArticle.subTopics, parentArticle.title);
  }
}