import { Injectable } from '@angular/core';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";
import { TreeNode } from 'primeng/api';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class QuizServiceService {

  baseUrl = BASE_URL;

  questions = []

  constructor(private httpClient: HttpClient) { }
  getUserId(){
    return localStorage.getItem('Id');
  }

  getQuizDetails(quizId: any){
    return this.httpClient.get(this.baseUrl + `/getQuiz?quizId=${quizId}&userId=${this.getUserId()}`,
      {headers: createAuthorizationHeader()});
  }

  saveNotes(data:any){
    console.log("Saving Notes Here", data)
    return this.httpClient.post(this.baseUrl + '/addNotes', {data},
    {headers: createAuthorizationHeader()});
  }
  
  getSpecificQuestion(data: any){
    return this.httpClient.post(this.baseUrl + '/getSpecificQuestion' , {data},
    {headers: createAuthorizationHeader()});
  }
  saveFeedback(data:any){
    console.log("Saving Feedback Here", data)
    return this.httpClient.post(this.baseUrl + '/addFeedback' , {data},
    {headers: createAuthorizationHeader()});
  }

  submitAnswer(data:any){
    console.log("Submitting Answer Here", data)
    return this.httpClient.post(this.baseUrl + '/submitAnswer' , {data},
    {headers: createAuthorizationHeader()});
  }
  
  markUnmarkQuestion(data:any){
    console.log("Marking Unmarking Questions");
    return this.httpClient.post(this.baseUrl + '/markQuestion' , {data} ,
    {headers: createAuthorizationHeader()});
  }

  saveQuiz(data:any){
    console.log("Saving Quiz Here", data)
    return this.httpClient.post(this.baseUrl + '/saveQuiz' , {data},
    {headers: createAuthorizationHeader()});
  }

  insertHighlightText(data:any){
    return this.httpClient.post(this.baseUrl + '/insertHighlightText' , {data},
    {headers: createAuthorizationHeader()});
  }

  insertMultipleHighlightText(data:any){
    return this.httpClient.post(this.baseUrl + '/insertMultipleHighlightText' , {data},
    {headers: createAuthorizationHeader()});
  }

  getPreviousQuizzes(data){
    return this.httpClient.post(this.baseUrl + '/getPreviousQuizzes' , {data},
    {headers: createAuthorizationHeader()});
  }

  editQuizTitle(data: any){
    return this.httpClient.post(this.baseUrl + '/editQuizTitle' , {data},
    {headers: createAuthorizationHeader()});
  }

  deleteQuiz(data: any){
    return this.httpClient.post(this.baseUrl + '/deleteQuiz' , {data},
    {headers: createAuthorizationHeader()});
  }

  resumePrevQuizzes(quiz_id: any, viewMode: any){
    return this.httpClient.post(this.baseUrl + '/getResumeQuiz', {user: {userId: this.getUserId(), quizId: quiz_id}},
      {headers: createAuthorizationHeader()});
  }

  getQuizResults(quiz_id: any, user_id: any){
    return this.httpClient.post(this.baseUrl + '/getTestReporting' , {quiz: {quizId: quiz_id, userId: user_id}},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceByDateandTest(){
    return this.httpClient.post(this.baseUrl + '/getPerformanceGraphs' , { userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }
  
  getNotes(){
    return this.httpClient.post(this.baseUrl + '/getAllNotes' , { userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }

  getFeedbacks(){
    return this.httpClient.get(this.baseUrl + '/getAllFeedbacks' , 
    {headers: createAuthorizationHeader()});
  }

  editNotes(notes: any){
    return this.httpClient.put(this.baseUrl + '/editNotes' , {notes},
    {headers: createAuthorizationHeader()});
  }
  deleteNotes(noteId: any){
    return this.httpClient.post(this.baseUrl + '/deleteNotes' , { noteId: noteId},
    {headers: createAuthorizationHeader()});
  }
  getPercentageOthers(questionId: any){
    return this.httpClient.post(this.baseUrl + '/getPercentageOthers' , {questionId: questionId},
    {headers: createAuthorizationHeader()});
  }

  searchQuestions(data: any){
    return this.httpClient.post(this.baseUrl + '/searchQuestions' , {data: data},
    {headers: createAuthorizationHeader()});
  }

  updateQuiz(data: any){
    return this.httpClient.post(this.baseUrl + '/updateQuiz' , {data: data},
    {headers: createAuthorizationHeader()});
  }

  updateQuestionStrikeThrough(data: any){
    return this.httpClient.post(this.baseUrl + '/updateQuestionStrikeThrough' , {data: data});
  }

  getQuestionStrikeThrough(data: any){
    return this.httpClient.post(this.baseUrl + '/getQuestionStrikeThrough' , {data: data});
  }

  getQuestionCategories(data: any){
    return this.httpClient.post(this.baseUrl + '/getQuestionCategories' , {data},
    {headers: createAuthorizationHeader()});
  }

  getUserQuizzes(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getUserQuizzes' , { data },
    {headers: createAuthorizationHeader()});
  }

  generateAITestAnalysis(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/generateAITestAnalysis' , { data },
    {headers: createAuthorizationHeader()});
  }

  generateAICustomQuiz(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/generateAICustomQuiz' , { data },
    {headers: createAuthorizationHeader()});
  }

  generateUSMLECustomQuiz(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/generateUSMLECustomQuiz' , { data },
    {headers: createAuthorizationHeader()});
  }

  generateUsingOpenAI(data: any) {
    return this.httpClient.post(this.baseUrl + '/openai/generate', { data });
  }

  generateVoiceUsingOpenAI(data: any) {
    return this.httpClient.post(this.baseUrl + '/openai/generate-voice', { data });
  }

  getAWSS3CloudFrontLink(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getS3CloudFrontLink', { data });
  }

  getCategoriesStatistics(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getCategoriesStatistics' , { data },
    {headers: createAuthorizationHeader()});
  }

  getQuestionVideoContent(data: any) {
    return this.httpClient.post(this.baseUrl + '/getQuestionVideoContent' , { data },
    {headers: createAuthorizationHeader()});
  }

  getNotesLanguages() {
    return this.httpClient.get(this.baseUrl + '/new/getNotesLanguages',
    { headers: createAuthorizationHeader() });
  }

  updateQuestionFlashcards(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateQuestionFlashcards', { data },
    { headers: createAuthorizationHeader() });
  }
}



