import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-linked-questions',
  templateUrl: './linked-questions.component.html',
  styleUrls: ['./linked-questions.component.scss']
})
export class LinkedQuestionsComponent implements OnInit {

  firstQuestionId: any;
  secondQuestionId: any;
  message = ''
  linkedQuestions: any;
  loader = false;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.getLinkedQuestions();
  }

  getLinkedQuestions(){
    this.loader = true;
    this.adminService.getLinkedQuestions().subscribe((res: any) => {
      this.loader = false;
      res.linkedQuestions = res.linkedQuestions.filter(linkQuestion => linkQuestion.Priority == 0);
      this.linkedQuestions = res.linkedQuestions;
    })
  }

  messageColor = true;
  linkQuestions(){
    this.message = ''
    if(this.firstQuestionId && this.secondQuestionId){
    this.adminService.linkQuestions(this.firstQuestionId, this.secondQuestionId).subscribe((res: any) => {
      if(res.status){
        this.messageColor = true;
        this.message = res.message;
        this.firstQuestionId = '';
        this.secondQuestionId = '';
        this.getLinkedQuestions();
      }
      else{
        this.message = res.message;
        this.messageColor = false;
      }
  })
    }else{
      this.message = "Kindly add both question ID's"
      this.messageColor = false;
    }
  }


  unlinkQuesions(linkedQuestions: any){
    let data = []
    data.push(linkedQuestions.QuestionID);
    data.push(linkedQuestions.LinkedID)
    this.linkedQuestions = this.linkedQuestions.filter(linkQuestion => linkQuestion.LinkedID != linkedQuestions.LinkedID);
    this.adminService.deleteLinkedQuestions(data).subscribe((res: any) => {
      if(res.status){
        }
    })
  }

}
