import {HttpHeaders , HttpErrorResponse} from "@angular/common/http";


// export const BASE_URL = 'http://50.112.225.189';
// export const BASE_URL = 'https://test.pookidevs.com';
// export const BASE_URL = 'https://staging.elhusseinyusmleprep.com';
// export const BASE_URL = 'http://localhost:8000';
export const BASE_URL = 'https://dashboard.elhusseinyusmleprep.com';


export function createAuthorizationHeader(){
  const headers = new HttpHeaders();
  // @ts-ignore
  return headers.set('authorization', localStorage.getItem('token'));
}

// FOR QUIZ MODULE
export function  AutoLogout(err : any): void{
  const arr = [ 'getQuestionNUmbers','getCategories','lessonNumber', 'Id', 'CourseDetailsAPIResult', 'coursearray', 'CourseID', 'CompletedTopicsIds', 'topicID', 'userIDForReport', 'CurrentIndex', 'AllQuestions', 'token', 'lessonName', 'SubmittedQuestions', 'strikedThroughOptions', 'email','QuizId','NotesArray','LessonID','flaggedQuestion','quiz_time', 'subscriptions', 'isInstructor'];
  if (err instanceof HttpErrorResponse){
    if (err.status === 401){
      arr.forEach(i => {
        localStorage.removeItem(i);
      });
      window.location.href = '';
    }
  }
}


export const bloodValue = [
  {title: 'Erythrocyte count ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '4.3-5.9 million/mm3', siValue: '4.3-5.9 x 1012/L'},
      {title: 'Female', value: '3.5-5.5 million/mm3', siValue: '3.5-5.5 x 1012/L'},
    ]
  },
  {title: 'Erythrocyte sedimentation rate (Westergren) ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '0-15 mm/h', siValue: '0-15 mm/h'},
      {title: 'Female', value: '0-20 mm/h', siValue: '0-20 mm/h'},
    ]
  },
  {title: 'Hematocrit ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '41%-53%', siValue: '0.41-0.53'},
      {title: 'Female', value: '36%-46%', siValue: '0.36-0.46'},
    ]
  },
  {title: 'Hemoglobin, blood ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '13.5-17.5 g/dL', siValue: '135-175 g/L'},
      {title: 'Female', value: '12.0-16.0 g/dL', siValue: '120-160 g/L'},
    ]
  },
  {title: 'Hemoglobin A1c',  value: '≤6%', siValue: '≤42 mmol/mol',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Hemoglobin, plasma',  value: '<4 mg/dL', siValue: '<0.62 mmol/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Leukocyte count (WBC) ',  value: '4500-11,000/mm3', siValue: '4.5-11.0 x 109/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Neutrophils, segmented',  value: '54%-62%', siValue: '0.54-0.62',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Neutrophils, bands',  value: '3%-5%', siValue: '0.03-0.05',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Eosinophils',  value: '1%-3%', siValue: '0.01-0.03',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Basophils',  value: '0%-0.75%', siValue: '0.00-0.0075',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Lymphocytes',  value: '25%-33%', siValue: '0.25-0.33',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Monocytes',  value: '3%-7%', siValue: '0.03-0.07',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'CD4+ T-lymphocyte count',  value: '≥500/mm3', siValue: '≥0.5 x 109/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Platelet count',  value: '150,000-400,000/mm3', siValue: '150-400 x 109/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Reticulocyte count',  value: '0.5%-1.5%', siValue: '0.005-0.015',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'D-Dimer',  value: '≤250 ng/mL', siValue: '≤1.4 nmol/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Partial thromboplastin time (PTT)(activated)' ,  value: '25-40 seconds', siValue: '25-40 seconds',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Prothrombin time (PT)' ,  value: '11-15 seconds', siValue: '11-15 seconds',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Mean corpuscular hemoglobin (MCH)' ,  value: '25-35 pg/cell', siValue: '0.39-0.54 fmol/cell',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Mean corpuscular hemoglobin concentration (MCHC)' ,  value: '31%-36% Hb/cell', siValue: '4.8-5.6 mmol Hb/L',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Mean corpuscular volume (MCV)' ,  value: '80-100 μm3', siValue: '80-100 fL',
    subtitle: [{title: '', value: ''}]
  },
  {title: 'Plasma ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '25-43 mL/kg', siValue: '0.025-0.043 L/kg'},
      {title: 'Female', value: '28-45 mL/kg', siValue: '0.028-0.045 L/kg'},
    ]
  },
  {title: 'Red cell ', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '20-36 mL/kg', siValue: '0.020-0.036 L/kg'},
      {title: 'Female', value: '19-31 mL/kg', siValue: '0.019-0.031 L/kg'},
    ]
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },

]





export const urineBMI = [
  {title: 'Calcium', value: '100-300 mg/24 h', siValue: '2.5-7.5 mmol/24 h',
  subtitle: []
  },
  {title: 'Creatine clearance', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '97-137 mL/min', siValue: '97-137 mL/min'},
      {title: 'Female', value: '88-128 mL/min', siValue: '88-128 mL/min'},
    ]
  },
  {title: 'Osmolality', value: '50-1200 mOsmol/kg H2O', siValue: '50-1200 mmol/kg',
  subtitle: []
  },
  {title: 'Oxalate', value: '8-40 μg/mL', siValue: '90-445 μmol/L',
  subtitle: []
  },
  {title: 'Proteins, total', value: '<150 mg/24 h', siValue: '<0.15 g/24 h',
  subtitle: []
  },
  {title: '17-Hydroxycorticosteroids', value: '', siValue: '',
  subtitle: [
    {title: 'Male', value: '3.0-10.0 mg/24 h', siValue: '8.2-27.6 μmol/24 h'},
    {title: 'Female', value: '2.0-8.0 mg/24 h', siValue: '5.5-22.0 μmol/24 h'},
  ]
  },
  {title: '17-Ketosteroids, total', value: '', siValue: '',
  subtitle: [
    {title: 'Male', value: '8-20 mg/24 h', siValue: '28-70 μmol/24 h'},
    {title: 'Female', value: '6-15 mg/24 h', siValue: '21-52 μmol/24 h'},
  ]
  },
  {title: 'Body Mass Index (BMI)', value: 'Adult: 19-25 kg/m2', siValue: '',
  subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
];




export const Serum = [
  {title: 'Alanine aminotransferase (ALT)', value: '10-40 U/L', siValue: '10-40 U/L',
    subtitle: []
  },
  {title: 'Aspartate aminotransferase (AST)', value: '12-38 U/L', siValue: '12-38 U/L',
    subtitle: []
  },
  {title: 'Alkaline phosphatase', value: '25-100 U/L', siValue: '25-100 U/L',
    subtitle: [ ]
  },
  {title: 'Amylase', value: '25-125 U/L', siValue: '25-125 U/L',
    subtitle: []
  },
  {title: 'Bilirubin', value: '', siValue: '',
    subtitle: [
      {title: 'Total', value: '0.1-1.0 mg/dL', siValue: '2-17 μmol/L'},
      {title: 'Direct', value: '0.0-0.3 mg/dL', siValue: '0-5 μmol/L'},
    ]
  },
  {title: 'Calcium', value: '8.4-10.2 mg/dL', siValue: '2.1-2.6 mmol/L',
    subtitle: []
  },
  {title: 'Cholesterol', value: '', siValue: '',
    subtitle: [
      {title: 'Normal', value: '<200 mg/dL	', siValue: '<5.2 mmol/L'},
      {title: 'High', value: '>240mg/dL', siValue: '>6.2 mmol/L'},
      {title: 'HDL', value: '40-60 mg/dL', siValue: '1.0-1.6 mmol/L'},
      {title: 'LDL', value: '<160 mg/dL	', siValue: '<4.2 mmol/L'},

    ]
  },
  {title: 'Triglycerides', value: '', siValue: '',
    subtitle: [
      {title: 'Normal', value: '<150 mg/dL', siValue: '<1.70 mmol/L'},
      {title: 'Borderline', value: '151-199 mg/dL	',  siValue: '1.71-2.25 mmol/L'},
    ]
  },
  {title: 'Cortisol', value: '', siValue: '',
    subtitle: [
      {title: '0800 h	', value: '5-23 μg/dL	', siValue: '138-635 nmol/L'},
      {title: '1600 h	', value: '3-15 μg/dL	',  siValue: '82-413 nmol/L'},
      {title: '2000 h	', value: '<50% of 0800 h	',  siValue: 'Fraction of 0800 h: <0.50'},

    ]
  },
  {title: 'Creatine kinase', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '25-90 U/L	', siValue: '25-90 U/L'},
      {title: 'Female', value: '10-70 U/L	',  siValue: '10-70 U/L'},
    ]
  },
  {title: 'Creatinine', value: '0.6-1.2 mg/dL	', siValue: '53-106 μmol/L',
    subtitle: []
  },
  {title: 'Urea nitrogen', value: '7-18 mg/dL', siValue: '1.2-3.0 mmol/L',
    subtitle: []
  },
  {title: 'Electrolytes, serum', value: '', siValue: '',
    subtitle: [
      {title: 'Sodium (Na+)	', value: '136-146 mEq/L	', siValue: '136-146 mmol/L'},
      {title: 'Potassium (K+)	', value: '3.5-5.0 mEq/L	', siValue: '3.5-5.0 mmol/L' },
      {title: 'Chloride (Cl-)	', value: '95-105 mEq/L	', siValue: '95-105 mmol/L'},
      {title: 'Bicarbonate (HCO3-)	', value: '22-28 mEq/L	', siValue: '22-28 mmol/L'},
      {title: 'Magnesium (Mg2+)	', value: '1.5-2.0 mEq/L	', siValue: '0.75-1.0 mmol/L'}

    ]
  },
  {title: 'Ferritin', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '20-250 ng/mL', siValue: '20-250 μg/L'},
      {title: 'Female:	', value: '10-120 ng/mL	', siValue: '10-120 μg/L'}

    ]
  },
  {title: 'Follicle-stimulating hormone', value: '', siValue: '',
    subtitle: [

      {title: 'Male', value: '4-25 mIU/mL', siValue: '4-25 U/L' },
      {title: 'Female', value: '', siValue: '' },
      {title: 'premenopause', value: '4-30 mIU/mL	', siValue: '4-30 U/L' },
      {title: 'midcycle peak', value: '10-90 mIU/mL	', siValue: '10-90 U/L' },
      {title: 'postmenopause', value: '40-250 mIU/mL	', siValue: '40-250 U/L' },

    ]
  },
  {title: 'Glucose', value: '', siValue: '',
    subtitle: [


      {title: 'Fasting', value: '70-110 mg/dL	', siValue: '3.8-5.6 mmol/L' },
      {title: 'Random, non-fasting	', value: '<140 mg/dL	', siValue: '<7.7 mmol/L' },

    ]
  },
  {title: 'Growth hormone- arginine stimulation', value: '', siValue: '',
    subtitle: [
      {title: 'Fasting', value: '<5 ng/mL	', siValue: '<5 μg/L'},
      {title: 'Provocative stimuli	', value: '>7 ng/mL	', siValue: '>7 μg/L' },
    ]
  },

  {title: 'Iron', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '65-175 μg/dL	', siValue: '11.6-31.3 μmol/L'},
      {title: 'Female', value: '50-170 μg/dL	', siValue: '9.0-30.4 μmol/L' },
    ]
  },

  {title: 'Total iron-binding capacity	', value: '250-400 μg/dL	', siValue: '44.8-71.6 μmol/L',
    subtitle: [
    ]
  },

  {title: 'Transferrin', value: '200-360 mg/dL', siValue: '2.0-3.6 g/L',
    subtitle: [
    ]
  },
  {title: 'Lactate dehydrogenase', value: '45-200 U/L', siValue: '45-200 U/L',
    subtitle: [
    ]
  },

  {title: 'Luteinizing hormone', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '	6-23 mIU/mL', siValue: '6-23 U/L'},

    ]
  },

  {title: 'Luteinizing hormone', value: '', siValue: '',
    subtitle: [
      {title: 'Male', value: '	6-23 mIU/mL', siValue: '6-23 U/L'},

    ]
  },


  {title: 'Female', value: '', siValue: '',
    subtitle: [
      {title: 'follicular phase', value: '5-30 mIU/mL', siValue: '5-30 U/L'},
      {title: 'midcycle', value: '75-150 mIU/mL', siValue: '5-150 U/L'},
      {title: 'postmenopause', value: '30-200 mIU/mL', siValue: '30-200 U/L'},

    ]
  },

  {title: 'Osmolality', value: '275-295 mOsmol/kg', siValue: '4H2O	275-295 mOsmol/kg H2O',
    subtitle: [
    ]
  },

  {title: 'Intact parathyroid hormone (PTH)', value: '10-60 pg/mL', siValue: '10-60 ng/mL',
    subtitle: [
    ]
  },

  {title: 'Phosphorus (inorganic)', value: '3.0-4.5 mg/dL', siValue: '1.0-1.5 mmol/L',
    subtitle: [
    ]
  },


    {title: 'Prolactin (hPRL)', value: '', siValue: '',
      subtitle: [
        {title: 'Male', value: '<17 ng/mL', siValue: '<17 μg/L'},
        {title: 'Female', value: '<25 ng/mL', siValue: '<25 μg/L'},

      ]
    },


      {title: 'Proteins', value: '', siValue: '',
        subtitle: [
          {title: 'Total', value: '6.0-7.8 g/dL', siValue: '60-78 g/L'},
          {title: 'Albumin', value: '3.5-5.5 g/dL', siValue: '35-55 g/L'},
          {title: 'Globulin', value: '2.3-3.5 g/dL', siValue: '23-35 g/L'},

        ]
      },

      {title: 'Troponin I', value: '<0.04 ng/dL', siValue: '<0.04 μg/L',
        subtitle: [
        ]
      },

      {title: 'TSH', value: '0.4-4.0 μU/mL', siValue: '	0.4-4.0 μU/mL',
        subtitle: [
        ]
      },

      {title: 'Thyroidal iodine', value: 'uptake	8%-30% of administered dose', siValue: '24 h	0.08-0.30/24 h',
        subtitle: [
        ]
      },

      {title: 'Thyroxine (T4)', value: '5-12 μg/dL', siValue: '	64-155 nmol/L',
        subtitle: [
        ]
      },

      {title: 'Free (T4)', value: '	0.9-1.7 ng/dL', siValue: '12.0-21.9 pmol/L',
        subtitle: [
        ]
      },

      {title: 'Triiodothyronine (T3) (RIA)', value: '100-200 ng/dL', siValue: '1.5-3.1 nmol/L',
        subtitle: [
        ]
      },

      {title: 'Triiodothyronine (T3) (RIA) esin uptake', value: '25%-35%', siValue: '0.25-0.35',
        subtitle: [
        ]
      },

      {title: 'Uric acid', value: '3.0-8.2 mg/dL', siValue: '0.18-0.48 mmol/L',
        subtitle: [
        ]
      },

      {title: 'Immunoglobulins', value: '', siValue: '',
        subtitle: [
          {title: 'IgA', value: '76-390 mg/dL', siValue: '0.76-3.90 g/L'},
          {title: 'IgE', value: '0-380 IU/mL', siValue: '0-380 kIU/L'},
          {title: 'IgE', value: '0-300 IU/mL', siValue: '0-380 kIU/L'},
          {title: 'IgG', value: '650-1500 mg/dL', siValue: '6.5-15.0 g/L'},
          {title: 'IgM', value: '50-300 mg/dL', siValue: '0.5-3.0 g/L'},

        ]
      },

      {title: 'Gases, arterial blood (room air)', value: '', siValue: '',
        subtitle: [
          {title: 'pH', value: '7.35-7.45	[H+]', siValue: '36-44 nmol/L'},
          {title: 'Pco2', value: '33-45 mm Hg', siValue: '4.4-5.9 kPa'},
          {title: 'Po2', value: '75-105 mm Hg', siValue: '10.0-14.0 kPa'},

        ]
      },
      {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
];




export const CerebrospinalLabValues = [
  {title: 'Cell count', value: '0-5/mm^3', siValue: '0-5 x 10^6/L',
    subtitle: []
  },
  {title: 'Chloride', value: '118-132 mEq/L', siValue: '118-132 mmol/L',
    subtitle: []
  },
  {title: 'Gamma globulin', value: '3%-12% of total proteins', siValue: '0.03-0.12 of total proteins',
    subtitle: []
  },
  {title: 'Glucose', value: '40-70 mg/dL', siValue: '2.2-3.9 mmol/L',
    subtitle: []
  },
  {title: 'Pressure', value: '70-180 mm H2O', siValue: '70-180 mm H2O',
    subtitle: []
  },
  {title: 'Proteins, total', value: '<40 mg/dL', siValue: '<0.40 g/L',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
  {title: '', value: '', siValue: '',
    subtitle: []
  },
];








export function haltInteraction(flag : any): void{
  if (flag){
     // @ts-ignore
    document.getElementById('ymp-dashboard-subparent').style.zIndex = String(-1);
  }
  else {
     // @ts-ignore
    document.getElementById('ymp-dashboard-subparent').style.zIndex = 'auto'

  }

}
