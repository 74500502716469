import { Component, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { AutoLogout } from 'src/app/models/globals';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  // @ts-ignore
  subscriptions: Subscription = [];

  displayModal = false;
  editNoteValue: any;
  editNoteId: any;
  notes = [];

  NumofRows = [
    {numOfRows: 5},
    {numOfRows: 10},
    {numOfRows: 15},
    {numOfRows: 20},
    {numOfRows: 25}
];

  selectedNumber: any;
  loader = false;

  constructor( private quizService: QuizServiceService) { }

  ngOnInit(): void {
    document.getElementById('eup-navbar-heading').innerText = 'Notes';
    this.selectedNumber = 5
    this.getNotes()
  }

  getNotes(){
    this.loader = true;
    this.subscriptions.push(
      this.quizService.getFeedbacks().subscribe((res: any) => {
          this.notes = res.feedbacks;
          this.loader = false;
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  selectNum(num: any): void{
    this.selectedNumber = num.numOfRows;
  }

  
  viewNote(note: any){
    this.editNoteValue = note.feedback;
    this.editNoteId = note.ideup_user_feedbacks;
    this.displayModal = true;
  }

  


}
