<div class="overallPerformance_root">
  <div class="overallPerformance_parent">
      <div class="performance_options">
        <span *ngFor="let option of performanceOptions" [ngClass]="{'active': selectedOption == option}" (click)="changeOption(option)">{{option}}</span>
      </div>

      <div class="mb-4" *ngIf="subscribedCourses && subscribedCourses.length>1 && selectedOption == performanceOptions[0]">
        <div class="text-center mb-4">
            <h5>See the data of individual course by clicking here.</h5>
        </div>
        <div class="course_animated_buttons" *ngIf="subscribedCourses?.length > 1">
            <div class="col-md-3 col-sm-3 col-xs-6" *ngFor="let course of subscribedCourses;" (click)="handleSingleCourse(course.courseID)"> <a class="btn btn-sm animated-button thar-three" [ngClass]="course.courseID == courseId ? 'button_active' : ''">{{course.courseName}}</a> </div>
        </div>
        
      </div>

      <div class="loader" style="margin-top: 5rem;" *ngIf="loader">
        <app-page-loader></app-page-loader>
      </div>
      
      <div class="tests_details" *ngIf="selectedOption == performanceOptions[0] && !loader">
        
        <div class="left_wrapper">
          <div class="ml-0 pl-3 mb-4 text-weight-bold heading_answer_changes eup-ov-cards-heading">
            <h5> Test Count </h5>
          </div>
          <div class="test_stats_left">
              <div class="single_card">
                  <div class="top_number purple_color">{{totalQuizzes}}</div>
                  <div class="bottom_des">Total Tests</div>
              </div>
              <div class="single_card">
                  <div class="top_number blue_color">{{quizCompleted}}</div>
                  <div class="bottom_des">Tests Completed</div>
              </div>
              <div class="single_card">
                  <div class="top_number mehroon_color">{{quizSuspended}}</div>
                  <div class="bottom_des">Tests Suspended</div>
              </div>
          </div>

        </div>
      </div>

      <div class="container" *ngIf="selectedOption == performanceOptions[0] && !loader">
        <!--          Cards wrapper  -->
      <div class="row">
        <div class="col-md-12">
          <div class="eup-ov-cards-heading ">
            <h5>Your Score</h5>
          </div>
          <div class="eup-ov-cards-wrapper col-sm-12">
            <!-- card 1 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4">
              <div class="eup-green-card">
                <p-card styleClass="eup-green-card">

                  <div class="eup-ov-card-value text-center"> <p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{totalCorrect}}</span></div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Total Correct</span> </div>
                </p-card>
              </div>
            </div>
            <!-- card 2 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4">
              <div class="eup-red-card">
                <p-card styleClass="eup-red-card">
                  <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{totalIncorrect}}</span> </div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Total Incorrect</span>  </div>
                </p-card>
              </div>
            </div>
            <!-- card 3 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4">
              <div class="eup-blue-card">
                <p-card styleClass="eup-blue-card">
                  <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{totalOmitted}}</span> </div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Total Omitted</span>  </div>
                </p-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ****************************** -->
    <!--          Charts wrapper    -->
    <div class="row eup-ov-charts-wrapper" *ngIf="selectedOption == performanceOptions[0] && !loader">
      <div class="col-md-10 col-lg-6 eup-ov-left-chart mt-2 mb-2">

        <div class="eup-ov-chart eup_left_chart">

          <div class="row ">
            <div class="col-sm-12 ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
              <h5>QBank Usage</h5>
            </div>
            <div class="col-md-6 ">
              <div class="eup-ov-data-chart">
                <canvas
                  baseChart
                  [data]="pieChartData1"
                  [colors]="chartColors"
                  [labels]="pieChartLabels1"
                  [chartType]="pieChartType"
                  [options]="pieChartOptions"
                  [plugins]="pieChartPlugins"
                  [legend]="pieChartLegend"
                >
                </canvas>
              </div>
            </div>
            <div class="col-md-6">
              <div class="eup-ov-data-chart-pointers eup_left_pointers">
                <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels1;let i = index;">
                  <div class="chart_headings">
                  <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
                  <p class="chart_labels">  {{label.label}}</p>
                </div>
                <div class="chart_number">
                  <div class="number {{pointersColorClasses[i]}}">
                   {{label.value}}
                  </div>
                </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="col-md-10 col-lg-6 eup-ov-right-chart mt-2 mb-2">
        <div class="eup-ov-chart ">
          <div class="row ">
            <div class="col-md-12 ">
              <div class="eup-ov-data-chart-pointers">
                <div class="ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
                  <h5> Answer Changes </h5>
                </div>
                <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels;let i = index;">
                  <div class="chart_headings">
                    <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
                    <p class="chart_labels">{{label.label}}</p>
                  </div>
                  <div class="chart_number">
                    <div class="number {{pointersColorClasses[i]}}">
                        {{label.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- ****************************** -->

      <!-- THE KNOB CHARTS WILL COME HERE -->
      <div class="knob_chart_parent" *ngIf="selectedOption == performanceOptions[0] && !loader">
        <div class="knob_chart_wrapper">
          <div class="left_chart">
            <div class="eup-ov-cards-heading ">
              <h5>Total Correct</h5>
            </div>
            <div class="left_chart_wrapper">
              <p-knob [(ngModel)]="totalCorrectPercentage" [strokeWidth]="7" [readonly]="true" valueTemplate="{value}%" [size]="200" valueColor="#61ce87" rangeColor="#c9c9c9"></p-knob>
            </div>
          </div>
          <div class="right_chart">
            <div class="eup-ov-cards-heading ">
              <h5>Total Used</h5>
            </div>
            <div class="right_chart_wrapper">
              <p-knob [(ngModel)]="totalUsedPercentage" [strokeWidth]="7" [readonly]="true" valueTemplate="{value}%" [size]="200" valueColor="#389cf2" rangeColor="#c9c9c9"></p-knob>
            </div>
          </div>
        </div>
      </div>

  <app-performance-table *ngIf="selectedOption == performanceOptions[1]" [subscribedCourses]="subscribedCourses"></app-performance-table>

</div>
