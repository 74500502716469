import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { TreeNode } from 'primeng/api';
import { CourseService } from 'src/app/shared/services/course.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { demo_variables } from 'src/app/models/demo_global';


@Component({
  selector: 'app-performance-table',
  templateUrl: './performance-table.component.html',
  styleUrls: ['./performance-table.component.scss']
})
export class PerformanceTableComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription = [];

  @Input() subscribedCourses: any;

  files1: TreeNode[];

  files2: TreeNode[];

  files3: TreeNode[];

  files4: TreeNode[];

  files5: TreeNode[];

  cols: any[];

  cols1: any[];

  cols2: any[];

  quizId: any;

  loader = false;
  showTestDetails = true;

  courseId: any;

  constructor(private adminService:AdminService, private quizService: QuizServiceService, private courseService: CourseService, private errorHandler: ErrorHandlerService) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  async ngOnInit(){
    this.cols = [
      { field: 'name', header: 'SUBJECTS' },
      { field: 'questions', header: 'TOTAL Q' },
      { field: 'correct', header: 'CORRECT' },
      { field: 'incorrect', header: 'INCORRECT' },
      { field: 'omitted', header: 'OMITTED' }
    ];

    this.cols1 = [
      { field: 'name', header: 'SYSTEMS' },
      { field: 'questions', header: 'TOTAL Q' },
      { field: 'correct', header: 'CORRECT' },
      { field: 'incorrect', header: 'INCORRECT' },
      { field: 'omitted', header: 'OMITTED' }
    ];

    this.cols2 = [
      { field: 'name', header: 'TOPICS' },
      { field: 'questions', header: 'TOTAL Q' },
      { field: 'correct', header: 'CORRECT' },
      { field: 'incorrect', header: 'INCORRECT' },
      { field: 'omitted', header: 'OMITTED' }
    ];

    if(localStorage.getItem('Id') != '-1'){
      if (this.subscribedCourses.length > 1) {
        let index = 0;
        if (sessionStorage.getItem('userSelectedCourse')) {
          index = this.subscribedCourses.findIndex((item: any) => item.courseName == sessionStorage.getItem('userSelectedCourse'))
        }

        this.courseId = this.subscribedCourses[index].courseID
        this.individualCourseTable(this.courseId);
      } else {
        await this.getSubjectTabledata(-1);
        await this.getSystemTableData(-1);
        await this.getTopicTableData(-1);
      }
    } else {
      this.getDemoValues()
    }
  }
  getDemoValues(){
    this.files1 = demo_variables.getTestAnalysisBySubject;
    this.files2 = demo_variables.getTestAnalysisBySubject;
    this.files3 = demo_variables.getTestAnalysisBySystem;
    this.files4 = demo_variables.getTestAnalysisBySystem;
    this.files5 = demo_variables.getPerformanceByTopic;
  }

  compareToken(){
    // @ts-ignore
    this.subscriptions.push(
      this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        if(res.status){
        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }
  
value = 0;

  async individualCourseTable(courseId: any){
    this.courseId = courseId;
    await this.getSubjectTabledata(courseId);
    await this.getSystemTableData(courseId);
    await this.getTopicTableData(courseId);
  }

  getSubjectTabledata(courseId: any){
  
    this.loader = true
    this.subscriptions.push(
      this.courseService.getPerformanceBySubjects(courseId).subscribe((res: any) => {
        
        this.files1 = res.data.data;
        this.files2 = res.data.data;
        if(res.status == false){
          this.showTestDetails = false;
        }
        this.loader = false;
      },
        error => {
          
        this.loader = false
          this.errorHandler.handleError(error);
        })
    );
  }

  getSystemTableData(courseId: any){
    this.loader = true;
    this.subscriptions.push(
      this.courseService.getPerformanceBySystems(courseId).subscribe((res: any) => {
        
        this.loader = false;
        this.files3 = res.data.data;
        this.files4 = res.data.data;
        if(res.status == false){
          this.showTestDetails = false;
        }
      },
        error => {
          
        this.loader = false
          this.errorHandler.handleError(error);
        })
    );
  }

  getTopicTableData(courseId: any){
    this.loader = true;
    this.subscriptions.push(
      this.courseService.getPerformanceByTopics(courseId).subscribe((res: any) => {
        
        
        this.files5 = res.data.data;
        if(res.status == false){
          this.showTestDetails = false;
        }
        this.loader = false
      },
        error => {
          
        this.loader = false
          this.errorHandler.handleError(error);
        })
    );
  }

}
