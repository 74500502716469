import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = BASE_URL;
  constructor(private httpClient: HttpClient) { }

  getUserActivityData(){
    console.log("Going for getUserActivityData")
    return this.httpClient.get(this.baseUrl + '/getUserActivityData',
    {headers: createAuthorizationHeader()} );
  }
  getAdminNotifications(){
    return this.httpClient.post(this.baseUrl + '/getAdminNotifcation', {userId: localStorage.getItem("Id")},
    {headers: createAuthorizationHeader()} );
  }
  deleteAdminNotification(notification){
    return this.httpClient.post(this.baseUrl + '/deleteAdminNotifcation', {notification},
    {headers: createAuthorizationHeader()} );
  }
  sendAdminNotifications(data: any){
    return this.httpClient.post(this.baseUrl + '/addAdminNotification',{data},
    {headers: createAuthorizationHeader()} );
  }
  deleteSpecificAdminNotification(notificationId){
    return this.httpClient.post(this.baseUrl + '/deleteSpecificAdminNotification',{notificationId},
    {headers: createAuthorizationHeader()} );
  }
  getQuizDetails(){
    return this.httpClient.get(this.baseUrl + '/getAllQuizzes',
    {headers: createAuthorizationHeader()} );
  }
  getUsers(){
    return this.httpClient.get(this.baseUrl + '/getAllUsers',
    {headers: createAuthorizationHeader()} );
  }

  logoutUser(user_Id: any){
    return this.httpClient.post(this.baseUrl + '/autoLogout',{userId: user_Id},
    {headers: createAuthorizationHeader()} );
  }

  compareToken(userId: any){
    return this.httpClient.post(this.baseUrl + '/compareToken', {userId: userId},
    {headers: createAuthorizationHeader()} );
  }

  linkQuestions(questionId: any, linkedId: any){
    return this.httpClient.post(this.baseUrl + '/addLinkedQuestions', {data: {questionId: questionId, linkedId: linkedId}},
    {headers: createAuthorizationHeader()} );
  }

  getLinkedQuestions(){
    return this.httpClient.get(this.baseUrl + '/getLinkedQuestions',
    {headers: createAuthorizationHeader()} );
  }

  deleteLinkedQuestions(data){
    return this.httpClient.post(this.baseUrl + '/deleteLinkedQuestions', {questionId: data},
    {headers: createAuthorizationHeader()} );
  }

  setForceLogout(userId: any) {
    return this.httpClient.post(this.baseUrl + '/setForceLogout', {data: {userId: userId}},
    {headers: createAuthorizationHeader()} );
  }

  setRenewToggle(data: any) {
    return this.httpClient.post(this.baseUrl + '/setRenewToggle', {data: data},
    {headers: createAuthorizationHeader()} );
  }

  getAllChats(userId: any){
    return this.httpClient.get(this.baseUrl + '/getAllChats',
    {headers: createAuthorizationHeader()} );
  }

  linkQuestionIDs(data: any) {
    return this.httpClient.post(this.baseUrl + '/linkQuestionIDs', { data },
    {headers: createAuthorizationHeader()} );
  }

  getUWorldLinkedQuestions() {
    return this.httpClient.get(this.baseUrl + '/getUWorldLinkedQuestions',
    {headers: createAuthorizationHeader()} );
  }

  updateUWorldLinkedQuestions(data: any) {
    return this.httpClient.post(this.baseUrl + '/updateUWorldLinkedQuestions', { data },
    {headers: createAuthorizationHeader()} );
  }

  deleteUWorldLinkedQuestions(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteUWorldLinkedQuestions', { data },
    {headers: createAuthorizationHeader()} );
  }

  addAdminDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/addAdminDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  addAdminFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/addAdminFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  getAdminDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/getAdminDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  editAdminDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/editAdminDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  editAdminFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/editAdminFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteAdminDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteAdminDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteAdminFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteAdminFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  addInstructor(data: any) {
    return this.httpClient.post(this.baseUrl + '/addInstructor', { data },
    {headers: createAuthorizationHeader()});
  }

  getInstructors() {
    return this.httpClient.get(this.baseUrl + '/getInstructors',
    {headers: createAuthorizationHeader()});
  }

  editInstructor(data: any) {
    return this.httpClient.post(this.baseUrl + '/editInstructor', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteInstructor(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteInstructor', { data },
    {headers: createAuthorizationHeader()});
  }

  getInstructorPermissions(data: any) {
    return this.httpClient.post(this.baseUrl + '/getInstructorPermissions', { data },
    {headers: createAuthorizationHeader()});
  }

  checkDuplicateInstructor(data: any) {
    return this.httpClient.post(this.baseUrl + '/checkDuplicateInstructor', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteUser(userId: any) {
    return this.httpClient.post(this.baseUrl + '/deleteUser', {userId},
    {headers: createAuthorizationHeader()} );
  }

  addSyncQuestions(data: any) {
    return this.httpClient.post(this.baseUrl + '/addSyncQuestions', { data },
    { headers: createAuthorizationHeader() } );
  }

  editSyncQuestions(data: any) {
    return this.httpClient.post(this.baseUrl + '/editSyncQuestions', { data },
    { headers: createAuthorizationHeader() } );
  }

  deleteSyncQuestions(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteSyncQuestions', { data },
    { headers: createAuthorizationHeader() } );
  }

  getQuestionsList() {
    return this.httpClient.get(this.baseUrl + '/getQuestionsList',
    { headers: createAuthorizationHeader() } );
  }

  syncCategories() {
    return this.httpClient.get(this.baseUrl + '/syncCategories',
    { headers: createAuthorizationHeader() } );
  }

  getMemberships() {
    return this.httpClient.get(this.baseUrl + '/getMemberships',
    { headers: createAuthorizationHeader() } );
  }

  editMembership(data: any) {
    return this.httpClient.post(this.baseUrl + '/editMembership', {data},
    { headers: createAuthorizationHeader() } );
  }
}
