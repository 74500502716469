import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-manage-memberships',
  templateUrl: './manage-memberships.component.html',
  styleUrls: ['./manage-memberships.component.scss'],
  providers: [MessageService]
})
export class ManageMembershipsComponent implements OnInit {

  memberships: any = [];
  rows = 10;
  NumofRows = [
    {numOfRows: 10},
    {numOfRows: 20},
    {numOfRows: 50}
  ];
  editMembershipModal = false;
  editForm: any;
  selectedMembership: any = null;
  loader = false;
  modalLoader = false;
  durations = [
    {
      title: '6 months',
      value: 180
    },
    {
      title: '1 year',
      value: 365
    },
    {
      title: '2 years',
      value: 730
    }
  ]

  constructor(private fb: FormBuilder, private messageService: MessageService, private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      name: [null, [Validators.required]],
      price: [null, [Validators.required]],
      percentage: [null, [Validators.required]],
      duration: [null, [Validators.required]]
    });
    this.getMemberships();
  }

  selectNum(a: any): void{
    this.rows = a.numOfRows;
  }

  getMemberships() {
    this.loader = true;
    this.adminService.getMemberships().subscribe((res: any) => {
      this.loader = false;
      this.memberships = res.memberships;
    })
  }

  openEditModal(index: any) {
    this.editMembershipModal = true;
    this.editForm.reset();
    setTimeout(() => {
      this.selectedMembership = this.memberships[index];
      this.editForm.controls['name'].setValue(this.memberships[index].Name);
      this.editForm.controls['price'].setValue(this.memberships[index].Price);
      this.editForm.controls['percentage'].setValue(this.memberships[index].Percentage);
      this.editForm.controls['duration'].setValue(this.memberships[index].Duration);
    }, 50)
  }

  editMembership() {
    const data = {
      membershipId: this.selectedMembership.ID,
      price: this.editForm.controls['price'].value,
      percentage: this.editForm.controls['percentage'].value,
      duration: this.editForm.controls['duration'].value
    }
    this.modalLoader = true;
    this.adminService.editMembership(data).subscribe((res: any) => {
      this.modalLoader = false;
      this.editMembershipModal = false;
      if (res.data.status) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Membership Edited Successfully'});
        this.getMemberships();
      }
    }, (error: any) => {
      this.modalLoader = false;
      this.editMembershipModal = false;
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Editing Membership'});
    })
  }
}
