import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = BASE_URL;
  constructor(private httpClient: HttpClient) { }

  addBookmarkArticle(data)
  {
    return this.httpClient.post(this.baseUrl + '/addBookmarkArticle', {data},
    {headers: createAuthorizationHeader()} );
  }

  getBookmarkArticles(data)
  {
    return this.httpClient.post(this.baseUrl + '/getBookmarkArticles', {data},
    {headers: createAuthorizationHeader()} );
  }

  removeBookmarkArticle(data)
  {
    return this.httpClient.post(this.baseUrl + '/removeBookmarkArticle', {data},
    {headers: createAuthorizationHeader()} );
  }

  getmedicalLibrary(data: any)
  {
    return this.httpClient.post(this.baseUrl + '/getMedicalLibrary', { data },
    {headers: createAuthorizationHeader()} );
  }

  // add highlight 
  addHighlight(data)
  {
    return this.httpClient.post(this.baseUrl + '/addTextHighlight', {data},
      {headers: createAuthorizationHeader()} );
  }
  deleteHighlight(data)
  {
    return this.httpClient.post(this.baseUrl + '/removeTextHighlight', {data},
      {headers: createAuthorizationHeader()} );
  }

 
  getTextHighlights(data)
  {
    
    return this.httpClient.post(this.baseUrl + '/getTextHighlights', {data},
      {headers: createAuthorizationHeader()} );
  }

  // add annotation
  addAnnotation(data)
  {
    return this.httpClient.post(this.baseUrl + '/addTextAnnotation', {data},
    {headers: createAuthorizationHeader()} );
  }

  // update annotation 
  updateAnnotation(data)
  {
    return this.httpClient.post(this.baseUrl + '/editTextAnnotation', {data},
      {headers: createAuthorizationHeader()} );
  }
  deleteAnnotation(data)
  {
    return this.httpClient.post(this.baseUrl + '/removeTextAnnotation', {data},
      {headers: createAuthorizationHeader()} );
  }
  //get all user annotations 
  getAnnotations(data)
  {
    return this.httpClient.post(this.baseUrl + '/getUserAnnotations', {data},
    {headers: createAuthorizationHeader()} );
  }

  addArticle(data)
  {
    return this.httpClient.post(this.baseUrl + '/addArticle', {data},
      {headers: createAuthorizationHeader()} );
  }

  

  updateArticle(data)
  {
    return this.httpClient.post(this.baseUrl + '/editArticle', {data},
      {headers: createAuthorizationHeader()} );
  }

  deleteArticle(data)
  {
    return this.httpClient.post(this.baseUrl + '/deleteArticle', {data},
      {headers: createAuthorizationHeader()} );
  }

  addArticleFeedback(data: any)
  {
    return this.httpClient.post(this.baseUrl + '/addArticleFeedback', { data },
    {headers: createAuthorizationHeader()} );
  }

  addRelatedArticles(data: any)
  {
    return this.httpClient.post(this.baseUrl + '/addRelatedArticles', { data },
    {headers: createAuthorizationHeader()} );
  }
}
