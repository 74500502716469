<div class="library-root">
    <div class="courses">
        <h2>Medical Library</h2>
        <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" [style]="{ width: '100%' }" (onChange)="changeArticles()"></p-dropdown>
    </div>
    <hr>
    <div class="flex align-start ">

        <div class="sidebar-wrapper">
            <div class="actions-wrapper">
             <div class="flex align-center justify-content-center menu-wrapper" (click)="addForm()">
                 <i class='pi pi-plus'></i> <span>Add article</span>
             </div>
            </div>

            <div *ngIf="medicalLibrary?.length>0" class="main-category-wrapper">

                <div *ngFor="let category of medicalLibrary" >
                    <div class="main-categories"  *ngIf="category.subTopics?.length>0" >
                        <div class="pointer" (click)="activeMainCategory(category)">
                            <i class="pi " [ngClass]="category.status? 'pi-folder-open':'pi-folder'"></i> <label>{{category.title}}</label>
                        </div>
                        <div  *ngIf="category.subTopics?.length>0 && category.status" class="subcategories-background">
                            <div class="subcategory-styling pl-1rem" *ngFor="let subcat of category.subTopics">
                                <div class="flex align-center justify-between  subcategory-heding-wrapper pointer" (click)="activeSubcategory(subcat , category.subTopics , category)">
                                    <div class="flex align-center subcategory-heding">
                                        <img class="newspaper" [ngClass]="subcat.status?'active-svg':''" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                                        <p [ngClass]="subcat.status ? 'active':''">{{subcat.title}}</p> 
                                    </div>
                                </div>
                                    <div *ngIf="subcat.subTopics?.length>0 && subcat.status" class="menu-option-wrapper">
                                        <div *ngFor="let topics of subcat.subTopics; let k = index;" >
                                            <div  [ngClass]="topics.status?'active-tab':''" class="menu-option" (click)="scrollToHeading('heading-' + (k + 1))">
                                                {{topics.title}} 
                                            </div>
                
                                            <ul *ngIf="topics.subTopics?.length>0 && topics.status" class="subtopics-wrapper">
                                                <li *ngFor="let topics of topics.subTopics" [ngClass]="topics.status?'active-tab':''" class="menu-option no-border">
                                                    {{topics.title}} 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                               </div>
                        </div>
                   </div>
                </div>
                
               </div>
            
               

         </div>
         <div class="article-content-wrapper">
          
            
           <div *ngIf="showAddForm">
            <div class="article-headings">
                <h6>Subject Name</h6>
                <input [(ngModel)]="subjectName" (keyup)="checkSubject($event.target)">
                <h6>Article Name</h6>
                <input [(ngModel)]="articleName">
               </div>
                <div class="notebookdialog editor-headings"  id="notebookdialog" style="width: 100%; height: 65vh;">
                    <ckeditor  id="ckEditorNotebook" [(ngModel)]="notebookEditor"></ckeditor>
                  </div>
    
                  <div class="buttons-wrapper flex align-center justify-end">
                  
                    <button class="cancel-btn" (click)="cancelEditing()">
                        Cancel
                    </button>
                    <button *ngIf="!editArticleFlag" class="add-btn" [ngClass]="(!subjectName || !articleName  || !ckEditorNotebook?.getData() ) ? 'disable':''" (click)="addArticle()">
                        Add Article
                    </button>

                    <button *ngIf="editArticleFlag" class="add-btn" [ngClass]="(!subjectName || !articleName  || !ckEditorNotebook?.getData() ) ? 'disable':''" (click)="updateArticle()">
                        Update
                    </button>

                    
                    
                    
                </div>
           </div>
           <div *ngIf="!showAddForm" id="article-content">

            <div *ngIf="medicalLibrary?.length>0 && showEditbuttons" class="buttons-wrapper flex align-center justify-end">
                <button class="edit-btn" (click)="editArticle()">Edit  <i class=" pi pi-pencil"></i></button>
                <button class="delete-btn" (click)="showDeletePopup()" >Delete <i class=" pi pi-trash"></i></button>
            </div>
            <div class="related_articles">
                <span (click)="openRelatedArticles()">View related articles</span>
            </div>
            <div *ngIf="medicalLibrary?.length>0"
             [innerHTML]="articleToView | safeHtml" (click)="handleLinkClick($event)">
            </div>
            <div class="show_articles" *ngIf="selectedArticle?.relatedArticles && selectedArticle?.relatedArticles.length > 0">
                <hr>
                <h5>RELATED ARTICLES</h5>
                <div class="articles">
                    <div class="item" *ngFor="let related of selectedArticle?.relatedArticles" (click)="navigateToSearchedArticle(related)">
                        <img class="newspaper" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                        <span>{{ related.title }}</span>
                    </div>
                </div>
            </div>
           </div>
         </div>


    </div>


    <div *ngIf="!articleExists && !showAddForm" class="placeholder-wrapper flex flex-column align-center justify-center">
        <div class="heading">
            No article found
        </div>
        <div class="description">
            Please add article to view in the list
        </div>
    </div>
</div>


<p-dialog header="Delete Article" [(visible)]="showDeleteDialog" [modal]="true" [style]="{width: '400px'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <div class="p-field">
        <p>Are you sure you want to delete this article?</p>
        <h6>
            This action cannot be undone.
        </h6>
        <div class="buttons-wrapper-annotations buttons-wrapper flex align-center justify-end">
            <button class="cancel-btn" (click)="cancelDelete()">Cancel</button>
            <button class="delete-btn"  (click)="deleteArticle()">Delete</button>
        </div>
</div>
  
</p-dialog>

<app-overlay-loader *ngIf="loader"></app-overlay-loader>

<p-dialog header="Exhibit Display" styleClass="imgDialog" [(visible)]="displayImg" position="center" [modal]="false" [baseZIndex]="10000"  [style]="{ width: '500px' }" [draggable]="false" [modal]="true">
    <div class="imgDialog_root">
        <div class="loading_image" style="margin: 2rem 0;" *ngIf="!showImage">
            <div class="loader" style="margin: 0 auto; text-align: center;"></div>
        </div>
      <div class="showImage" id="pic" *ngIf="showType === 0 && showImage">
        <pinch-zoom [limit-zoom]="4">
          <img src={{showImage}}>
        </pinch-zoom>
      </div>
      <div class="showImage" id="pic" *ngIf="showType === 1 && showImage">
          <div [innerHTML]="showImage | safeHtml" class="showTable"></div>
      </div>
      <div class="showImage" id="pic" *ngIf="showType === 2 && showImage">
          <video controls controlsList="nodownload">
              <source src="{{ showImage }}" type="video/mp4">
          </video>
      </div>
      <div class="showImage" id="pic" *ngIf="showType === 3 && showImage">
          <audio controls controlsList="nodownload">
              <source src="{{ showImage }}" type="audio/mp3">
          </audio>
      </div>
    </div>
  </p-dialog>

<p-dialog header="View related articles" styleClass="imgDialog" [(visible)]="displayRelated" position="center" [modal]="false" [draggable]="false" [modal]="true" [style]="{ width: '400px' }">
    <div class="all_articles">
        <span class="heading">Add new related articles</span>
        <p-dropdown [options]="allArticles" [(ngModel)]="selectedRelatedArticle" optionLabel="title" [style]="{ width: '100%', marginBottom: '1rem' }" appendTo="body" placeholder="Select article" [filter]="true" filterBy="title" [showClear]="true"></p-dropdown>
        <div class="buttons">
            <div class="loader" *ngIf="miniLoader"></div>
            <button *ngIf="!miniLoader" (click)="addRelatedArticles()" [disabled]="!selectedRelatedArticle">Add</button>
        </div>
        <hr>
        <span class="heading">Added related articles</span>
        <div class="all_related">
            <div class="item" *ngFor="let related of selectedArticle?.relatedArticles; let i = index;">
                <div class="text">
                    <img class="newspaper" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                    <span>{{ related.title }}</span>
                </div>
                <i class="pi pi-times" (click)="removeRelatedArticles(i)"></i>
            </div>
            <div class="no_item" *ngIf="!selectedArticle?.relatedArticles || selectedArticle?.relatedArticles.length === 0">
                No related articles added yet.
            </div>
        </div>
    </div>
</p-dialog>