<div class="medical_library" [ngStyle]="{ padding: isQuizModule ? '0' : '15px 20px 0px' }">
    <div class="courses">
        <h2>{{ isQuizModule ? '' : 'Medical Library' }}</h2>
        <p-dropdown *ngIf="!isDemo" [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" [style]="{ width: '100%' }" (onChange)="changeArticles()"></p-dropdown>
    </div>
    <hr>
    <div class="flex align-start">
        <div class="sidebar-wrapper">
           <div class="actions-wrapper">
            <div class="flex align-center menu-wrapper" (click)="updateFlags('showSearch')" [ngClass]="!isDemo ? '' : 'disabled'">
                <i class='pi pi-search'></i> <span>Search Medical Library</span>
            </div>
            <div class="flex align-center menu-wrapper" (click)="updateFlags('bookmarks')" [ngClass]="!isDemo ? '' : 'disabled'">
                <i class="fa fa-bookmark-o"></i> <span>Bookmarks</span>
            </div>
           </div>

           <div class="subcategory-styling pl-x" *ngIf="isDemo">
                <div class="flex align-center justify-between subcategory-heding-wrapper pointer" (click)="selectDefault()">
                    <div class="flex align-center subcategory-heding">
                        <img  class="newspaper"  [ngClass]="demoOptions.status?'active-svg':''" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                        <p [ngClass]="demoOptions.status ? 'active':''" >{{ demoOptions.title }}</p> 
                    </div>
                </div>
           </div>
    
           <div class="subcategory-styling pl-x" *ngIf="!isDemo">
            <div class="flex align-center justify-between subcategory-heding-wrapper pointer" (click)="selectDefault()">
                <div class="flex align-center subcategory-heding">
                    <img  class="newspaper"  [ngClass]="tourOptions.status?'active-svg':''" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                    <p [ngClass]="tourOptions.status ? 'active':''" >{{ tourOptions.title }}</p> 
                </div>
            </div>
                <div *ngIf="tourOptions.status" class="menu-option-wrapper">
                    <div *ngFor="let option of tourOptions.options" (click)="activeOption(option)" [ngClass]="option.status?'active-tab':''" class="menu-option">
                        {{option.title}}
                    </div>
                </div>
           </div>
    
           <div class="main-category-wrapper">
            <div  *ngFor="let category of libraryObject">
                <div class="main-categories"  *ngIf="category.subTopics?.length>0">
                    <div class="pointer" (click)="activeMainCategory(category)">
                        <i class="pi " [ngClass]="category.status? 'pi-folder-open':'pi-folder'"></i> <label>{{category.title}}</label>
                    </div>
                    <div  *ngIf="category.subTopics?.length>0 && category.status" class="subcategories-background">
                        <div class="subcategory-styling pl-1rem" *ngFor="let subcat of category.subTopics">
                            <div class="flex align-center justify-between  subcategory-heding-wrapper pointer" (click)="activeSubcategory(subcat, category.subTopics, category.title, false)">
                                <div class="flex align-center subcategory-heding">
                                    <img class="newspaper" [ngClass]="subcat.status?'active-svg':''" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                                    <p [ngClass]="subcat.status ? 'active':''">{{subcat.title}}</p> 
                                </div>
                                <img class="pointer bookmark" (click)="addBookmarkArticles(subcat)"  [ngClass]="subcat.bookmark? 'filledstar':''" [src]="subcat.bookmark ? '../../../assets/medicallibrary/star-filled-svgrepo-com.svg':'../../../assets/medicallibrary/star-empty-svgrepo-com.svg'"  (click)='bookmarkCategory(subcat)'>
                            </div>
                                <div *ngIf="subcat.subTopics?.length>0 && subcat.status" class="menu-option-wrapper">
                                    <div *ngFor="let topics of subcat.subTopics; let k = index;" >
                                        <div (click)="activeTopic(topics , subcat.subTopics)" [ngClass]="topics.status?'active-tab':''" class="menu-option" (click)="scrollToHeading('heading-' + (k + 1))">
                                            {{topics.title}} 
                                        </div>
            
                                        <ul *ngIf="topics.subTopics?.length>0 && topics.status" class="subtopics-wrapper">
                                            <li *ngFor="let topics of topics.subTopics" (click)="activeTopic(topics , topics.subTopics)" [ngClass]="topics.status?'active-tab':''" class="menu-option no-border">
                                                {{topics.title}} 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                           </div>
                    </div>
               </div>
            </div >
          
           </div>
        </div>
    
    
        <div class="article-content-wrapper">
           <div *ngIf="flags.showContent" id="article-content">
                <div class="prev_page flex align-items-center" (click)="navigatePageBack()" *ngIf="prevPages.length > 1">
                    <i class="pi pi-arrow-left"></i>
                    <h6>Back to Previous Page</h6>
                </div>
                <h1> {{selectedArticle?.title}} </h1>
                <div class="details flex justify-content-between align-items-center">
                    <div class="subjects_list">
                        <span *ngFor="let subject of selectedArticle?.subjects">{{ subject }}</span>
                    </div>
                    <div class="settings" *ngIf="!isDemo">
                        <i class="pi pi-cog" (click)="settings.toggle($event)"></i>
                        <p-overlayPanel #settings
                            [style]="{'background-color':'#F1F1F1','width':'330px','max-height':'400px','padding':'10px' , 'overflow-y': 'scroll'}">
                            <ng-template pTemplate>
                            <div class="settings-dropdown-heading">
                                <span>Settings</span>
                            </div>
                            <div class="settings-card">
                                <span class="card-heading">Appearance</span>
                                <div class="card settings-card">
                                    <div class="settings-option">
                                        <div class="flex-1">
                                        <span class="settings-option-label">Font Size</span>
                                        </div>
                                        <div class="settings-btn-group">
                                        <span (click)="decreaseFontSize()"><i class="fa fa-minus"></i></span>
                                        <span (click)="resetFontSize()"><i class="fa fa-font"></i></span>
                                        <span (click)="increaseFontSize()"><i class="fa fa-plus"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="settings-card night-mode-heading" *ngIf="!this.tourOptions.status">
                                <span class="card-heading">Features</span>
                                <div class="card settings-card">
                                    <div class="settings-option">
                                        <div class="flex-1">
                                        <span class="settings-option-label">Highlight-Only Mode</span>
                                        </div>
                                        <div class="settings-btn-group">
                                        <span>
                                            <label class="switch">
                                            <input type="checkbox" [checked]="highlightOnlyMode"
                                                (change)="changeHighlightMode()">
                                            <span class="slider round"></span>
                                            </label>
                                        </span>
                                        </div>
                                    </div>
                                    <div class="settings-option">
                                        <div class="flex-1">
                                        <span class="settings-option-label">Multicolor Highlighting</span>
                                        </div>
                                        <div class="settings-btn-group">
                                        <span>
                                            <label class="switch">
                                            <input type="checkbox" [checked]="multiHighlightMode"
                                                (change)="changeMultiHighlight()">
                                            <span class="slider round"></span>
                                            </label>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </ng-template>
                        </p-overlayPanel>
                    </div>
                </div>
                <hr style="margin-top: 1.5rem; margin-bottom: 1.5rem;">
                <div 
                id="articleContent"
                
                (mouseup)="(isIpad)?highlightTxt():null"
                 [innerHTML]="selectedArticle?.content | safeHtml" (click)="handleLinkClick($event)">
                </div>
                <span id="control-library" [ngStyle]="{ width: !multiHighlightMode ? '390px' : '500px' }">
                    <b *ngIf="highlightEnabled" id="highlightingIcon1-library"></b>
                    <b *ngIf="highlightEnabled && multiHighlightMode" id="highlightingIcon2-library"></b>
                    <b *ngIf="highlightEnabled && multiHighlightMode" id="highlightingIcon3-library"></b>
                    <b *ngIf="highlightEnabled && multiHighlightMode" id="highlightingIcon4-library"></b>
                    <b *ngIf="highlightEnabled && multiHighlightMode" id="highlightingIcon5-library"></b>
                    <b id="annotations-library"></b>
                    <b id="notebook-library"></b>
                    <b style="width: 65px;" id="newFlashcard-library"></b>
                    <b id="existingFlashcard-library"></b>
                    <b id="aiFlashcards-library"></b>
                </span>
                <div class="related_articles" *ngIf="selectedArticle.relatedArticles">
                    <hr>
                    <h5>RELATED ARTICLES</h5>
                    <div class="articles">
                        <div class="item" *ngFor="let related of selectedArticle.relatedArticles" (click)="navigateToSearchedArticle(related)">
                            <img class="newspaper" src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                            <span>{{ related.title }}</span>
                        </div>
                    </div>
                </div>
           </div>
           <div *ngIf="flags.showSearch">
               <div class="search-wrapper">
                        <h1>Search Medical Library</h1>
    
                       
               </div>
               <div class="search-bar flex align-center">
                <i class="pi pi-search abs-search"></i>
                <input type="text" [(ngModel)]="searchWord" (keyup)="searchArticles($event)">
                <i class="pi pi-times abs-times" *ngIf="searchWord?.length > 0" style="cursor: pointer;" (click)="resetSearch()"></i>
               </div>
    
               <div class="found-article-wrapper">
                <p *ngIf="searchWord">{{filteredArticles.length}} results found for "{{searchWord}}"</p>
    
                <div class="searched-article-wrapper" *ngFor="let articles of filteredArticles" (click)="navigateToSearchedArticle(articles)">
                    <h3>
                        {{articles.title}}
                    </h3>
                    <div [innerHTML]="articles.searchedParagraph">
                  
                    </div>
                </div>
               </div>
           </div>
    
    
           <div *ngIf="flags.bookmarks">
            <div class="search-wrapper flex align-center justify-between">
                     <h1>Bookmarks</h1>
    
                     <div *ngIf="bookmarked.length>0">
                        <i class="pi pi-pencil pointer" (click)="editBookmark=!editBookmark"></i>
                     </div>
            </div>
    
            <div *ngIf="bookmarked.length>=0" class="bookmarks-wrapper">
                <div class="bookmarked-articles flex align-center justify-between" *ngFor="let article of bookmarked ; let i =index">
                    <div class="flex align-center subcategory-heding" (click)="navigateToSearchedArticle(article)">
                        <img  class="newspaper"   src="../../../assets/medicallibrary/newspaper-svgrepo-com.svg">
                        <p>{{article.title}}</p> 
                    </div>
                    <div class="flex align-center">
                       
                        <h6 class="added-date">Added on {{article.added_on | date:'fullDate'}}</h6>
                        <p-checkbox 
                                *ngIf="editBookmark"
                            
                                (onChange)="onCheckboxChange(article, $event)">
                            </p-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="bookmarked.length==0" class="placeholder-wrapper flex flex-column align-center justify-center">
                <div class="heading">
                    No bookmarks found
                </div>
                <div class="description">
                    No article is added as bookmarks
                </div>
            </div>
    
    
    
          
            <div class="edit-bookmars flex align-center justify-between" *ngIf="selectedBookmarks.length>0">
               <p> {{selectedBookmarks.length}} {{selectedBookmarks.length ==1  ?'bookmark' : 'bookmarks'}} selected</p>
    
               <div class="buttons-wrapper flex align-center">
                    <button class="cancel-btn" (click)="cancelEdit()">Cancel</button>
                    <button class="remove-btn" (click)="removeBookmarkArticles()">Remove</button>
               </div>
            
            </div>
            </div>
    
            <div class="static-bottom-bar">
                <div class="flex align-items-center justify-content-center icons-wrapper">
                    <div class="items flex align-items-center" *ngIf="!isSearchTopic">
                        <div class="item flex align-items-center" (click)="openSearchTopic()">
                            <i class="pi pi-search"></i>
                            <span>Search Article</span>
                        </div>
                        <div class="item flex align-items-center" *ngIf="!tourOptions.status" (click)="openFeedbackDialog()">
                            <i class="pi pi-comment"></i>
                            <span>Feedback</span>
                        </div>
                    </div>
                    <div class="search-articles flex align-items-center" *ngIf="isSearchTopic">
                        <input type="text" placeholder="Search this article" (input)="searchTopic($event)">
                        <div class="close" (click)="openSearchTopic()">
                            <i class="pi pi-times"></i>
                        </div>
                        <div class="steps flex align-items-center">
                            <i class="pi pi-chevron-left" (click)="prevSearchIndex()"></i>
                            <span>{{ searchedIndex }}</span>
                            <span>of</span>
                            <span>{{ searchCount }}</span>
                            <i class="pi pi-chevron-right" (click)="nextSearchIndex()"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Annotation" [(visible)]="annotationPopuop" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <div class="p-field p-col-12 p-md-12">
        <textarea class="text-area-style" [(ngModel)]="selectedAnnotation" rows="5" maxlength="1000" placeholder="Write a note..."></textarea>
       <div class="flex align-center justify-between action-wrapper">
        <p class="warning">* Max 1000 characters allowed</p>
        <i *ngIf="editAnnotation" class="pi pi-trash pointers" (click)="deleteAnnotation()"></i>
       </div>

        <div class="buttons-wrapper-annotations buttons-wrapper flex align-center justify-end">
            <button (click)="cancelAnnotation()">Cancel</button>
            <button class="remove-btn" [ngClass]="(selectedAnnotation)?'':'disable'" (click)="editAnnotation? updateAnnotation():saveAnnotation()">Save</button>
        </div>
</div>
      <!-- <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayModal=false" label="OK" styleClass="p-button-text"></p-button>
      </ng-template> -->
</p-dialog>

<!-- Notebook Feature Starts -->
<p-dialog header="Notebook" styleClass="notebookDialog" [(visible)]="shownotebookdialog" position="center" [modal]="true"
  [style]="{right:'-8px', 'padding':'0', width: '90vw', 'overflow':'hidden',height:'90vh','max-height': '90vh'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <div *ngIf="shownotebookdialog">
    <app-user-notebook [notebookText]=notebookText [quizEdit]="quizEdit" [html]="notebookText"></app-user-notebook>
  </div>

</p-dialog>


<!-- Flashcard Feature -->
<p-dialog header="Flashcards" styleClass="flashcardDialog" [(visible)]="displayFlashcards" position="center" [modal]="true" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false" (onHide)="closeFlashcards()">
  <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
    <div class="elhusseniey-sas-flashcard-body">
        <!-- Header of the flashcard block -->
        <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
            <div class="aku-elhusseniey-flashcard-heading-wrapper">
                <h2>Flashcards</h2>
            </div>
            <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                    <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center" style="padding-right: 10px;">
                        <i class="pi pi-window-maximize"></i>
                    </button>
                    <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center" style="padding-right: 10px;">
                        <i class="pi pi-window-minimize"></i>
                    </button>
                </div>
                <div class="flashcard" *ngIf="showFlashcard || createFlashcard">
                  <button class="screenSize-btn flex-display align-center justify-center" style="padding: 0px; padding-right: 5px;" (click)="showFlashcard = false; createFlashcard = false">
                    <i class="fa fa-th"></i>
                  </button>
                </div>
                <div class="aku-elhusseniey-flashcard-close-wrapper">
                    <button (click)="displayFlashcards = false"  class="closeModal-btn flex-display align-center justify-center">
                        <i class="pi pi-times"></i>
                    </button>
                </div>

            </div>

        </div>
        <div class="note">
          <strong>Keyboard Shortcuts:</strong> Copy -> CTRL + C and Paste -> CTRL + V
        </div>
        <div class="search_flashcards" *ngIf="!showFlashcard && !createFlashcard">
          <input type="text" [(ngModel)]="flashcardKeyword" placeholder="Search flashcards"/>
          <i class="pi pi-search" style="cursor: pointer;" (click)="searchFlashcards()"></i>
        </div>
        <div class="flashcard_body" *ngIf="searcher">
          <div class="deck_content" style="justify-content: flex-start;">
              <i class="pi pi-arrow-left" style="color: rgba(0,0,0,.38); margin-right: 15px; cursor: pointer; font-size: 20px;" (click)="searcher = false; flashcardKeyword = ''"></i>
              <span>Search Results</span>
          </div>
          <div class="cards" *ngIf="searchedFlashcards.length > 0">
              <div class="single_card" *ngFor="let flashcard of searchedFlashcards; let i = index;" (click)="selectFlashcard(i, searchedFlashcards)">
                  <span [innerHTML]="flashcard.Question"></span>
              </div>
          </div>
          <div class="no_cards" *ngIf="searchedFlashcards.length <= 0">
              <span>No cards found.</span>
          </div>
        </div>
        <div class="flashcard_body" *ngIf="!showFlashcard && !createFlashcard && !searcher">
          <div class="question_flashcards">
            <span>This Question</span>
            <div class="cards">
              <div class="new_card" (click)="addNewCard()">
                <i class="pi pi-plus"></i>
                <span>New Card</span>
              </div>
              <div class="single_card" *ngFor="let flashcard of questionFlashcards; let i = index" (click)="selectFlashcard(i, questionFlashcards)">
                <span [innerHTML]="flashcard.Question"></span>
              </div>
            </div>
          </div>
          <div class="all_flashcards">
            <span>Other</span>
            <div class="cards">
              <div class="single_card" *ngFor="let flashcard of flashcards; let i = index" (click)="selectFlashcard(i, flashcards)">
                <span [innerHTML]="flashcard.Question"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="flashcard-decks" style="justify-content: space-between; width: 93%;" *ngIf="showFlashcard">
          <div class="deck">
              <div class="circle" [style.backgroundColor]="showFlashcards[selectedFlashcard]?.DeckColor">{{showFlashcards[selectedFlashcard]?.Deck[0]}}</div>
              <span>{{showFlashcards[selectedFlashcard]?.Deck}}</span>
          </div>
          <div class="view" (click)="changeView()">
              <span>{{flashcardView}}</span>
              <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
          </div>
          <div></div>
          <div class="options">
              <i class="pi pi-ellipsis-v" style="cursor: pointer;" (click)="displayCardOptions = true"></i>
              <div class="deck-dropdown" *ngIf="displayCardOptions">
                  <div class="close_dropdown">
                      <i class="pi pi-times" (click)="displayCardOptions = false"></i>
                  </div>
                  <div class="dropdown_content">
                      <div class="item" (click)="openEditFlashcardDialog(showFlashcards[selectedFlashcard])">
                          <i class="fa fa-pencil-square-o" style="margin-right: 10px;"></i>
                          <span>Edit</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- flashcard main body -->
      <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly" *ngIf="flashcardView == 'Single Side View' && showFlashcard">
          <div class="flashcard-front-wrapper" style="width: 100%;">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Back' ? '#2196F3' : 'black'}" (click)="position = 'Back'" style="cursor: pointer; width: 15%;">Back</span>
                  <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Front' ? '#2196F3' : 'black'}" (click)="position = 'Front'" style="cursor: pointer; width: 15%;">Front</span>
              </div>
              <div class="flashcard-editor-wrapper">
                  <div class="flashcard-text" *ngIf="position == 'Front'" [innerHTML]="showFlashcards[selectedFlashcard]?.Question"></div>
                  <div class="flashcard-text" *ngIf="position == 'Back'" [innerHTML]="showFlashcards[selectedFlashcard]?.Answer"></div>
                  <div class="tags" *ngIf="position == 'Back'">
                      <i class="pi pi-tag"></i>
                      <span *ngFor="let tag of showFlashcards[selectedFlashcard]?.Tags">{{tag}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="aku-elhusseniey-flashcard-main-wrapper flex-display space-between" *ngIf="flashcardView == 'Double Side View' && showFlashcard">
          <div class="flashcard-front-wrapper" style="width: 48%; height: 350px;">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span class="flex-display align-center justify-center">Front</span>
              </div>
              <div class="flashcard-editor-wrapper" style="height: 100%;">
                  <div class="flashcard-text" [innerHTML]="showFlashcards[selectedFlashcard]?.Question"></div>
              </div>
          </div> 
          <div class="flashcard-back-wrapper" style="width: 48%;" [ngStyle]="{'height': position == 'Back' ? '303px': '350px'}">
              <div class="flashcard-header flex-display align-center justify-end">
                  <span  class="flex-display align-center justify-center">Back</span>
              </div>
              <div class="flashcard-editor-wrapper" style="height: 100%;">
                  <div class="flashcard-text" style="background-color: #d9d9d9;" *ngIf="position == 'Front'"></div>
                  <div class="flashcard-text" [innerHTML]="showFlashcards[selectedFlashcard]?.Answer" *ngIf="position == 'Back'"></div>
                  <div class="tags" *ngIf="position == 'Back'">
                      <i class="pi pi-tag"></i>
                      <span *ngFor="let tag of showFlashcards[selectedFlashcard]?.Tags">{{tag}}</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="flashcard-footer flex-display align-center space-between" style="flex-direction: column;" *ngIf="showFlashcard">
          <div class="flashcard-numbers">
              {{selectedFlashcard + 1}} of {{showFlashcards.length}}
          </div>
          <div class="flashcard-buttons">
              <button (click)="prevCardIndex()">
                  Previous
              </button>
              <button *ngIf="position == 'Front'" (click)="position = 'Back'">
                  Show Answer
              </button>
              <button *ngIf="position == 'Back'" (click)="position = 'Front'">
                  Hide Answer
              </button>
              <button (click)="nextCardIndex()">
                  Next
              </button>
          </div>
      </div>
      <div class="new_flashcard" *ngIf="createFlashcard">
        <div class="flashcard-decks" (click)="displayDropdown = !displayDropdown">
          <div class="circle" [style.backgroundColor]="selectedDeck?.Color">{{selectedDeck?.Title[0]}}</div>
          <span>{{selectedDeck?.Title}}</span>
          <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
        </div>
        <div class="deck-dropdown" *ngIf="displayDropdown">
            <div class="close_dropdown">
                <i class="pi pi-times" (click)="displayDropdown = false;"></i>
            </div>
            <div class="new_deck" (click)="displayDeck = true;" style="border-bottom: 1px solid #e7e7e7;">
              <i class="pi pi-plus"></i>
              <span>New Deck</span>
            </div>
            <div class="single_deck" *ngFor="let deck of decks; let i = index" [ngStyle]="{'border-bottom' : i < decks.length - 1 ? '1px solid #e7e7e7' : 'none'}" (click)="selectedDeck = deck; displayDropdown = false;">
                <div class="circle" [style.backgroundColor]="deck.Color"></div>
                <span>{{deck.Title}}</span>
            </div>
        </div>
        <!-- flashcard main body -->
        <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
            <div class="flashcard-front-wrapper">
                <div class="flashcard-header flex-display align-center justify-end">
                    <span class="flex-display align-center justify-center">Front</span>
                </div>
                <div class="flashcard-editor-wrapper">
                  <ckeditor id="ckEditorFront" [(ngModel)]="frontEditor"></ckeditor>
                </div>
            </div>
            <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                <button class="interchange-btn">
                    <i class="fa fa-exchange"></i>
                </button>
            </div>
            <div class="flashcard-back-wrapper">
                <div class="flashcard-header flex-display align-center justify-end">
                    <span  class="flex-display align-center justify-center">Back</span>
                </div>
                <div class="flashcard-editor-wrapper">
                  <ckeditor id="ckEditorBack" [(ngModel)]="backEditor"></ckeditor>
                </div>
            </div>
        </div>
        <div class="flashcard-chip-wrapper flex-display align-center ">
            <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
            </p-chips>
        </div>
        <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
            <button class="clear-btn" (click)="createFlashcard = false;">
                Cancel
            </button>
            <button class="apply-btn" (click)="(existingFlashcard ? editDeckFlashcard() : addDeckFlashcard())">
                Save
            </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit Flashcard" styleClass="flashcardDialog" [(visible)]="displayEditFlashcard" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false"
[resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="displayEditFlashcard = false"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
                <div class="flashcard-front-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                      <ckeditor id="ckEditorFront" [(ngModel)]="frontEditor"></ckeditor>
                    </div>
                </div>
                <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                    <button class="interchange-btn">
                        <i class="fa fa-exchange"></i>
                    </button>
                </div>
                <div class="flashcard-back-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                      <ckeditor id="ckEditorBack" [(ngModel)]="backEditor"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="flashcard-chip-wrapper flex-display align-center ">
                <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
                </p-chips>
            </div>
            <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
                <button class="clear-btn" (click)="displayEditFlashcard = false">
                    Cancel
                </button>
                <button class="apply-btn" (click)="editUserFlashcard()">
                    Update
                </button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="" styleClass="continueDialog" [(visible)]="displayContinue" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false">
  <span>Please select a card to continue</span>
</p-dialog>

<p-dialog header="" styleClass="cardDialog" [(visible)]="displayCards" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
  <div class="addToFlashcard">
    <div class="heading">
      <span>Add To Flashcard</span>
    </div>
    <div class="content">
      <span>Please select which side you wish to add your selected content</span>
    </div>
    <div class="buttons">
      <button (click)="updateHTML('Front')">Front</button>
      <button (click)="updateHTML('Back')">Back</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Exhibit Display" styleClass="imgDialog" [(visible)]="displayImg" position="center" [modal]="false" [baseZIndex]="10000"  [style]="{ width: '500px' }" [draggable]="false" [modal]="true">
  <div class="imgDialog_root">
    <div class="loader" style="margin: 4rem 0;" *ngIf="!showImage"></div>
    <div class="showImage" id="pic" *ngIf="showType === 0 && showImage">
      <pinch-zoom [limit-zoom]="4">
        <img src={{showImage}}>
      </pinch-zoom>
    </div>
    <div class="showImage" id="pic" *ngIf="showType === 1 && showImage">
        <div [innerHTML]="showImage | safeHtml" class="showTable"></div>
    </div>
    <div class="showImage" id="pic" *ngIf="showType === 2 && showImage">
        <video controls controlsList="nodownload">
            <source src="{{ showImage }}" type="video/mp4">
        </video>
    </div>
    <div class="showImage" id="pic" *ngIf="showType === 3 && showImage">
        <audio controls controlsList="nodownload">
            <source src="{{ showImage }}" type="audio/mp3">
        </audio>
    </div>
    <div class="showOptions" *ngIf="showType === 0 || showType === 1">
      <span (click)="imgToNewFlashcard()"><i class="fa fa-bolt" style="margin-right: 10px;"></i>New Flashcard</span>
      <span (click)="imgToExistingFlashcard()"><i class="fa fa-bolt" style="margin-right: 10px;"></i>Existing Flashcard</span>
      <span (click)="imgToNotebook()"><i class="fa fa-book" style="margin-right: 10px;"></i>Add To Notebook</span>
    </div>
  </div>
</p-dialog>

<p-dialog header="Create Deck" styleClass="deckDialog" [(visible)]="displayDeck" [modal]="true" [style]="{width: '280px'}"
    [draggable]="false" [resizable]="false" [baseZIndex]="100000">
    <div class="createDeck">
        <input type="text" placeholder="Enter Name" [(ngModel)]="deck.title" [style.borderBottomColor]="deck.color">
        <div class="colors">
            <div [style.backgroundColor]="color" class="circle" *ngFor="let color of deckColors" (click)="deck.color = color">
                <i *ngIf="deck.color == color" class="pi pi-check"></i>
            </div>
        </div>
    </div>
    <div class="createButtons">
        <button (click)="displayDeck = false;">Cancel</button>
        <button (click)="addNewUserDeck()">Create</button>
    </div>

</p-dialog>

<p-dialog header="Generated AI Flashcards" [(visible)]="displayAIFlashcards" [modal]="true" [closable]="false"
  [style]="{width: '600px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="decks_content">
    <div class="decks_option">
      <div class="option">
        <input type="radio" [(ngModel)]="flashcardSelection" [value]="0" (change)="changeDeckOption()">
        <label>Please select from available decks</label>
      </div>
      <div class="option">
        <input type="radio" [(ngModel)]="flashcardSelection" [value]="1" (change)="changeDeckOption()">
        <label>Or add a new deck</label>
      </div>
    </div>
    <div class="decks">
      <p-dropdown *ngIf="flashcardSelection === 0" [options]="aiDecks" [(ngModel)]="selectedAIDeck" optionLabel="Title" optionValue="DeckID"></p-dropdown>
      <input *ngIf="flashcardSelection === 1" type="text" [(ngModel)]="selectedAIDeck" placeholder="Enter deck name">
    </div>
    <div class="buttons">
        <button class="cancel" (click)="displayAIFlashcards = false;">Cancel</button>
        <button class="create" (click)="updateQuestionFlashcards()" [ngStyle]="{ 'background': !selectedAIDeck ? '#E3E3E3' : '' }" [disabled]="!selectedAIDeck">Save</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Feedback" styleClass="feedbackDialog" [style]="{width: '600px'}"  [(visible)]="displayFeedback" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
    <div class="feedback_content">
      <div class="content">
        <textarea placeholder="We appreciate your feedback. Please provide as much detail as possible in the space provided." rows="8" [(ngModel)]="feedbackText"></textarea>
      </div>
      <div class="button-loader" *ngIf="buttonLoader">
        <div class="loader"></div>
      </div>
      <div class="buttons flex justify-content-end" *ngIf="!buttonLoader">
        <button class="cancel" (click)="displayFeedback = false;">Cancel</button>
        <button class="save" [disabled]="feedbackText === ''" (click)="addArticleFeedback()">Submit</button>
      </div>
    </div>
  </p-dialog>

<app-overlay-loader *ngIf="libraryLoader"></app-overlay-loader>
<app-ai-loader *ngIf="aiLoader" [loaderText]="aiText"></app-ai-loader>

<p-toast></p-toast>