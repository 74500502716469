<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<div class="memberships_root">
    <div class="memberships_parent">
        <div class="memberships_content">
            <h1>Manage Memberships</h1>
            <div class="membership_table">
                <p-table id="membershipTable" #dt [value]="memberships" [globalFilterFields]="['Name']"
                responsiveLayout="scroll" [paginator]='true' [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="rows">
    
                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search by Name" />
                            </span>
                        </div>
                    </ng-template>
                
                    <ng-template pTemplate="header" class="table_header" styleClass="myTable" class="some-custom-class-name" pResizableColumn>
                        <tr>
                            <th pSortableColumn="id" class="id_prevTest_table" [ngStyle]="{'width':'20px'}">#</th>
                            <th pSortableColumn="name" [ngStyle]="{'width':'200px'}">Membership</th>
                            <th pSortableColumn="price" [ngStyle]="{'width':'60px'}">Price</th>
                            <th pSortableColumn="price" [ngStyle]="{'width':'50px'}">Renewal</th>
                            <th pSortableColumn="price" [ngStyle]="{'width':'50px'}">Duration</th>
                            <th pSortableColumn="action" [ngStyle]="{'width':'60px', 'text-align': 'center'}">Actions</th>        
                        </tr>
                        
                    </ng-template>
                    <ng-template pTemplate="body" let-membership let-i="rowIndex" class="table_body">
                        <tr>
                            <td class="id_prevTest_table" [ngStyle]="{'width':'20px'}">{{membership.ID}}</td>
                            <td class="name" [ngStyle]="{'width':'200px'}">{{membership.Name}}</td>
                            <td class="price" [ngStyle]="{'width':'60px'}">{{membership.Price}} EGP</td>
                            <td class="percentage" [ngStyle]="{'width':'50px'}">{{membership.Percentage}}%</td>
                            <td class="duration" [ngStyle]="{'width':'50px'}">{{membership.Duration === durations[0].value ? '6 months' : membership.Duration === durations[1].value ? '1 year' : '2 years'}}</td>
                            <td class="actions" [ngStyle]="{'width':'60px', 'text-align': 'center'}">
                                <i (click)="openEditModal(i)" class="pi pi-pencil" style="cursor: pointer;"></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Edit Membership" [(visible)]="editMembershipModal" [modal]="true" [closable]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false">
  <div class="membership_form">
    <form [formGroup]="editForm">
        <div class="form_field">
            <label>Name</label>
            <input type="text" readonly formControlName="name">
        </div>
        <div class="form_field">
            <label>Price</label>
            <input type="number" min="0" placeholder="Enter Price" required="required" formControlName="price">
        </div>
        <div class="form_field">
            <label>Renewal (In Percentage)</label>
            <input type="number" min="0" placeholder="Enter Renewal Percentage" required="required" formControlName="percentage">
        </div>
        <div class="form_field">
            <label>Duration</label>
            <p-dropdown [options]="durations" formControlName="duration" optionLabel="title" optionValue="value" [style]="{ width: '100%' }" appendTo="body"></p-dropdown>
        </div>
    </form>
    <div class="page-loader" *ngIf="modalLoader">
        <div class="loader"></div>
    </div>
    <div class="buttons" *ngIf="!modalLoader">
        <button (click)="editMembershipModal = false;">Cancel</button>
        <button (click)="editMembership()" [disabled]="editForm.invalid">Save</button>
    </div>
  </div>
</p-dialog>