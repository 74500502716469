import { Component, HostListener, OnInit } from '@angular/core';
import {ChartOptions, ChartType} from "chart.js";
import {Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet} from "ng2-charts";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseService} from "../../shared/services/course.service";
import {Subscription} from "rxjs";
import {ErrorHandlerService} from "../../shared/services/error-handler.service";
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { QuizGeneratorService } from 'src/app/shared/services/quiz-generator.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { demo_variables } from 'src/app/models/demo_global';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  // This is overview screen i.e, Welcome screen of user dashboard
  totalCorrectPercentage: any = 50;
  totalUsedPercentage: any = 20;
  smallfinalarray = [{ID: 103333,
    post_name: "Free Trial",
    post_title: "Free Trial",
    value: 10.04,
    courseImage: "http://elhusseinyusmleprep.com/wp-content/uploads/2020/11/free.png"
  }
    ]
  // @ts-ignore
  subscriptions: Subscription = [];
  loader = true;
  multiplier = 100;
  totalcourses: any;
  completedcouses: any;
  enrolledcourses: any;
  courses: any;
  percentages: any;
  // Adding percentages and total courses api data in one variable
  finalCourseArray: any;
  // Making copy of it to save it for search function
  finalCourseArray2: any;

  // smallfinalarray: any;

  // smallfinalarray: any;
  contentLoader = true;
  demo = false;

  searchText = ''

  totalQues = 1;
  unusedQues = 1;
  usedQues = 3;
  courseId: any = [];


  pointersColorClasses = ['eup-green-pointer','eup-red-pointer','eup-blue-pointer','eup-purple-pointer'];
  chartType: ChartType = 'pie';
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  chartColors: Array<any> = [
    {
      backgroundColor: [
        '#61ce87',
        '#e14f6a',
        '#389cf2',
        '#7583f0'
      ]
    }
  ];
  pieChartLabels1: Label[] = [['Total Questions'], ['Used  Questions'], ['Unused Questions']];
  pieChartLabels: Label[] = [['Correct to Correct'], ['Correct to Incorrect'], ['Incorrect to Correct'], ['Incorrect to Incorrect']];
  pieChartData1: SingleDataSet = [12, 2, 10];
  pieChartData: SingleDataSet = [20, 16, 36, 40];
  pieChartType: ChartType = 'pie';
  pieChartLegend = false;
  pieChartPlugins = [];
  cardLabels = [
    {label: 'Total Correct', value: 2, bgClass: 'eup-green-card'},
    {label: 'Total Incorrect', value: 2, bgClass: 'eup-red-card'},
    {label: 'Total Omitted', value: 5, bgClass: 'eup-blue-card'},
    // {label: 'Incorrect to Incorrect', value: 20, bgClass: 'eup-purple-card'},
  ];

  cardLabels1 = [
    {label: 'Total Questions', value: 12, bgClass: 'eup-green-card'},
    {label: 'Used Questions', value: 2, bgClass: 'eup-red-card'},
    {label: 'Unused Questions', value: 10, bgClass: 'eup-blue-card'}
  ];

  innerWidth: any;
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup-navbar-heading').innerText=''
  }
}

  constructor(private adminService: AdminService,private quizgeneratorService: QuizGeneratorService ,private globalService: GlobalService,private authService: AuthService, private route:ActivatedRoute, private router:Router, private courseService: CourseService, private errorHandler: ErrorHandlerService ) {

  }

  async ngOnInit(){
    if(!localStorage.getItem("token")){
      this.router.navigate(['/']);
    }
    else{
    }
    if(localStorage.getItem('Id') == '-1'){
      this.demo = true;
    }
    if(this.demo){
      this.getDemoVariables()
    }
    else{
      let index = 0;
    if (localStorage.getItem('subscriptions')) {
      this.subscribedCourses = JSON.parse(localStorage.getItem('subscriptions'))
      this.courseId = this.subscribedCourses.map((item: any) => item.courseID)
      
      if (sessionStorage.getItem('userSelectedCourse')) {
        index = this.subscribedCourses.findIndex((item: any) => item.courseName == sessionStorage.getItem('userSelectedCourse'))
      }
    }
    if (this.subscribedCourses.length > 1) {
      this.individualCourseData(this.courseId[index]);
    } else {
      this.getNumbers()
    }
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Dashboard';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }


    await this.getDataCount();

    // ************** GET CATEGORIES AND QUESTION COUNT
  //   if(localStorage.getItem("getCategories")){
  //     var getCatResult = JSON.parse(localStorage.getItem("getCategories"));
  //     for(let i = 0; i< getCatResult.Categories.length; i++){
  //        if(getCatResult.Categories[i].parentCategory == "q-courses"){
  //           this.courses = getCatResult.Categories[i].subCategories;
  //         }
  //       }

  //       for(let i=0; i<this.courses.length; i++){
  //         this.selectedCourse = this.courses[i];
  //         await this.getQuestionNumbers();
  //       }
  //   }
  //   else{
  //   await this.getAllCategories();
  // }


    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email')) ;
    }
  }



  searchCourse(): void{
    console.log(this.searchText.toLowerCase() , this.finalCourseArray)
    // if(this.searchText == ''){
    //   this.finalCourseArray2 = this.finalCourseArray;
    // }
    // else{

      // @ts-ignore
      this.smallfinalarray = this.finalCourseArray.filter(course => {
        return (course.post_title).toLowerCase().includes(this.searchText);
      })
    // }
  }

  getDemoVariables(): any{
    console.log('Getting Demo Variables')
    this.finalCourseArray = this.smallfinalarray;

    this.contentLoader = false;
    localStorage.setItem("coursearray", JSON.stringify(this.smallfinalarray));
    // this.smallfinalarray = demo_variables.smallfinalarray;
  }


  getData(): void{
    this.contentLoader = true;
    // @ts-ignore
    this.subscriptions.push(
      this.courseService.getAllCourses().subscribe((res: any) => {
        this.loader = false;
        if(res.status){
          this.courses = res.courseDetails;
          // res.courseDetails.forEach((course: any) => {
          //   this.courses.push(
          //     {
          //       courseId: course.ID,
          //       courseTitle: course.post_title,
          //       courseIntro: course.post_excerpt,
          //     }
          //   )
          // });
          this.contentLoader = false;
          this.getDataCount();


        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  subscribedCourses: any = [];
  getNumbers(): void{
    // this.contentLoader = true;
    var quizId = "";
    // @ts-ignore
    this.subscriptions.push(
      this.courseService.getNumbers(quizId, this.courseId).subscribe((res: any) => {
        this.totalQues = res.totalQuestions;
        this.unusedQues = res.totalQuestions - res.totalUsedQuestions;
        this.usedQues = res.totalUsedQuestions;
        this.totalCorrectPercentage = ((res.totalCorrectQuestions / (res.totalCorrectQuestions + res.totalIncorrectQuestions + res.totalOmittedQuestions)) * 100).toFixed(1);
        if(this.totalCorrectPercentage>100){
          this.totalCorrectPercentage = 100;
        }
        this.totalUsedPercentage = ((res.totalUsedQuestions/res.totalQuestions)*100).toFixed(1);
        if(this.totalUsedPercentage>100){
          this.totalUsedPercentage = 100;
        }
        // this.totalUsedPercentage = Math.ceil(this.totalUsedPercentage)
        this.pieChartData1 = []
        this.pieChartData1.push(this.totalQues);
        this.pieChartData1.push(this.usedQues);
        this.pieChartData1.push(this.unusedQues);
        this.cardLabels1[0].value = this.totalQues;
        this.cardLabels1[1].value = this.usedQues;
        this.cardLabels1[2].value = this.unusedQues;

        this.pieChartData = [];
        this.pieChartData.push(res.totalCorrect_Correct);
        this.pieChartData.push(res.totalCorrect_Incorrect);
        this.pieChartData.push(res.totalIncorrect_Correct);
        this.pieChartData.push(res.totalIncorrect_Incorrect);

        this.cardLabels[0].value = res.totalCorrectQuestions;
        this.cardLabels[1].value = res.totalIncorrectQuestions;
        this.cardLabels[2].value = res.totalOmittedQuestions;

      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  individualCourseData(course_id: any){
    this.courseId = [course_id];
    this.getNumbers();
  }


  getDataCount(): void{
    this.contentLoader = true

     // @ts-ignore
     this.subscriptions.push(
      this.courseService.getCourseDataCount().subscribe((res: any) => {
        if(res.status){

          this.totalcourses = res.data.totalCourses;
          this.completedcouses = res.data.completedCourses;
          this.enrolledcourses = res.data.enrolledCourses;
          this.percentages = res.data.courseCompletionStatus;
          this.smallfinalarray = [];
          for(let j=0; j<res.data.courseCompletionStatus.length; j++){
            this.smallfinalarray.push({ID: res.data.courseCompletionStatus[j].courseId, post_title: res.data.courseCompletionStatus[j].post_title, value: res.data.courseCompletionStatus[j].value, post_name: res.data.courseCompletionStatus[j].post_value, courseImage: res.data.courseCompletionStatus[j].courseImage})
          }
          this.finalCourseArray = this.smallfinalarray;

        //   res.data.courseCompletionStatus.forEach((course: any) => {
        //     this.completionValue.push(
        //       {
        //         // @ts-ignore
        //         value: parseFloat(course.value * this.multiplier).toFixed(2)
        //       }
        //     )
        //   })
        // @ts-ignore
        // this.finalCourseArray = this.courses.map(t1 => ({...t1, ...this.percentages.find(t2 => t2.courseId === t1.ID)}))
        // this.finalCourseArray2 = this.finalCourseArray;

        // @ts-ignore
        // this.smallfinalarray = this.finalCourseArray.map(({ID, post_name, post_title, value}) => ({ID, post_name, post_title, value}))

        localStorage.setItem("coursearray", JSON.stringify(this.smallfinalarray));
        this.contentLoader = false;

        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }


  // GETTING RESULTS FOR GENERATE QUIZ SCREEN
  AllCategories: any;
  totalQuestion: any
  subjects: any;
  systems: any;
  topics: any;

  subjectQuestionNumberarray;
  selectedCourse;
  systemQuestionNumberarray;
  topicQuestionNumberarray;
  totalResponseFromGetQuestions = [];
  async getQuestionNumbers(){
    // @ts-ignore
    this.subscriptions.push(
      this.quizgeneratorService.getAllQuestionsNumbers(this.selectedCourse).subscribe((res: any) => {
        this.totalResponseFromGetQuestions.push(res)
        // this.subjectQuestionNumberarray = [];
        // this.systemQuestionNumberarray = [];
        // this.topicQuestionNumberarray = [];
        // this.subjects = [];
        // this.systems = [];
        // this.topics = [];
        // for(let i=0; i<res.subjects.length; i++){
        //   this.subjects.push(res.subjects[i].Title);
        //   this.subjectQuestionNumberarray.push({title: res.subjects[i].Title,questions: res.subjects[i].questions})
        // }
        // for(let i=0; i<res.systems.length; i++){
        //   this.systems.push(res.systems[i].Title);
        //   this.systemQuestionNumberarray.push({title: res.systems[i].Title,questions: res.systems[i].questions})
        // }
        // for(let i=0; i<res.topics.length; i++){
        //   this.topics.push(res.topics[i].Title);
        //   this.topicQuestionNumberarray.push({title: res.topics[i].Title,questions: res.topics[i].questions})
        // }
        // // this.selectedCategories = []
        if(res.status){
        localStorage.setItem("getQuestionNUmbers", JSON.stringify(this.totalResponseFromGetQuestions));
        }

      }))
  }

}
