import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as Editor from '../../../assets/ckeditor/build/ckeditor';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as pako from 'pako';
@Component({
  selector: 'app-ready-flashcards',
  templateUrl: './ready-flashcards.component.html',
  styleUrls: ['./ready-flashcards.component.scss'],
  providers: [MessageService]
})
export class ReadyFlashcardsComponent implements OnInit {
  keyword = ""
  decks = []

  allDecks: any = [];
  deck = {
    "userId": localStorage.getItem('Id'),
    "title": "",
    "color": "#62B5FC",
    "isAdminDeck": 1,
    "courseId": 115014
  }
  
  card = {
    "question": "",
    "answer": "",
    "tags": [],
    "deckId": -1,
    "questionId": -1
  }

  selectedDeck: any;
  selectedCard: any;
  deckActiveIndex = 0;
  cardActiveIndex = 0;
  deckId = 0
  cardId = 0

  deckColors = ['#62B5FC', '#F15856', '#FB7915', '#FFCC33', '#B13EDB',
                '#7274E1', '#6E756D', '#BF5D5D', '#FF8BF0', '#36C8A3'] 

  displayDeck = false
  displayCard = false
  displayDropdown = false
  displayFlashcards = false
  displayEditDeck = false
  displayAll = false
  displayCardOptions = false
  displayEditFlashcard = false
  displayImg = false;
  showImage = '';

  searcher = false;
  searchedFlashcards = []

  isFullScreen = false;
  position = 'Front'

  dropdownEnabled = false;
  isLoad = false;

  flashcardView = 'Single Side View'

  ckEditorFront: any;
  ckEditorBack: any;

  frontEditor: any;
  backEditor: any;

  public ClassicEditor: any = Editor;

  loader = false;

  courses: any = [
    {
      "courseID": 115014, 
      "courseName": "USMLE Step 1"
    },
    {
      "courseID": 115015, 
      "courseName": "USMLE Step 2 CK"
    },
    {
      "courseID": 135631, 
      "courseName": "USMLE Step 3"
    }
  ]
  selectedCourse = {
    "courseID": 115014, 
    "courseName": "USMLE Step 1"
  };

  constructor(private adminService: AdminService, private confirmationService: ConfirmationService, private messageService: MessageService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getAdminDeck()
  }

  changeDecks() {
    this.decks = this.allDecks.filter((item: any) => item.CourseID === this.selectedCourse);
    sessionStorage.setItem('selectedCourse', JSON.stringify(this.selectedCourse))
    if (!this.displayAll) {
      if (this.decks.length > 0) {
        this.selectedDeck = this.decks[0]
      }
    } else {
      const index = this.selectedDeck.deckIndex;
      this.selectedDeck = this.decks[index];
      this.selectedDeck.deckIndex = index;
    }
  }

  addAdminDeck() {
    this.displayDeck = false
    this.loader = true;
    this.adminService.addAdminDeck(this.deck).subscribe((res: any) => {
      this.loader = false;
      if (!res.error) {
        this.deck.title = ""
        this.getAdminDeck()
      }
      else {
        this.messageService.add({severity:'error', summary:'Add Deck Failed', detail:'Something went wrong while adding deck!'});
      }
    })
  }

  addDeckFlashcard() {
    this.card.question = this.ckEditorFront.getData()
    this.card.answer = this.ckEditorBack.getData()
    this.card.deckId = this.selectedDeck.DeckID
    this.isLoad = true;

    this.adminService.addAdminFlashcard(this.card).subscribe((res: any) => {
      this.isLoad = false;
      if (!res.error) {
        this.card.question = ""
        this.card.answer = ""
        this.card.tags = []
        this.card.deckId = -1
        this.displayCard = false
        this.getAdminDeck()
      }
      else {
        this.messageService.add({severity:'error', summary:'Add Flashcard Failed', detail:'Something went wrong while adding flashcard!'});
      }
    })
  }

  getAdminDeck() {
    let data = {
      "userId": localStorage.getItem('Id')
    }
    this.loader = true
    this.adminService.getAdminDeck(data).subscribe((res: any) => {
      const decompressedData = pako.inflate(res.data.decks.data, { to: 'string' });
      res.data.decks = JSON.parse(decompressedData);

      this.loader = false
      this.decks = res.data.decks;
      this.allDecks = JSON.parse(JSON.stringify(this.decks));

      if (this.selectedCourse.courseID) {
        this.decks = this.allDecks.filter((item: any) => item.CourseID === this.selectedCourse.courseID);
      } else {
        this.decks = this.allDecks.filter((item: any) => item.CourseID === this.selectedCourse);
      }

      this.decks.forEach((decks, index) => {
        this.decks[index].Flashcards.forEach((flashcards) => {
          if (flashcards.NextStudyDate == '0000-00-00 00:00:00') {
            flashcards.NextStudyDate = new Date()
          }
          flashcards.OriginalQuestion = flashcards.Question;
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
          flashcards.Tags = JSON.parse(flashcards.Tags);
        })
      })

      if (sessionStorage.getItem('selectedCourse')) {
        this.selectedCourse = JSON.parse(sessionStorage.getItem('selectedCourse'));
        this.changeDecks();
      } else {
        if (!this.displayAll) {
          if (this.decks.length > 0) {
            this.selectedDeck = this.decks[0]
          }
        } else {
          const index = this.selectedDeck.deckIndex;
          this.selectedDeck = this.decks[index];
          this.selectedDeck.deckIndex = index;
        }
      }
    })
  }

  openFullscreen() {
    this.isFullScreen = true;
  }

  closeFullscreen() {
    this.isFullScreen = false;
  }

  closeModal() {
    this.displayCard = false
  }

  openCardDialog() {
    this.displayDropdown = false;
    this.deckActiveIndex = 0;
    this.cardActiveIndex = 0;

    this.card.question = ""
    this.card.answer = ""
    this.card.tags = []
    
    this.displayCard = true

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      this.ClassicEditor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        editor.setData("")
      })

      this.ClassicEditor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
        editor.setData("")
      })
    }, 100)
  }

  openFlashcard(card: any, deckIndex: any, cardIndex: any) {
    this.position = 'Front'
    this.displayFlashcards = true
    this.selectedCard = card
    this.deckActiveIndex = deckIndex
    this.cardActiveIndex = cardIndex
    this.flashcardView = 'Single Side View'

    this.setImageDisplay();
  }

  updatePosition(position: any) {
    this.position = position;

    this.setImageDisplay();
  }

  showAnswer() {
    this.position = 'Back';
    this.setImageDisplay();
  }

  openSearchFlashcard(card: any, cardIndex: any) {
    this.position = 'Front'
    this.displayFlashcards = true
    this.selectedCard = card
    this.deckActiveIndex = this.decks.findIndex((item => item.DeckID == card.DeckID))
    this.cardActiveIndex = cardIndex
    this.flashcardView = 'Single Side View'
  }

  prevCardIndex() {
    this.displayCardOptions = false
    if (this.cardActiveIndex > 0) {
      this.cardActiveIndex --
      this.position = 'Front'
    }
  }

  nextCardIndex() {
    this.displayCardOptions = false
    if (this.cardActiveIndex < this.decks[this.deckActiveIndex].Flashcards.length - 1) {
      this.cardActiveIndex ++
      this.position = 'Front'
    }
  }

  searchFlashcards() {
    if (this.keyword == "" || this.keyword.length < 3) {
      this.messageService.add({severity:'error', summary:'Error searching flashcards', detail:'Search keywords cannot be less than 3 characters.'});
    }
    else {
      this.searchedFlashcards = []
      this.decks.forEach((element) => {
        this.searchedFlashcards = [...this.searchedFlashcards, ...element.Flashcards]
      })

      let tempFlashcards = []
      this.searcher = true
      this.displayAll = false

      this.searchedFlashcards.forEach((element) => {  
        if ((element.OriginalQuestion.toLowerCase().includes(this.keyword.toLowerCase())) || (element.Answer.toLowerCase().includes(this.keyword.toLowerCase()))) {
          tempFlashcards.push(element)
        }
      });

      this.searchedFlashcards = tempFlashcards
    }
  }

  openDropdown(value: any) {
    this.decks.forEach((element, index) => {
      document.getElementById('dropdown'+index).style.display = 'none'
    })
    document.getElementById('dropdown'+value).style.display = 'flex'
    this.dropdownEnabled = true
  }
  
  closeDropdown(index: any) {
    document.getElementById('dropdown'+index).style.display = 'none'
    this.dropdownEnabled = false
  }

  openEditDialog(deck: any) {
    this.deck.title = deck.Title
    this.deck.color = deck.Color
    this.deckId = deck.DeckID
    this.displayEditDeck = true
  }

  openEditFlashcardDialog(flashcard: any) {
    this.card.tags = flashcard.Tags
    this.displayEditFlashcard = true
    this.displayFlashcards = false
    this.deckId = flashcard.DeckID
    this.cardId = flashcard.FlashcardID
    this.displayCardOptions = false

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      this.ClassicEditor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        editor.setData(flashcard.Question.changingThisBreaksApplicationSecurity)
      })

      this.ClassicEditor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
        editor.setData(flashcard.Answer)
      })
    }, 100)
  }

  deleteAdminDeck(deckId: any, flashcards: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this deck?',
      accept: () => {
        let data = {
          'deckId': deckId,
          'flashcardId': flashcards.map(item => item.FlashcardID),
          'userId': localStorage.getItem('Id')
        }
        this.loader = true;
        this.adminService.deleteAdminDeck(data).subscribe((res: any) => {
          this.getAdminDeck()
        })
      },
      reject: () => {
      }
    })
  }

  deleteAdminFlashcard() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this flashcard?',
      accept: () => {
        let data = {
          'deckId': this.selectedCard.DeckID,
          'flashcardId': this.selectedCard.FlashcardID
        }
        this.loader = true;
        this.displayFlashcards = false;
        this.adminService.deleteAdminFlashcard(data).subscribe((res: any) => {
          this.getAdminDeck()
        })
      },
      reject: () => {
      }
    })
  }

  editAdminDeck() {
    let data = {
      'deckId': this.deckId,
      'title': this.deck.title,
      'color': this.deck.color
    }
    this.adminService.editAdminDeck(data).subscribe((res: any) => {
      this.displayEditDeck = false
      this.getAdminDeck()
    })
  }

  editAdminFlashcard() {
    let data = {
      'deckId': this.deckId,
      'question': this.ckEditorFront.getData(),
      'answer': this.ckEditorBack.getData(),
      'tags': this.card.tags,
      'flashcardId': this.cardId
    }
    this.isLoad = true;
    this.adminService.editAdminFlashcard(data).subscribe((res: any) => {
      this.isLoad = false;
      this.displayEditFlashcard = false
      this.getAdminDeck()
    })
  }

  shuffleCards() {
    this.selectedDeck.Flashcards.sort((a: any, b: any) => 0.5 - Math.random())
  }

  seeAll(deck: any, deckIndex: any) {
    this.selectedDeck = deck;
    this.selectedDeck.deckIndex = deckIndex;
    this.displayAll = true;
  }

  changeView() {
    if (this.flashcardView == 'Single Side View') {
      this.flashcardView = 'Double Side View'
    }
    else {
      this.flashcardView = 'Single Side View'
    }
    this.setImageDisplay();
  }

  setImageDisplay() {
    // Image Click Event //
    setTimeout(() => {
      document.querySelectorAll('.flashcard-editor-wrapper img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 500)
  }

  removeDisplayAll() {
    this.keyword = ''
    this.displayAll = false; 
    this.selectedDeck = this.decks[0]
  }
}
