import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizGeneratorService } from '../../shared/services/quiz-generator.service';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { AutoLogout } from '../../models/globals';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { isQuote } from '@angular/compiler';
import {MessageService} from 'primeng/api';
import * as pako from 'pako';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-generate-quiz',
  templateUrl: './generate-quiz.component.html',
  styleUrls: ['./generate-quiz.component.scss'],
  providers: [MessageService]
})
export class GenerateQuizComponent implements OnInit, OnDestroy {
  selectAll = false;
  quizIdInput: any = '';
  isCustomQuestion = false;
  inputQuestionIds = ''
  totalQuestion: any;
  data: any;
  AllCategories: any;
  ParentCategories: string[] = [];
  questionsIds: any = [];
  tempSubject = [];
  tempSystem = [];
  tempTopic = [];
  searchTopic = [];
  searchText: any;

  // @ts-ignore
  subscriptions: Subscription[] = [];

  quizTitle: any;

  numberOfQuestion = 0;
  statusLevels = -1;

  difficultyLevels: number[] = [];
  diffOne = false;
  diffTwo = false;
  diffThree = false;
  diffFour = false;
  diffFive = false;
  selectedCourse: any;

  selectedCategories: any[] = [];

  courses: any[] = [
    { name: 'Allergy & Immunology', key: 'A' },
    { name: 'Nervous System', key: 'M' },
  ];

  systems: any[] = [
    { name: 'Immunology & Allergy', key: 'A' },
    { name: 'Nervous System', key: 'M' },
  ];

  subjects: any[] = [
    { name: 'Allergy', key: 'A' },
    { name: 'Nervous System', key: 'M' },
  ];

  topics: any[] = [
    { name: 'Topic 01', key: 'A' },
    { name: 'Nervous System 02', key: 'M' },
  ];

  checked: boolean = false;
  tutorMode = true;
  timeMode = false;
  questionMode = true;
  IsCourses = true;
  IsSystems = false;
  IsSubjects = false;
  IsTopics = false;
  IsQbank = false;
  headingArray = [
  'Courses', 'QBank', 'Subjects', 'Systems', 'Topics'];

  QuestionTypes: any[] = [
    { name: 'Marked Questions', key: 'MQ' },
    { name: 'Omitted Questions', key: 'OQ' },
    { name: 'Unused Questions', key: 'UQ' },
    { name: 'Correct Questions', key: 'CQ' },
    { name: 'Incorrect Questions', key: 'ICQ' },
    { name: 'All', key: 'ALL' }
  ];

  selectedQuestion: any;
  tempSelectedCategories: any;

  APIloader = false;

  redcolor = false;

  subjectQuestionNumberarray: any[];
  systemQuestionNumberarray: any[];
  topicQuestionNumberarray: any[];
  innerWidth: any;

  autoSelectedCourse = false;
  isdemo= false;

  allQuestions: any = [];
  tempQuestions: any = [];

  selectedQbank: any = [];
  selectedSubjects: any = [];
  selectedSystems: any = [];
  selectedTopics: any = [];

  generatedQuestions: any = [];

  courseIds: any = [];

  category: any = [false, false, false];
  displayInvalidModal = false;
  invalidQuestions = [];

  constructor(
    private adminService: AdminService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private quizgeneratorService: QuizGeneratorService,
    private errorHandler: ErrorHandlerService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }

  async ngOnInit() {
    if(localStorage.getItem('Id')=='-1'){
      this.isdemo = true;
      this.selectedQuestion = this.QuestionTypes[5];
      this.headingArray = [
        'Courses', 'Subjects', 'Systems', 'Topics'];
    }
    else {
      this.selectedQuestion = this.QuestionTypes[2];
    }
    this.globalService.tawkIO(
      localStorage.getItem('UserName'),
      localStorage.getItem('email')
    );
    this.totalQuestion = 1;
    var current = new Date();
    var datetime = current.toLocaleString();
    var date = current.toLocaleDateString();
    var time = current.toLocaleTimeString();
    this.quizTitle = 'Custom Quiz ' + datetime;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 750) {
      document.getElementById('eup-navbar-heading').innerText = 'Generate Quiz';
    } else {
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    if (localStorage.getItem('getCategories')) {
      this.getAllCategoriesLocal();
    } else {
      this.getAllCategories();
    }

    await this.compareToken();
  }

  // Api call to check if token is expired or not
  compareToken() {
    // @ts-ignore
    this.subscriptions.push(
      this.adminService
        .compareToken(JSON.parse(localStorage.getItem('Id')))
        .subscribe(
          (res: any) => {
            if (res.status) {
            } else {
              this.errorHandler.AutoLogout();
            }
          },
          (error) => {
            this.errorHandler.handleError(error);
          }
        )
    );
  }

  // Checking question number entered by user is greater than 40 or not
  changingQuesNumber(event: any) {
    if (this.numberOfQuestion > 40) {
      this.redcolor = true;
    } else {
      this.redcolor = false;
    }
  }

  retrieveQuizQuestions(){
    let data = {
      userId: localStorage.getItem('Id'),
      quizId: this.quizIdInput,
      isShared: true
    }
    this.message = '';
    this.subscriptions.push(
      this.quizgeneratorService.getQuizQuestions(data).subscribe((res: any) => {
        if(res.data.status == false){
          this.message = 'This quiz doesnot exist in our database.'
        }
        this.inputQuestionIds = res.data.questions.toString()
      })
      )
  }
  
  selectAllCheckBox(){ 
    if(this.IsSubjects){
      this.subjects.forEach(subject => {
        if(this.selectAll[0] == 'Selected'){
        if(this.selectedSubjects.indexOf(subject.Title) == -1 && subject.Questions>0){
          this.selectedSubjects.push(subject.Title);
        }
      }else{
        if(this.selectedSubjects.indexOf(subject.Title) != -1){
          this.selectedSubjects.splice(this.selectedSubjects.indexOf(subject.Title), 2); 
        }
      }
      });
      var tempArray = this.subjects;
      this.subjects = [];
      setTimeout(() => {
        this.subjects = tempArray
        this.filterTheQuestions(null, 'subject')
      }, 10);
    }
    if(this.IsSystems){
      this.systems.forEach(subject => {
        if(this.selectAll[0] == 'Selected'){
        if(this.selectedSystems.indexOf(subject.Title) == -1 && subject.Questions>0){
          this.selectedSystems.push(subject.Title);
        }}
        else{
          if(this.selectedSystems.indexOf(subject.Title) != -1){
            this.selectedSystems.splice(this.selectedSystems.indexOf(subject.Title), 2); 
          }
        }
      });
      var tempArray = this.systems;
      this.systems = [];
      setTimeout(() => {
        this.systems = tempArray
        this.filterTheQuestions(null, 'system')
      }, 10);
    }
    if(this.IsTopics){
      this.topics.forEach(subject => {
        if(this.selectAll[0] == 'Selected'){
        if(this.selectedTopics.indexOf(subject.Title) == -1 && subject.Questions>0){
          this.selectedTopics.push(subject.Title);
        }}
        else{
          if(this.selectedTopics.indexOf(subject.Title) != -1){
            this.selectedTopics.splice(this.selectedTopics.indexOf(subject.Title), 2); 
          }
        }
      });
      var tempArray = this.topics;
      this.topics = [];
      setTimeout(() => {
        this.topics = tempArray
        this.filterTheQuestions(null, 'topic')
      }, 10);
    }
  }

  // Changing mode between tutor and exam
  changeMode(): void {
    this.tutorMode = !this.tutorMode;
  }

  changeQuestionMode(): void {
    this.questionMode = !this.questionMode;
    if (!this.questionMode) {
      this.isCustomQuestion = true
      this.filterQuestions(0);
    } else {
      this.isCustomQuestion = false
    }
  }

  // Headings styling of course subject system topic
  setHeading(index: any): void {
    this.selectAll = false;
    if (index == 1) {
      this.IsQbank = (this.isdemo)?false:true;
      this.IsCourses = false;
      this.IsSystems = false;
      this.IsSubjects = (this.isdemo)?true:false;
      this.IsTopics = false;
    }
    else
     if (index == 0) {
      this.IsQbank = false;
      this.IsCourses = true;
      this.IsSystems = false;
      this.IsSubjects = false;
      this.IsTopics = false;
    } else if (index == 2) {
      this.IsQbank = false;
      this.IsCourses = false;
      this.IsSubjects = (this.isdemo)?false:true;
      this.IsSystems = (this.isdemo)?true:false;
      this.IsTopics = false;
    } else if (index == 3) {
      this.IsQbank = false;
      this.IsCourses = false;
      this.IsSubjects = false;
      this.IsSystems = (this.isdemo)?false:true;
      this.IsTopics = (this.isdemo)?true:false;
    } else {
      this.IsQbank = false;
      this.IsCourses = false;
      this.IsSystems = false;
      this.IsSubjects = false;
      this.IsTopics = true;
    }
  }

  // Generating quiz and getting questions from backend
  value = 0;
  loader = false;
  message = '';
  QuizGenerate(): void {
    this.message = '';
    this.loader = true;
    this.value = 0;
    var num = 0;
    let interval = setInterval(() => {
      this.value = this.value + 13 + num++;
      if (this.value >= 97) {
        this.value = 97;
        clearInterval(interval);
      }
    }, 1200);

    this.filterQuestions(1);
    if (this.generatedQuestions.length > 0) {
      this.data.questionsIds = this.generatedQuestions.map((item: any) => item.ID);
      this.data.questionIds = this.generatedQuestions.map((item: any) => item.ID);
    }
    this.tutorMode == true
      ? localStorage.setItem('QuizMode', 'tutor')
      : localStorage.setItem('QuizMode', 'exam');
    this.timeMode == true
      ? localStorage.setItem('isTimed', JSON.stringify(true))
      : localStorage.setItem('isTimed', JSON.stringify(false));

    if (this.statusLevels == 6) {
      this.data.questionIds = this.inputQuestionIds.split(',');
      this.data.count = this.data.questionIds.length;
      this.data.isCustomQuiz = true;
    } else {
      this.data.isCustomQuiz = false;
    }

    if (localStorage.getItem('subscriptions')) {
      let courses = JSON.parse(localStorage.getItem('subscriptions'))
      this.data.courses = courses.map((item: any) => item.courseID)
    } else {
      this.data.courses = []
    }

    this.subscriptions.push(
      this.quizgeneratorService.getQuestions(this.data).subscribe(
        (res: any) => {
          this.loader = false;

          if (res.data.invalidQuestions) {
            this.displayInvalidModal = true;
            this.invalidQuestions = res.data.invalidQuestions;
          }

          if (res.data.status) {
            this.totalQuestion = res.totalQuestions;
  
            var flaggedQuestions = res.flaggedQuestions;
            var realFlaggedQuestions = [];
            if (flaggedQuestions && flaggedQuestions.length > 0) {
              for (let i = 0; i < flaggedQuestions.length; i++) {
                realFlaggedQuestions.push({
                  QuestionId: flaggedQuestions[i].question_id,
                  index: i,
                });
              }
            }
            localStorage.setItem('viewMode', JSON.stringify(false));
            localStorage.setItem('QuizId', res.data.quizId);
            localStorage.setItem(
              'flaggedQuestion',
              JSON.stringify(realFlaggedQuestions)
            );
            localStorage.setItem('quizTitle', this.quizTitle);
            localStorage.setItem(
              'AllQuestions',
              JSON.stringify(res.data.questions)
            );
            if (this.statusLevels == 4) {
              localStorage.setItem('isFlaggedQuestions', JSON.stringify(true))
            }
            const arr = ['SelectedOptionArray', 'SubmittedQuestions', 'singleQuestionTime', 'currentQuestionIndex', 'CurrentIndex', 'dotQuestionsArray', 'quiz_time', 'timesup', 'AnswerChanges', 'highlightedQuestionIndexes'];
            arr.forEach(i => {
              localStorage.removeItem(i);
            });
            if(res.data.questions.length> 0){
              this.setUserCourse();
              this.router.navigate(['/quiz']);
            }else{
              this.message = 'These questions doesnot exist in our database.'
            } 
          }
          else {
            if (res.data.message) {
              this.message = res.data.message
            }
          }
        },
        (err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  setUserCourse(){
    const data = {
      userId: localStorage.getItem('Id'),
      courseId: this.selectedCourse
    }
    this.authService.setUserCourse(data).subscribe((res: any) => {
      if (res.status) {
        sessionStorage.setItem('userSelectedCourse', data.courseId);
      }
    })
  }

  // Toggling time mode
  changeTimeMode(): void {
    this.timeMode = !this.timeMode;
  }

  // Setting up data to send on backend
  filterQuestions(filterstatus: any): void {
    let Isstatus = false;
    if (filterstatus == 1) {
      Isstatus = true;
    }
    // setting up data variable to send to backend to get all the questions data
    this.getParentCategory();
    if (!this.selectedQuestion) {
      this.statusLevels = -1;
    } else if (this.selectedQuestion.name == 'Unused Questions') {
      this.statusLevels = 0;
    } else if (this.selectedQuestion.name == 'Correct Questions') {
      this.statusLevels = 1;
    } else if (this.selectedQuestion.name == 'Incorrect Questions') {
      this.statusLevels = 2;
    } else if (this.selectedQuestion.name == 'Omitted Questions') {
      this.statusLevels = 3;
    } else if (this.selectedQuestion.name == 'Marked Questions') {
      this.statusLevels = 4;
    } else if (this.selectedQuestion.name == 'All') {
      this.statusLevels = 5;
    }

    if (this.isCustomQuestion) {
      this.statusLevels = 6
    }
    if(this.statusLevels == 6 && this.isdemo){
      this.selectedQuestion = { name: 'All', key: 'ALL' },
      this.APIloader = false;
      // this.selectedQuestion.name = 'All'
      this.messageService.add({severity:'error', summary:'Error', detail:'You cannot use this functionality in demo.'});
      return
    }else{
    this.difficultyLevels = [];
    if (this.diffOne == true) {
      this.difficultyLevels.push(1);
    }
    if (this.diffTwo == true) {
      this.difficultyLevels.push(2);
    }
    if (this.diffThree == true) {
      this.difficultyLevels.push(3);
    }
    if (this.diffFour == true) {
      this.difficultyLevels.push(4);
    }
    if (this.diffFive == true) {
      this.difficultyLevels.push(5);
    }
    if (this.difficultyLevels.length == 0) {
      this.difficultyLevels = [];
    }
    var tempQbank = []
    this.qBank.forEach(element => {
      tempQbank.push(element.Title)
    });
    this.data = {
      parentCategories: this.ParentCategories,
      subCategories: this.tempSelectedCategories,
      statusLevels: this.statusLevels,
      difficultyLevels: this.difficultyLevels,
      status: Isstatus,
      count: this.numberOfQuestion,
      userId: localStorage.getItem('Id'),
      questionIds: [],
      courseId: this.selectedCourse,
      courseNames: this.courses,
      qBankId: (this.selectedQbank && this.selectedQbank.length>0)?this.selectedQbank:null,
      qBankNames: tempQbank
    };
  }
  }

  // On changing of difficulty levels
  difficultylevel(number: any): void {
    number == 1
      ? (this.diffOne = !this.diffOne)
      : number == 2
        ? (this.diffTwo = !this.diffTwo)
        : number == 3
          ? (this.diffThree = !this.diffThree)
          : number == 4
            ? (this.diffFour = !this.diffFour)
            : number == 5
              ? (this.diffFive = !this.diffFive)
              : '';
    this.questionsFilteration()
  }

  getQuestionNumberLocal() {
    var tempres = JSON.parse(localStorage.getItem('getQuestionNUmbers'));
    var res;
    for (let i = 0; i < tempres.length; i++) {
      if (this.selectedCourse == tempres[i].course) {
        res = tempres[i];
        break;
      }
    }
    this.subjectQuestionNumberarray = [];
    this.systemQuestionNumberarray = [];
    this.topicQuestionNumberarray = [];
    this.subjects = [];
    this.systems = [];
    this.topics = [];
    for (let i = 0; i < res.subjects.length; i++) {
      this.subjects.push(res.subjects[i].Title);
      this.subjectQuestionNumberarray.push({
        title: res.subjects[i].Title,
        questions: res.subjects[i].questions,
      });
    }
    for (let i = 0; i < res.systems.length; i++) {
      this.systems.push(res.systems[i].Title);
      this.systemQuestionNumberarray.push({
        title: res.systems[i].Title,
        questions: res.systems[i].questions,
      });
    }
    for (let i = 0; i < res.topics.length; i++) {
      this.topics.push(res.topics[i].Title);
      this.topicQuestionNumberarray.push({
        title: res.topics[i].Title,
        questions: res.topics[i].questions,
      });
    }
    this.selectedCategories = [];
    this.searchTopic = this.topics;
  }

  getParentCategory(): any {
    if (this.selectedCourse) {
      this.selectedCategories.push(this.selectedCourse);
    }
    if (!this.selectedCategories || this.selectedCategories.length == 0) {
      this.ParentCategories = ['q-courses', 'systems', 'subjects', 'topics'];
      this.tempSelectedCategories = this.courses.concat(
        this.systems,
        this.subjects,
        this.topics
      );
    } else {
      this.tempSelectedCategories = this.selectedCategories;
      let checkcourse = 0;
      let checksubject = 0;
      let checksystem = 0;
      let checktopic = 0;
      for (let i = 0; i < this.selectedCategories.length; i++) {
        for (let j = 0; j < this.courses.length; j++) {
          if (this.selectedCategories[i] == this.courses[j]) {
            checkcourse = 1;
            if (!this.ParentCategories.includes('q-courses')) {
              this.ParentCategories.push('q-courses');
            }
          }
        }
        for (let j = 0; j < this.subjects.length; j++) {
          if (this.selectedCategories[i] == this.subjects[j].Title) {
            checksubject = 1;
            if (!this.ParentCategories.includes('subjects')) {
              this.ParentCategories.push('subjects');
            }
          }
        }
        for (let j = 0; j < this.systems.length; j++) {
          if (this.selectedCategories[i] == this.systems[j].Title) {
            checksystem = 1;
            if (!this.ParentCategories.includes('systems')) {
              this.ParentCategories.push('systems');
            }
          }
        }
        for (let j = 0; j < this.topics.length; j++) {
          if (this.selectedCategories[i] == this.topics[j].Title) {
            checktopic = 1;
            if (!this.ParentCategories.includes('topics')) {
              this.ParentCategories.push('topics');
            }
          }
        }
      }
      if (checkcourse == 0) {
        let index = this.ParentCategories.findIndex((p) => p === 'q-courses');
        if (index !== -1) {
          this.ParentCategories.splice(index, 1);
        }
      }
      if (checksubject == 0) {
        let index = this.ParentCategories.findIndex((p) => p === 'subjects');
        if (index !== -1) {
          this.ParentCategories.splice(index, 1);
        }
      }
      if (checktopic == 0) {
        let index = this.ParentCategories.findIndex((p) => p === 'topics');
        if (index !== -1) {
          this.ParentCategories.splice(index, 1);
        }
      }
      if (checksystem == 0) {
        let index = this.ParentCategories.findIndex((p) => p === 'systems');
        if (index !== -1) {
          this.ParentCategories.splice(index, 1);
        }
      }
    }
    this.searchTopic = this.topics;
  }

  selectedCourseForTotalQuestions: any;
  qBank:any;
  async getAllCategories() {
    let courses: any = [];
    if (localStorage.getItem('subscriptions')) {
      courses = JSON.parse(localStorage.getItem('subscriptions'))
    }
    if (this.isdemo) {
      courses = []
      courses.push({
        'courseID': '136132',
        'courseName': 'Free Trial',
        'courseQuestions': 5
      })
    } 
    // @ts-ignore
    this.APIloader = true;
    this.subscriptions.push(
      this.quizgeneratorService.getCategoryQuestions(courses).subscribe(
        async (res: any) => {
          this.APIloader = false;
          if (res.status) {
            const decompressedData = pako.inflate(res.questions.data, { to: 'string' });
            res.questions = JSON.parse(decompressedData);

            this.questionsIds = res.questions;
            var tempsubjects = [];
            var tempsystems = [];
            var temptopics = [];
            this.AllCategories = res;
            this.totalQuestion = res.totalQuestions;
            for (let i = 0; i < res.Categories.length; i++) {
              if (res.Categories[i].parentCategory == 'subjects') {
                for (
                  let l = 0;
                  l < res.Categories[i].subCategories.length;
                  l++
                ) {
                  tempsubjects.push({
                    Title: res.Categories[i].subCategories[l],
                    Questions: 0,
                  });
                }
              } else if (res.Categories[i].parentCategory == 'systems') {
                for (
                  let l = 0;
                  l < res.Categories[i].subCategories.length;
                  l++
                ) {
                  tempsystems.push({
                    Title: res.Categories[i].subCategories[l],
                    Questions: 0,
                  });
                }
              } else if (res.Categories[i].parentCategory == 'topics') {
                for (
                  let l = 0;
                  l < res.Categories[i].subCategories.length;
                  l++
                ) {
                  temptopics.push({
                    Title: res.Categories[i].subCategories[l],
                    Questions: 0,
                  });
                }
              } else if (res.Categories[i].parentCategory == 'q-courses') {
                this.courses = res.Categories[i].subCategories;
              }
              else if (res.Categories[i].parentCategory == 'q-bank') {
                this.qBank = [];
                res.Categories[i].subCategories.forEach(element => {
                  this.qBank.push({Title: element, Questions: 0})
                });
              }
            }

            this.allQuestions = res.questions;

            this.qBank.forEach((element: any) => {
              let count = this.allQuestions.filter((item: any) => item.QBank == element.Title).length
              element.Questions = count
            })

            this.tempQuestions = JSON.parse(JSON.stringify(this.allQuestions))
            this.randomizeQuestions();

            this.courseIds = res.courseIds

            this.subjects = tempsubjects;
            this.systems = tempsystems;
            this.topics = temptopics;
            this.searchTopic = this.topics;
            for (let i = 0; i < this.courses.length; i++) {
              this.selectedCourseForTotalQuestions = this.courses[i];
            }

            if (courses.length > 1) {
              this.selectedCourse = courses[0].courseName;
              if (sessionStorage.getItem('userSelectedCourse')) {
                this.selectedCourse = sessionStorage.getItem('userSelectedCourse');
              }
              this.filterTheQuestions(null, 'course');
            }
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      )
    );
  }

  getAllCategoriesLocal() {
    this.AllCategories = JSON.parse(localStorage.getItem('getCategories'));
    this.totalQuestion = this.AllCategories.totalQuestions;
    for (let i = 0; i < this.AllCategories.Categories.length; i++) {
      if (this.AllCategories.Categories[i].parentCategory == 'subjects') {
        this.subjects = this.AllCategories.Categories[i].subCategories;
      } else if (this.AllCategories.Categories[i].parentCategory == 'systems') {
        this.systems = this.AllCategories.Categories[i].subCategories;
      } else if (this.AllCategories.Categories[i].parentCategory == 'topics') {
        this.topics = this.AllCategories.Categories[i].subCategories;
      } else if (
        this.AllCategories.Categories[i].parentCategory == 'q-courses'
      ) {
        this.courses = this.AllCategories.Categories[i].subCategories;
      }
    }
    this.tempTopic = this.topics;

    if (this.courses.length == 1) {
      this.autoSelectedCourse = true;
      this.selectedCourse = this.courses[0];
      if (sessionStorage.getItem('userSelectedCourse')) {
        this.selectedCourse = sessionStorage.getItem('userSelectedCourse');
      }
      this.getQuestionNumberLocal();
    }
  }

  getQuestionCount() {
    // this.IsStatus=true;
    var data = {
      // parentCategories: this.categories,
      // subCategories: this.selectedSubjects,
      statusLevels: this.statusLevels,
      // difficultyLevels: this.selectedQuestionsTypes,
      courseId: localStorage.getItem('examCourseId'),
      userId: localStorage.getItem('ympUserId'),
    };
  }

  searchTopics() {
    this.topics = this.searchTopic.filter((topic) => {
      return topic.Title.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  filterTheQuestions(value: any, type: any) {
    this.tempQuestions = []

    if (this.selectedCourse) {
      this.tempQuestions = this.allQuestions.filter((item: any) => item.QCourse == this.selectedCourse)
    } else {
      this.tempQuestions = JSON.parse(JSON.stringify(this.allQuestions))
    }

    if (type == 'course') {
      this.selectedSubjects = []
      this.subjects.forEach((element: any) => {
        element.Questions = 0
      })

      this.selectedSystems = []
      this.systems.forEach((element: any) => {
        element.Questions = 0
      })

      this.selectedTopics = []
      this.topics.forEach((element: any) => {
        element.Questions = 0
      })

      this.selectedQbank = []

      this.qBank.forEach((element: any) => {
        let count = this.tempQuestions.filter((item: any) => item.QBank == element.Title).length
        element.Questions = count
      })
    } else if (type == 'qbank') {
      if (this.selectedQbank.length <= 0) {
        this.selectedSubjects = []
        this.subjects.forEach((element: any) => {
          element.Questions = 0
        })

        this.selectedSystems = []
        this.systems.forEach((element: any) => {
          element.Questions = 0
        })

        this.selectedTopics = []
        this.topics.forEach((element: any) => {
          element.Questions = 0
        })

        this.category[0] = false;
        this.category[1] = false;
        this.category[2] = false;
      }
    } else if (type == 'subject') {
      if (this.selectedSubjects.length <= 0) {
        this.selectedSystems = []
        this.systems.forEach((element: any) => {
          element.Questions = 0
        })

        this.selectedTopics = []
        this.topics.forEach((element: any) => {
          element.Questions = 0
        })

        this.category[0] = true;
        this.category[1] = false;
        this.category[2] = false;
      }
    } else if (type == 'system') {
      if (this.selectedSystems.length <= 0) {
        this.selectedTopics = []
        this.topics.forEach((element: any) => {
          element.Questions = 0
        })
      }

      this.category[0] = true;
      this.category[1] = true;
      this.category[2] = false;
    }

    if (this.selectedQbank.length > 0) {
      this.tempQuestions = this.tempQuestions.filter((item: any) => this.selectedQbank.includes(item.QBank));

      this.subjects.forEach((element: any) => {
        let count = this.tempQuestions.filter((item: any) => item.QSubject == element.Title).length
        element.Questions = count
      })

      this.category[0] = true;
      this.category[1] = false;
      this.category[2] = false;
    }
    if (this.selectedSubjects.length > 0) {
      this.tempQuestions = this.tempQuestions.filter((item: any) => this.selectedSubjects.includes(item.QSubject));

      this.systems.forEach((element: any) => {
        let count = this.tempQuestions.filter((item: any) => item.QSystem == element.Title).length
        element.Questions = count
      })

      this.category[0] = true;
      this.category[1] = true;
      this.category[2] = false;
    } 
    if (this.selectedSystems.length > 0) {
      this.tempQuestions = this.tempQuestions.filter((item: any) => this.selectedSystems.includes(item.QSystem));

      this.topics.forEach((element: any) => {
        let count = this.tempQuestions.filter((item: any) => item.QTopic == element.Title).length
        element.Questions = count
      })

      this.category[0] = true;
      this.category[1] = true;
      this.category[2] = true;
    } 
    if (this.selectedTopics.length > 0) {
      this.tempQuestions = this.tempQuestions.filter((item: any) => this.selectedTopics.includes(item.QTopic));
    }

    this.randomizeQuestions()
    
    this.totalQuestion = this.tempQuestions.length
  }

  randomizeQuestions() {
   this.generatedQuestions = []
    let randomQuestions = JSON.parse(JSON.stringify(this.tempQuestions))

    for (let i = 0; i < this.tempQuestions.length; i++) {
      if (i == 60) {
        break;
      }

      let random = Math.floor(Math.random() * randomQuestions.length)
      let question = randomQuestions[random]
      this.generatedQuestions.push(question)
      randomQuestions.splice(random, 1)
    }
  }

  questionsFilteration() {
    if (!this.selectedQuestion) {
      this.statusLevels = -1;
    } else if (this.selectedQuestion.name == 'Unused Questions') {
      this.statusLevels = 0;
    } else if (this.selectedQuestion.name == 'Correct Questions') {
      this.statusLevels = 1;
    } else if (this.selectedQuestion.name == 'Incorrect Questions') {
      this.statusLevels = 2;
    } else if (this.selectedQuestion.name == 'Omitted Questions') {
      this.statusLevels = 3;
    } else if (this.selectedQuestion.name == 'Marked Questions') {
      this.statusLevels = 4;
    } else if (this.selectedQuestion.name == 'All') {
      this.statusLevels = 5;
    } else if (this.selectedQuestion.name == 'Custom') {
      this.statusLevels = 6;
    }
    this.difficultyLevels = [];
    if (this.diffOne == true) {
      this.difficultyLevels.push(1);
    }
    if (this.diffTwo == true) {
      this.difficultyLevels.push(2);
    }
    if (this.diffThree == true) {
      this.difficultyLevels.push(3);
    }
    if (this.diffFour == true) {
      this.difficultyLevels.push(4);
    }
    if (this.diffFive == true) {
      this.difficultyLevels.push(5);
    }
    if (this.difficultyLevels.length == 0) {
      this.difficultyLevels = [];
    }
    
    let data = {
      userId: localStorage.getItem('Id'),
      statusLevels: this.statusLevels,
      difficultyLevels: this.difficultyLevels,
      courseIds: this.courses
    }
    this.APIloader = true;
    this.quizgeneratorService.filterQuestions(data).subscribe((res: any) => {
      this.APIloader = false;

      const decompressedData = pako.inflate(res.data.questions.data, { to: 'string' });
      res.data.questions = JSON.parse(decompressedData);

      this.totalQuestion = res.data.totalQuestions;
      this.allQuestions = res.data.questions;

      this.selectedSubjects = []
      this.subjects.forEach((element: any) => {
        element.Questions = 0
      })

      this.selectedSystems = []
      this.systems.forEach((element: any) => {
        element.Questions = 0
      })

      this.selectedTopics = []
      this.topics.forEach((element: any) => {
        element.Questions = 0
      })

      if (this.selectedCourse) {
        this.tempQuestions = this.allQuestions.filter((item: any) => item.QCourse == this.selectedCourse)
      } else {
        this.tempQuestions = JSON.parse(JSON.stringify(this.allQuestions))
      }

      this.selectedQbank = []

      this.qBank.forEach((element: any) => {
        let count = this.tempQuestions.filter((item: any) => item.QBank == element.Title).length
        element.Questions = count
      })

      this.totalQuestion = this.tempQuestions.length

      this.randomizeQuestions();
    })

  }
}
