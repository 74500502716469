import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AdminService } from '../shared/services/admin.service';
import { GlobalService } from '../shared/services/global.service';
import { AutoLogout } from '../models/globals';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})
export class AdmindashboardComponent implements OnInit {
  isopen = false;
  userName: any;
  visibleSidebar2 = false;
  allNotifications: any;
  newNotification: any;
  sidebar: any = [];

  constructor(private adminService: AdminService ,private globalService: GlobalService ,private route:ActivatedRoute,private router:Router) {
    // router.navigate(['/admin/home'])
  }

  async ngOnInit() {
    var userName = localStorage.getItem("UserName");
    // document.getElementById("app-Title").innerHTML = userName;

    const { status, permissions }: any = await this.getPermissions();
    if (status) {
      if (permissions.length > 0) {
        this.sidebar = [
          {
            title: 'Dashboard',
            link: '/admin/home',
            icon: 'fa fa-th-large'
          },
          {
            title: 'Medical Library',
            link: '/admin/medicalLibrary',
            icon: 'pi pi-book'
          },
          {
            title: 'Users Feedback',
            link: '/admin/feedback',
            icon: 'pi pi-users'
          },
          {
            title: 'Medical Library Feedback',
            link: '/admin/library-feedback',
            icon: 'pi pi-comments'
          },
          {
            title: 'Link Questions',
            link: '/admin/linkedquestions',
            icon: 'pi pi-file'
          },
          {
            title: 'Link UWorld Questions',
            link: '/admin/linked-uworld-questions',
            icon: 'pi pi-file'
          },
          {
            title: 'Ready Decks',
            link: '/admin/ready-decks',
            icon: 'fa fa-bolt'
          },
          {
            title: 'Manage Memberships',
            link: '/admin/manage-memberships',
            icon: 'pi pi-credit-card'
          },
          {
            title: 'Manage Instructors',
            link: '/admin/manage-instructors',
            icon: 'pi pi-user'
          },
          {
            title: 'Sync Questions',
            link: '/admin/sync-questions',
            icon: 'pi pi-refresh'
          },
          {
            title: 'Chat',
            link: '/admin/chat',
            icon: 'pi pi-comment'
          }
        ]
        const newSidebar = [];
        for (let i = 0; i < permissions.length; i++) {
          const index = this.sidebar.findIndex((item: any) => item.title === permissions[i])
          if (index !== -1) {
            newSidebar.push(this.sidebar[index]);
          }
        }
        if (newSidebar.length > 0) {
          this.sidebar = newSidebar;
        }
      }
    } else {
      this.sidebar = [
        {
          title: 'Dashboard',
          link: '/admin/home',
          icon: 'fa fa-th-large'
        },
        {
          title: 'Medical Library',
          link: '/admin/medicalLibrary',
          icon: 'pi pi-book'
        },
        {
          title: 'Users Feedback',
          link: '/admin/feedback',
          icon: 'pi pi-users'
        },
        {
          title: 'Medical Library Feedback',
          link: '/admin/library-feedback',
          icon: 'pi pi-comments'
        },
        {
          title: 'Link Questions',
          link: '/admin/linkedquestions',
          icon: 'pi pi-file'
        },
        {
          title: 'Link UWorld Questions',
          link: '/admin/linked-uworld-questions',
          icon: 'pi pi-file'
        },
        {
          title: 'Ready Decks',
          link: '/admin/ready-decks',
          icon: 'fa fa-bolt'
        },
        {
          title: 'Manage Memberships',
          link: '/admin/manage-memberships',
          icon: 'pi pi-credit-card'
        },
        {
          title: 'Manage Instructors',
          link: '/admin/manage-instructors',
          icon: 'pi pi-user'
        },
        {
          title: 'Sync Questions',
          link: '/admin/sync-questions',
          icon: 'pi pi-refresh'
        },
        {
          title: 'Chat',
          link: '/admin/chat',
          icon: 'pi pi-comment'
        }
      ]
    }


    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    if(localStorage.getItem('UserName')){
      this.userName = localStorage.getItem('UserName');
    }
    // @ts-ignore
    document.getElementById('eup-navbar-heading').innerText = 'Dashboard';
    if(!localStorage.getItem("token")){
      this.router.navigate(['/']);
    }
    
    this.getAdminNotifications()
  }

  getPermissions() {
    return new Promise((resolve: any) => {
      const data = {
        instructorId: localStorage.getItem('Id')
      }
      this.adminService.getInstructorPermissions(data).subscribe((res: any) => {
        if (res.data.status) {
          let permissions = [];
          try {
            permissions = JSON.parse(res.data.permissions)
          } catch (e) {
            permissions = res.data.permissions
          }
          resolve({ status: true, permissions: permissions });
        } else {
          resolve({ status: false, permissions: [] });
        }
      })
    })
  }

  logout(): void {
    const arr = ['Id', 'UserName', 'email', 'subscriptions', 'token', 'isInstructor'];
    arr.forEach((element: any) => {
      localStorage.removeItem(element);
    })
    sessionStorage.removeItem('selectedCourse');
    this.router.navigate(['/']);
  }


  hamburgerclicked():void {
    this.isopen = !this.isopen;
      // @ts-ignore
      // $("#sidebar").toggle("slow");
    }

    getAdminNotifications(): any{
      this.adminService.getAdminNotifications().subscribe((res: any) => {
        this.allNotifications = res.res;
    })
  }

    sendNotification(){
    var data = {
      adminId: JSON.parse(localStorage.getItem("Id")),
      userName: localStorage.getItem("UserName"),
      request: this.newNotification
    }
   
    this.adminService.sendAdminNotifications(data).subscribe((res: any) => {
      this.newNotification = '';
      this.getAdminNotifications()
  },
  (        err: any) => {
    AutoLogout(err);
  }
)
  
    }

    deleteAdminNotification(notificationId: any){
      
      this.adminService.deleteSpecificAdminNotification(notificationId).subscribe((res: any) => {
        this.getAdminNotifications()
    },
    (        err: any) => {
      AutoLogout(err);
    }
  )
    
      }
    

}
