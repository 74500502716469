import { CommonModule, DatePipe } from '@angular/common';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { OverviewComponent } from './dashboard/overview/overview.component';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart';
import { AllCoursesComponent } from './dashboard/all-courses/all-courses.component';
import { OverlayComponent } from './shared/widgets/overlay/overlay.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { CourseOverviewComponent } from './dashboard/all-courses/course-overview/course-overview.component';
import {ProgressBarModule} from 'primeng/progressbar';
import { ProfileComponent } from './dashboard/profile/profile.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { FocusboardComponent } from './dashboard/focusboard/focusboard.component';
import { QuizModuleComponent } from './dashboard/quiz-module/quiz-module.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DataViewModule} from 'primeng/dataview';


import {DialogModule, Dialog} from 'primeng/dialog';
import { OverlayfullscreenComponent } from './shared/widgets/overlayfullscreen/overlayfullscreen.component';
import { CalculatorComponent } from './shared/widgets/calculator/calculator.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { UseractivityComponent } from './admindashboard/useractivity/useractivity.component';


import {ToggleButtonModule} from 'primeng/togglebutton';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from "primeng/knob";
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {EditorModule} from 'primeng/editor';
import {ChipsModule} from 'primeng/chips';


import {TreeTableModule} from 'primeng/treetable';
import {InputSwitchModule} from 'primeng/inputswitch';

import { SidebarModule } from 'primeng/sidebar';
import { GenerateQuizComponent } from './dashboard/generate-quiz/generate-quiz.component';
import {CheckboxModule} from 'primeng/checkbox';
import {SkeletonModule} from 'primeng/skeleton';
import { PreviousTestComponent } from './dashboard/previous-test/previous-test.component';
import { PerformanceScreenComponent } from './dashboard/performance-screen/performance-screen.component';
import { TestResultsComponent } from './dashboard/performance-screen/test-results/test-results.component';
import { TestAnalysisComponent } from './dashboard/performance-screen/test-analysis/test-analysis.component';
import { AnalysisChartComponent } from './dashboard/performance-screen/test-analysis/analysis-chart/analysis-chart.component';
import { AnalysisTableComponent } from './dashboard/performance-screen/test-analysis/analysis-table/analysis-table.component';
import { NotesComponent } from './dashboard/performance-screen/notes/notes.component';
import { OverallPerformanceComponent } from './dashboard/overall-performance/overall-performance.component';
import { PerformanceTableComponent } from './dashboard/overall-performance/performance-table/performance-table.component';
import { PerformanceChartComponent } from './dashboard/overall-performance/performance-chart/performance-chart.component';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {PaginatorModule} from 'primeng/paginator';



import { HomeScreenComponent } from './admindashboard/home-screen/home-screen.component';
import { FeedbackComponent } from './admindashboard/feedback/feedback.component';
import { LinkedQuestionsComponent } from './admindashboard/linked-questions/linked-questions.component';
import { PopupComponent } from './login/popup/popup.component';
import { ScoreCalculatorComponent } from './dashboard/score-calculator/score-calculator.component';
import { BellChartComponent } from './dashboard/overall-performance/bell-chart/bell-chart.component';
import { ChatModuleComponent } from './chat-module/chat-module.component';

import { UserchatComponent } from './dashboard/userchat/userchat.component';

import { NotebookModuleComponent } from './notebook-module/notebook-module.component';
import { CourseListComponent } from './notebook-module/course-list/course-list.component';
import { CourseViewComponent } from './notebook-module/course-view/course-view.component';
import {MenuItem} from 'primeng/api';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SanitizerPipe } from './sanitizer.pipe';
import { SearchQuestionsComponent } from './search-questions/search-questions.component';
import { FlashcardModuleComponent } from './flashcard-module/flashcard-module.component';
import { BrowseComponent } from './flashcard-module/browse/browse.component';
import { StudyComponent } from './flashcard-module/study/study.component';
import { ResetModuleComponent } from './reset-module/reset-module.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PeerRankingComponent } from './dashboard/overall-performance/peer-ranking/peer-ranking.component';
import { PageLoaderComponent } from './shared/widgets/page-loader/page-loader.component';
import { OverlayLoaderComponent } from './shared/widgets/overlay-loader/overlay-loader.component';
import { UserNotebookComponent } from './dashboard/user-notebook/user-notebook.component';
import { LinkedUworldQuestionsComponent } from './admindashboard/linked-uworld-questions/linked-uworld-questions.component';
import { ReadyFlashcardsComponent } from './admindashboard/ready-flashcards/ready-flashcards.component';
import { ReadyFlashcardModuleComponent } from './ready-flashcard-module/ready-flashcard-module.component';
import { AiLoaderComponent } from './shared/widgets/ai-loader/ai-loader.component';
import { AITestAnalysisComponent } from './dashboard/ai-components/ai-test-analysis/ai-test-analysis.component';
import { UsmleCriteriaComponent } from './dashboard/ai-components/ai-test-analysis/usmle-criteria/usmle-criteria.component';
import { AiFlashcardModuleComponent } from './ai-flashcard-module/ai-flashcard-module.component';
import { InstructorsComponent } from './admindashboard/instructors/instructors.component';
import { SyncQuestionsComponent } from './admindashboard/sync-questions/sync-questions.component';
import { MedicallibraryComponent } from './dashboard/medicallibrary/medicallibrary.component';
import { MedlibraryComponent } from './admindashboard/medlibrary/medlibrary.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { LibraryFeedbackComponent } from './admindashboard/library-feedback/library-feedback.component';
import { ManageMembershipsComponent } from './admindashboard/manage-memberships/manage-memberships.component';
import { SafeURLPipe } from './shared/pipes/safe-url.pipe';

@NgModule({
  declarations: [

    AppComponent,
    LoginComponent,
    AuthenticationComponent,
    DashboardComponent,
    OverviewComponent,
    AllCoursesComponent,
    OverlayComponent,
    CourseOverviewComponent,
    ProfileComponent,
    FocusboardComponent,
    QuizModuleComponent,
    OverlayfullscreenComponent,
    CalculatorComponent,
    AdmindashboardComponent,
    UseractivityComponent,
    GenerateQuizComponent,
    PreviousTestComponent,
    PerformanceScreenComponent,
    TestResultsComponent,
    TestAnalysisComponent,
    AnalysisChartComponent,
    AnalysisTableComponent,
    NotesComponent,
    OverallPerformanceComponent,
    PerformanceTableComponent,
    PerformanceChartComponent,
    HomeScreenComponent,
    FeedbackComponent,
    LinkedQuestionsComponent,
    PopupComponent,
    ScoreCalculatorComponent,
    BellChartComponent,
    ChatModuleComponent,
    UserchatComponent,
    NotebookModuleComponent,
    CourseListComponent,
    CourseViewComponent,
    SanitizerPipe,
    SearchQuestionsComponent,
    FlashcardModuleComponent,
    BrowseComponent,
    StudyComponent,
    ResetModuleComponent,
    PeerRankingComponent,
    PageLoaderComponent,
    OverlayLoaderComponent,
    UserNotebookComponent,
    LinkedUworldQuestionsComponent,
    ReadyFlashcardsComponent,
    ReadyFlashcardModuleComponent,
    AiLoaderComponent,
    AITestAnalysisComponent,
    UsmleCriteriaComponent,
    AiFlashcardModuleComponent,
    InstructorsComponent,
    SyncQuestionsComponent,
    MedicallibraryComponent,
    MedlibraryComponent,
    SafeHtmlPipe,
    SafeURLPipe,
    LibraryFeedbackComponent,
    ManageMembershipsComponent

  ],
  imports: [
    ConfirmPopupModule,
    RippleModule,
    TooltipModule,
    BadgeModule,
    KnobModule,
    InputSwitchModule,
    DataViewModule,
    TreeTableModule,
    SkeletonModule,
    CheckboxModule,
    SidebarModule,
    InputTextareaModule,
    InputNumberModule,
    RadioButtonModule,
    RatingModule,
    ToolbarModule,
    FileUploadModule,
    InputTextModule,
    DropdownModule,
    ContextMenuModule,
    MultiSelectModule,
    SliderModule,
    CalendarModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    TableModule,
    DialogModule,
    CommonModule,
    ButtonModule,
    ToastModule,
    AccordionModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    FormsModule,
    HttpClientModule,
    ScrollPanelModule,
    CardModule,
    ChartModule,
    TabMenuModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    ReactiveFormsModule,
    EditorModule,
    BreadcrumbModule,
    DragDropModule,
    ChipsModule,
    PinchZoomModule,
    PaginatorModule,
    CKEditorModule
  ],
  providers: [ConfirmationService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
