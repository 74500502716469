import { Component, HostListener, OnInit } from '@angular/core';
import {CourseService} from '../../shared/services/course.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {AutoLogout} from '../../models/globals';
import {QuizServiceService} from '../../shared/services/quiz-service.service';
import {Router} from "@angular/router";
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { demo_variables } from 'src/app/models/demo_global';
import { QuizGeneratorService } from 'src/app/shared/services/quiz-generator.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-performance-screen',
  templateUrl: './performance-screen.component.html',
  styleUrls: ['./performance-screen.component.scss']
})
export class PerformanceScreenComponent implements OnInit {
  // @ts-ignore
  subscriptions: Subscription = [];
  quizQuestions: any = [];
  displayQuestions=false;
  testResult= true;
  displayReview = false;
  displayNotes = false;

  quizId: any;
  quizTitle: any;

  userNotes: any;
  userFeedback: any;

  showQuizData = true;
  loader = false;
  quizStatus: any;

  innerWidth: any;

  userMembership = false
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup-navbar-heading').innerText=''
  }
}

  constructor(private quizgeneratorService: QuizGeneratorService, private adminService: AdminService,private globalService: GlobalService,private router: Router, private CourseService: CourseService , private errorHandler: ErrorHandlerService, private quizService: QuizServiceService, private authService: AuthService) { }

  ngOnInit(): void {
    this.getUserSubscription()
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    if(localStorage.getItem("showsubjectdetails")){
      this.showQuizData = JSON.parse(localStorage.getItem("showsubjectdetails"))
    }
    this.quizId = localStorage?.getItem("QuizId")
    this.quizTitle = localStorage?.getItem("quizTitle")
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Performance';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
    if(localStorage.getItem("testResult")){
      this.testResult = JSON.parse(localStorage.getItem("testResult"));
    }
    if(localStorage.getItem('Id') != '-1'){
    this.getReviewsNotes();
  } else{
    this.userNotes = [
      {notes: 'Graphic Design, as a skill has become a necessary survival tool in the modern world. Over the past decade, the expansion in design-related jobs has been astronomical and Adobe design software is leading this revolution.'}, {notes: 'A note in Pharmacology to remember'}
    ]
  }
    this.quizStatus = localStorage.getItem('quizStatus')
    // @ts-ignore
    // this.subscriptions.push(
    //   this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
    //     if(res.status){
    //     }else{
    //       this.errorHandler.AutoLogout();
    //     }
    //   },
    //     error => {
    //       this.errorHandler.handleError(error);
    //     })
    // );
    this.retrieveQuizQuestions();
  }

  retrieveQuizQuestions(){
    let data = {
      userId: localStorage.getItem('Id'),
      quizId: this.quizId,
      isShared: false
    }
    this.subscriptions.push(
      this.quizgeneratorService.getQuizQuestions(data).subscribe((res: any) => {
        console.log(res);
        this.quizQuestions = res.data.questions;
        console.log(this.quizQuestions);
      })
      )
  }

  getUserSubscription(){
    this.authService.getUserSubscription().subscribe((res: any) => {
      if (res.subscriptions.length > 0) {
        res.subscriptions.forEach(element => {
          if (element.Status == 'active') {
            this.userMembership = true
          }
        });
      }
    })
  }

  showBasicDialog2(): void{
    this.displayReview = !this.displayReview;
  }


  resumeQuiz(num: any): any{
    let viewMode = false;
    if(num == 1){
      viewMode = true
    }else{
      viewMode = false
    }
    if(localStorage.getItem('Id') != '-1'){
    this.loader = true;
    this.subscriptions.push(
    this.quizService.resumePrevQuizzes(this.quizId, viewMode).subscribe((res: any) => {
        var quizScore;
        var quizTitle;
        var quizMode;
        var quiz_time;
        var quizId;
        var isTimed = false;
        var currentIndexId = 0;

        var allQuestions = [];
        var submittedQuestions = [];
        var flaggedQuestions = [];
        var notes = [{note: 'dummy data' , questionId: -1}];

        quizScore = res.data?.previousQuizzes[0]?.quiz_meta?.quizScore;
        quizTitle = res.data?.previousQuizzes[0]?.quiz_meta?.quizTitle;
        quizMode = res.data?.previousQuizzes[0]?.quiz_meta?.quizMode;
        quiz_time = res.data?.previousQuizzes[0]?.quiz_meta?.quizTime;
        quizId = res.data?.previousQuizzes[0]?.quiz_meta?.quizId;
        isTimed = res.data?.previousQuizzes[0]?.quiz_meta?.isTimed;
        currentIndexId = res.data?.previousQuizzes[0]?.quiz_meta?.currentIndex;

        for (let i =0; i<res.data?.questions?.length; i++){
          // Getting Flagged Questions
          if(res.data?.questions[i].marked != -1){
            flaggedQuestions.push({QuestionId: res.data?.questions[i]?.marked, index: i})
          }

          // Getting already submitted Questions
          if(res.data?.questions[i]?.submitData && res.data?.questions[i]?.submitData != null){
            submittedQuestions.push(JSON.parse(res.data?.questions[i]?.submitData));
          }

          // Getting Notes
          if(res.data?.questions[i]?.notes && res.data?.questions[i]?.notes != null){
            notes.push({note: res.data?.questions[i]?.notes , questionId: res.data?.questions[i]?.post_id});
          }

          // getting all Questions that have options

          allQuestions.push({answer_data: res.data?.questions[i]?.answer_data, answer_type: res.data?.questions[i]?.answer_type, correct_msg: res.data?.questions[i]?.correct_msg, id: res.data?.questions[i]?.id, incorrect_msg: res.data?.questions[i]?.incorrect_msg, ld_Correct_Percentage: res.data?.questions[i]?.ld_Correct_Percentage, ld_hits_details: res.data?.questions[i]?.ld_hits_details, options: res.data?.questions[i]?.options, postId: res.data?.questions[i]?.post_id, quiz_id: res.data?.questions[i]?.quiz_id , statistics: res.data?.questions[i]?.statistics , subjects: res.data?.questions[i]?.subjects , systems: res.data?.questions[i]?.systems , topics: res.data?.questions[i]?.topics , question: res.data?.questions[i]?.question, linked: res.data?.questions[i].linked , priority: res.data?.questions[i].priority, UWorldID: res.data?.questions[i]?.UWorldID, questionTime: res.data?.questions[i].submitData ? JSON.parse(res.data?.questions[i].submitData).time : 0, totalLinks: res.data?.questions[i].totalLinks})
        }
          localStorage.setItem("AllQuestions",JSON.stringify(allQuestions));
          localStorage.setItem("quizScore", res.data.previousQuizzes[0].quiz_meta.quizScore);
          localStorage.setItem("SubmittedQuestions", JSON.stringify(submittedQuestions));
          localStorage.setItem("NotesArray", JSON.stringify(notes));
          localStorage.setItem("quizTitle", quizTitle);
          localStorage.setItem("QuizMode",quizMode);
          localStorage.setItem("quiz_time",quiz_time);
          localStorage.setItem("QuizId",quizId);
          localStorage.setItem("isTimed",JSON.stringify(isTimed));
          localStorage.setItem("isResumed", JSON.stringify(1));
          localStorage.setItem("viewMode", JSON.stringify(viewMode));
          localStorage.setItem("currentIndexId", JSON.stringify(currentIndexId));
          localStorage.setItem("flaggedQuestion", JSON.stringify(flaggedQuestions));

          const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
          arr.forEach(i => {
            localStorage.removeItem(i);
          });
        this.router.navigate(['/quiz']);
        this.loader = false;
      },
      (        err: any) => {
        AutoLogout(err);
      }
    )
  );}
  else{
    localStorage.setItem("AllQuestions",JSON.stringify(demo_variables.allQuestions));
          localStorage.setItem("quizScore", '0');
          localStorage.setItem('SubmittedQuestions', JSON.stringify(demo_variables.SubmittedQuestions))
          localStorage.setItem("NotesArray", JSON.stringify([]));
          localStorage.setItem("quizTitle", 'Demo Quiz');
          localStorage.setItem("QuizMode",'tutor');
          localStorage.setItem("QuizId", '-1');
          localStorage.setItem("isTimed",JSON.stringify(false));
          localStorage.setItem("isResumed", JSON.stringify(1));
          localStorage.setItem("viewMode", JSON.stringify(viewMode));
          localStorage.setItem("flaggedQuestion", JSON.stringify([]));

          const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
          arr.forEach(i => {
            localStorage.removeItem(i);
          });
          this.router.navigate(['/quiz']);
  }
  }

  showNotesPopup(): void{
    this.displayNotes = !this.displayNotes;
  }

  changeTestResult(i: any): void{
    if(i === 1){
      this.testResult = true;
    }else{
      this.testResult = false;
    }
    localStorage.setItem("testResult", JSON.stringify(this.testResult));
  }

  // getReviewsNotesOfQuiz
  getReviewsNotes(){
    this.quizId = localStorage?.getItem("QuizId")
    // @ts-ignore
    this.subscriptions.push(
      this.CourseService.getReviewsNotesOfQuiz(this.quizId).subscribe((res: any) => {
        this.userFeedback = res.userFeedbacks
        this.userNotes = res.userNotes
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  copyText(){
    var text = '';
    this.displayQuestions = !this.displayQuestions;
    this.quizQuestions.forEach(function(element,index,array) {
      if(index+1!=array.length){
      text = text+element+','
    }else{
      text = text+element
    }
    });
    console.log(text)
    navigator.clipboard.writeText(text);
  }


}
