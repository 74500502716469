<!-- <div style="height: 100vh" *ngIf="loader">
    <app-overlay ></app-overlay>
</div> -->


<!-- <app-overlayfullscreen *ngIf="loader"></app-overlayfullscreen> -->
<!-- <div>
    <p-dialog header="Data Notification" [(visible)]="!showTestDetails" [modal]="false" [closable]="false" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0">No Data Present for this Quiz. </p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" routerLink="/dashboard/previoustest" label="Go Back" class="p-button-text"></p-button>
            
        </ng-template>
  </p-dialog>
  </div> -->

<div class="mb-4" *ngIf="subscribedCourses && subscribedCourses.length>1">
    <div class="text-center mb-4">
        <h5>See the data of individual course by clicking here.</h5>
    </div>
    
    <div class="course_animated_buttons" *ngIf="subscribedCourses?.length > 1">
        <div class="col-md-3 col-sm-3 col-xs-6" *ngFor="let course of subscribedCourses;" (click)="individualCourseTable(course.courseID)"> <a class="btn btn-sm animated-button thar-three" [ngClass]="course.courseID == courseId ? 'button_active' : ''">{{course.courseName}}</a> </div>
    </div>
</div>

<div class="loader" style="margin-top: 5rem;" *ngIf="loader">
    <app-page-loader></app-page-loader>
</div>

<div class="mb-5" *ngIf="!loader">
<p-treeTable #subjectTable [value]="files1" [columns]="cols" [scrollable]="true" *ngIf="showTestDetails">
    <ng-template pTemplate="caption">
        <div style="text-align: right">        
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="30" placeholder="Search By Subjects" (input)="subjectTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field" style="width: 165px;">
                {{col.header}}
                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index" style="width: 165px;">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <span *ngIf="i == 0" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
                <span *ngIf="i != 0">{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>


<br>
<br>
<p-treeTable #systemtable [value]="files3" [columns]="cols1" [scrollable]="true" *ngIf="showTestDetails">
    <ng-template pTemplate="caption">
        <div style="text-align: right">        
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="30" placeholder="Search By System" (input)="systemtable.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field" style="width: 165px;">
                {{col.header}}
                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index" style="width: 165px;">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <span *ngIf="i == 0" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
                <span *ngIf="i != 0">{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<br>
<br>
<p-treeTable #topictable [value]="files5" [columns]="cols2" [scrollable]="true" *ngIf="showTestDetails">
    <ng-template pTemplate="caption">
        <div style="text-align: right">        
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="30" placeholder="Search By Topic" (input)="topictable.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field" style="width: 165px;">
                {{col.header}}
                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index" style="width: 165px;" >
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0" ></p-treeTableToggler>
                <span *ngIf="i == 0" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
                <span *ngIf="i != 0">{{rowData[col.field]}}</span>
                
            </td>
        </tr>
    </ng-template>
</p-treeTable>
</div>