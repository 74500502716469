
export const libraryContent = 
[
    {   
        articleId:1,
        title:'Welcome to the Medical Library',
        htmlContent:
        `<h3><strong>Overview of the UWorld Medical Library</strong></h3>
<p>The UWorld Medical Library is a comprehensive knowledge platform for students, residents, and practicing physicians.&nbsp; It contains <strong>600+</strong> unique, up-to-date, peer-reviewed articles that integrate with your QBank to deliver an ideal review experience for exam prep and daily practice.</p>
<ul class="article-body-unordered-list">
<li><strong>For medical students focusing on their preclinical curriculum:</strong>&nbsp; Connect pathophysiology with testable clinical manifestations for a more rounded view of topics you're studying.</li>
<li><strong>For medical students on their clinical rotations:</strong>&nbsp; Strengthen your clinical reasoning skills, refine your understanding of differential diagnoses, and find answers to clinical and management questions.</li>
<li><strong>For residents and practicing physicians:</strong>&nbsp; Validate your findings and refresh your knowledge on specific topics.</li>
</ul>
<h3><strong>How to Find Articles (Search)</strong></h3>
<p>The Medical Library makes it easy to find additional context on topics you're studying or to validate your clinical findings during practice.&nbsp; To get started, select "<strong>Search Medical Library</strong>" at the top-left corner of your screen and enter your query.</p>
<p>We suggest searching for specific topics or keyword phrases just as you would in My Notebook.&nbsp; The search bar looks for your query in the title and body text of our 600+ curated articles.&nbsp; If the library has content supporting your inquiry, it will be highlighted in your results, which will be sorted by relevance.&nbsp; Article titles will appear bolded, followed by a short snippet of the article highlighting your query.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p>If you select an article and would like to return to your search results, click "<strong>Back to Previous Page</strong>" at the top-left corner of your screen.&nbsp; You can then explore other query-related articles or enter a new search.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p>&nbsp;</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>How to Use Bookmarks (Save)</strong></span></p>
<p>Bookmarks are useful when you are studying a difficult topic, have a patient with needs that you are less familiar with, or want to save specific articles for future use.&nbsp; To make a new bookmark, select the <strong>star icon</strong> in the article tools bar.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p>You can view your saved resources by clicking "<strong>Bookmarks</strong>" in the navigation menu on the left side of your screen.&nbsp; This tab also opens a search bar where you can look up specific bookmarks as needed.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p>At the top-right corner of your Bookmarks screen, you'll find an <strong>edit icon</strong> that opens a multi-select option to remove bookmarks individually or in bulk.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>How to Use Article Tools (Customize)</strong></span></p>
<p>The Medical Library integrates your QBank tools to help you add clinical context to your flashcards and notes, and to further optimize your review sessions.&nbsp; You can access them by highlighting content or using the article tools bar at the bottom of your screen.</p>
<p>When you select content, a <strong>pop-up menu</strong> that has similar functionality to the menu in your answer explanations will appear.&nbsp; You can highlight text, create a new flashcard, edit an existing flashcard, or transfer the content into My Notebook.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p>Another menu, the <strong>article tools bar</strong>, is available at the bottom of each article.&nbsp; This menu allows you to transfer content into My Notebook, create new flashcards, submit targeted feedback to our physician authors, bookmark the article, and search for specific terms within the article.</p>
<p>&rarr; Click here to see how it works on:&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Desktop</a>)&nbsp; (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>Mobile</a>)</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Your Essential Online Medical Library</strong></span></p>
<p>The Medical Library transforms your QBank into an all-in-one resource for clinical content mastery by expanding on the topics you need to know.&nbsp; Get fast, reliable answers to your clinical questions while studying, preparing for exams, or managing patients.</p>`
    },
    {
        articleId:2,
        title:'Allergic/irritant contact dermatitis',
        htmlContent:
      ` <div id="article-html-content" class="content article-html-content">
<div class="ng-star-inserted">
<div>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Introduction</strong></span></p>
<p>Contact dermatitis is an inflammatory skin condition caused by exposure to an irritant that disrupts the skin barrier (irritant contact dermatitis [ICD]) or to an allergen that causes a type IV (delayed-type) hypersensitivity reaction (allergic contact dermatitis [ACD]).&nbsp; ICD and ACD have overlapping clinical presentations, virtually indistinguishable histologic features, and similar management strategies.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Pathophysiology</strong></span><span class="article-body-headers article-body-subheader" data-node-name="h2">Irritant contact dermatitis (ICD)</span></p>
<p><strong>Chemical</strong> (eg, water, detergents, solvents) or <strong>physical</strong> (eg, metals, wood, fiberglass) irritants can disrupt the epidermal barrier through damage (eg, microtrauma) to keratinocytes, increasing epidermal permeability and water loss.&nbsp; A nonspecific inflammatory response follows at the site of exposure, likely caused by the activation of innate immunity and the release of cytokines from keratinocytes.</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Allergic contact dermatitis (ACD)</span></p>
<p>ACD is a <strong>type IV (delayed-type)</strong> hypersensitivity reaction that occurs in 2 distinct phases (<a> figure</a>):</p>
<ol>
<li>
<p>The <strong>sensitization</strong> phase leads to the creation of hapten-specific T cells and takes 10-14 days.&nbsp; Cutaneous dendritic (Langerhans) cells take up the haptens and express them on MHC-I and MHC-II molecules as hapten-conjugated peptides.&nbsp; These Langerhans cells travel to the draining lymph nodes and interact with hapten-sensitive CD4<sup>+</sup> and CD8<sup>+</sup> T cells, causing activation and clonal expansion.</p>
</li>
<li>
<p>The <strong>elicitation</strong> phase typically occurs within 2-3 days following reexposure to the same antigen (or following sensitization after first exposure to a highly antigenic antigen, such as urushiol/poison ivy).&nbsp; In this phase, the hapten is taken up by skin cells and causes activation of hapten-sensitized T cells in the dermis and epidermis.&nbsp; This results in an inflammatory response and the clinical manifestations of contact dermatitis.</p>
</li>
</ol>
<p>Depending on the etiologic agent, contact dermatitis can be mediated primarily by cytotoxic CD8<sup>+</sup> T cells or CD4<sup>+</sup> Th1 cells (which cause indirect damage by activating macrophages).&nbsp; For example, in urushiol-induced contact dermatitis (caused by poison ivy), CD8<sup>+</sup> T cells are the primary effector cells and directly destroy keratinocytes expressing haptenated proteins.</p>
<p>Common allergens (<a> table</a>) include nickel (found in jewelry and belt buckles), fragrances, preservatives (eg, cosmetics, skin care products), topical medications, dyes, latex, rubber, formaldehyde (eg, artificial nails), and urushiol (eg, poison ivy, poison oak, poison sumac).</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Risk factors</strong></span><span class="article-body-headers article-body-subheader" data-node-name="h2">Irritant contact dermatitis</span></p>
<ul>
<li>
<p>Age:&nbsp; younger patients are more likely to develop ICD due to their heightened inflammatory response.</p>
</li>
<li>
<p>Body site:&nbsp; sites with thinner stratum corneum (eg, face, dorsal hands, finger webs) have increased irritant permeability compared to sites with thicker stratum corneum (eg, palms, soles, back).&nbsp; In addition, body sites that are more exposed (eg, hands, arms, legs) have a higher probability of irritant exposure than sites that are typically covered by clothing (eg, trunk).</p>
</li>
<li>
<p>Atopic dermatitis:&nbsp; impaired skin barrier function in patients with atopic dermatitis increases irritant permeability.</p>
</li>
<li>
<p>Occupations:&nbsp; patients in certain occupations are at increased risk for ICD due to frequent exposure to water and chemicals (eg, cleaners, hairdressers, food handlers), as well as physical irritants (eg, mechanics, carpenters, construction workers).</p>
</li>
<li>
<p>Environment:&nbsp; extremes of temperature and humidity, as well as high air flow (eg, wind, air conditioning), increase skin permeability and promote inflammatory response to irritants.</p>
</li>
</ul>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Allergic contact dermatitis</span></p>
<ul>
<li>
<p>Age:&nbsp; adults are more likely than children to develop ACD because they have had more time for allergen sensitization.</p>
</li>
<li>
<p>Atopic dermatitis:&nbsp; patients with atopic dermatitis may be at increased risk for ACD, possibly related to impaired skin barrier function and immune dysregulation.</p>
</li>
<li>
<p>Occupation:&nbsp; patients in certain occupations (eg, health care, landscaping, cosmetology) are at increased risk for ACD due to frequent exposure to allergens.</p>
</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Clinical presentation</strong></span><span class="article-body-headers article-body-subheader" data-node-name="h2">Irritant contact dermatitis</span></p>
<ul>
<li>
<p><strong>Acute</strong> ICD develops after a single exposure to a <strong>strong irritant</strong> (eg, bleach) and presents with burning or painful skin erythema (<a> image</a>), edema, vesicles, and bullae.</p>
</li>
<li>
<p>Chronic ICD develops after <strong>repeated</strong> exposures to a <strong>mild irritant</strong> (eg, water, detergent) over time (eg, weeks to months) and presents with scaling, lichenification, and fissuring (<a> image</a>), most commonly on the hands.</p>
</li>
</ul>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Allergic contact dermatitis</span></p>
<ul>
<li>
<p>Acute ACD presents with intensely pruritic, erythematous, indurated plaques associated with vesicles (<a> image</a>), bullae, and mild scaling that typically occur 24-72 hours (ranging 4-96 hr) following reexposure to a previously sensitized allergen.&nbsp; Lesions are typically limited to the area of allergen exposure; however, secondary transfer of allergens to other sites (eg, through scratching, touching contaminated clothes) can also occur.</p>
<p><strong>Urushiol</strong>, found in poison ivy, is a common cause of acute ACD.&nbsp; It often presents with linear streaks (<a> image</a>) where the skin has brushed against plant leaves.</p>
</li>
<li>
<p>Chronic ACD develops with repeated exposures to low levels of allergen or when acute contact dermatitis is untreated.&nbsp; Lesions are characterized by pruritus, lichenification (<a> image</a>), and fissuring; the vesicles and bullae of acute dermatitis are typically not seen.</p>
<p><strong>Nickel</strong> is a common allergen that causes chronic ACD; the corrosion of metal alloys by electrolytes in sweat releases soluble metal ions that trigger a hypersensitivity reaction.&nbsp; Nickel-induced ACD typically causes symptoms in characteristic locations, including the medial beltline (from belt buckles), wrists (from watches or bracelets), earlobes (from earrings), and perioral areas (<a> image</a>) (from musical instruments).</p>
</li>
</ul>
<p>For both ICD and ACD, secondary skin changes (eg, excoriations) or bacterial infection (eg, impetigo) can sometimes be superimposed on primary skin changes.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Pathology</strong></span></p>
<p>ICD and ACD can be indistinguishable histologically.</p>
<p>In acute dermatitis, spongiosis (<a> image</a>) is the hallmark histologic finding.&nbsp; Dermal edema caused by leaky vessels seeps into the epidermal intercellular spaces (spongiosis), pulling apart intercellular attachments (desmosomes) and sometimes resulting in intraepidermal vesicles (corresponding clinically to vesicles and bullae).&nbsp; The epidermis has normal thickness, and an inflammatory infiltrate (eg, lymphocytes, eosinophils, histiocytes) is seen in the dermis.</p>
<p>With chronic antigen or irritant exposure, spongiosis and inflammatory infiltrate become minimal, and acanthosis (epidermal thickening), hyperkeratosis (stratum corneum thickening), and hypergranulosis (stratum granulosum thickening) become the more prominent features (<a> image</a>).</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Diagnosis</strong></span></p>
<p>ICD and ACD are mostly diagnosed clinically, based on patient history and physical examination. Important diagnostic aspects include:</p>
<ul>
<li>
<p>Distribution of the rash, reflecting areas of contact with allergens or irritants.&nbsp; A rash in noncontact areas should raise suspicion for an alternate diagnosis.</p>
</li>
<li>
<p>Resolution of symptoms after withdrawal of the offending agents and recurrence of symptoms with reexposure favor the diagnosis of ICD or ACD over other types of dermatitis.</p>
</li>
<li>
<p>The temporal relationship between irritant/allergen exposure and rash onset may help to distinguish between ICD and ACD (eg, 2- to 3-day delay for ACD, immediate eruption following strong irritant exposure for ICD).</p>
</li>
</ul>
<p>Select cases of ICD and ACD require additional evaluation with patch testing and skin biopsy.</p>
<p>Indications for <strong>patch testing</strong> include the following:</p>
<ul>
<li>
<p>Identify the allergen (thereby confirming the diagnosis) when suspected ACD is persistent.</p>
</li>
<li>
<p>Exclude concomitant ACD in patients with chronic ICD (ACD and ICD may coexist).</p>
</li>
</ul>
<p>In patch testing, patches of allergenic compounds are applied to the skin (eg, back) for a specified time to allow allergen penetration.&nbsp; To identify ACD, the skin is examined for erythema and vesicles (<a> image</a>) 48 hours after patch removal because ACD is a delayed-type hypersensitivity reaction.&nbsp; When these changes are present, the associated compound is identified as the allergen and should be avoided.</p>
<p>Skin biopsy with histologic examination cannot reliably distinguish between eczematous dermatitis (eg, ICD, ACD, atopic dermatitis, seborrheic dermatitis).&nbsp; However, it is indicated to exclude other diagnoses when suspected ICD and ACD are recalcitrant to standard therapy and irritant and allergen avoidance.&nbsp; Of particular concern is cutaneous T-cell lymphoma (mycosis fungoides), which is typically misdiagnosed as atopic dermatitis and contact dermatitis initially.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Differential diagnosis</strong></span></p>
<ul>
<li>
<p><strong>Atopic dermatitis</strong>:&nbsp; a chronic inflammatory skin condition caused by immune dysregulation and genetically mediated skin barrier dysfunction (eg, filaggrin mutation).&nbsp; It presents with an eczematous rash that can be indistinguishable from ACD and ICD. However, unlike ACD and ICD, atopic dermatitis has a characteristic bilateral flexural distribution in adults (<a> image</a>) (eg, antecubital and popliteal fossae) who often have had no exposure to allergens or irritants.&nbsp; In addition, patients typically have a family history of atopic dermatitis or personal history of atopy (eg, allergic rhinitis, asthma).</p>
</li>
<li>
<p><strong>Psoriasis</strong>:&nbsp; a chronic inflammatory skin condition that presents with mildly pruritic, erythematous plaques covered with thick, silvery scales that are not seen in patients with ACD or ICD.&nbsp; Plaque psoriasis usually affects the bilateral extensor surfaces (<a> image</a>) (eg, elbows, knees), where contact with allergens or irritants is unlikely to occur.&nbsp; Nail changes (<a> image</a>) (eg, pitting, onycholysis, oil spots) and psoriatic arthritis (<a> image</a>) (inflammatory arthritis affecting the peripheral and axial skeletons) can coexist with psoriasis but not with ACD or ICD; when present, these changes serve as additional diagnostic clues.</p>
</li>
<li>
<p><strong>Seborrheic dermatitis</strong>:&nbsp; causes erythematous patches or plaques with greasy scales (<a> image</a>) that typically affect oily areas such as the scalp (<a> image</a>) (dandruff) and central face (eg, eyebrows, nose, nasolabial folds).&nbsp; Vesicles, bullae, and skin edema seen in ACD and ICD are not present.</p>
</li>
<li>
<p><strong>Tinea corporis</strong>:&nbsp; presents with scaly, erythematous, annular patches or plaques (<a> image</a>), a morphology inconsistent with ACD and ICD.&nbsp; Diagnosis can be confirmed by a potassium hydroxide skin test demonstrating fungal hyphae (<a> image</a>).</p>
</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Management</strong></span></p>
<p>The overall management principles are similar for ICD and ACD; they are presented separately for clarity.</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Irritant contact dermatitis</span></p>
<ul>
<li>
<p><strong>Avoidance</strong>:&nbsp; The culprit irritant should be avoided.&nbsp; If this is not possible, patients should wear gloves and apply barrier creams to prevent cutaneous penetration by irritants.</p>
</li>
<li>
<p><strong>Emollients and moisturizers</strong>:&nbsp; Both should be applied multiple times a day to restore the skin barrier and transdermal water loss.</p>
</li>
<li>
<p><strong>Topical corticosteroid therapy</strong>:&nbsp; To reduce inflammation, topical corticosteroid therapy is recommended.</p>
<ul>
<li>
<p>High-potency corticosteroid therapy is recommended for areas with thicker skin (eg, hands, feet) or when the dermatitis is severe.</p>
</li>
<li>
<p>Low- or medium-potency corticosteroid therapy is recommended for areas with thinner skin (eg, face, eyelids, flexural areas) or when the dermatitis is mild.</p>
</li>
</ul>
</li>
</ul>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Allergic contact dermatitis</span></p>
<ul>
<li>
<p><strong>Avoidance:</strong>&nbsp; The culprit allergen should be identified and avoided. Barrier protectants (eg, gloves, long sleeve shirts/pants) are recommended to mitigate allergen contact.</p>
</li>
<li>
<p><strong>Reduction of post-exposure spread:</strong>&nbsp; After contact with an allergen (eg, poison ivy), contaminated clothing should be removed, and the area of contact should be washed with soap and water to prevent secondary spread of the allergen (eg, through scratching).</p>
</li>
<li>
<p><strong>Corticosteroid therapy:</strong>&nbsp; To reduce inflammation and itching, corticosteroid therapy is recommended.</p>
<ul>
<li>
<p>Topical corticosteroid therapy is used when the rash is limited in distribution (&lt;20% of total body surface area).&nbsp; It is applied until the rash resolves but not longer than 2-4 weeks (to prevent skin atrophy and hypopigmentation).</p>
<ul>
<li>
<p>High-potency (eg, clobetasol propionate 0.05%) corticosteroid therapy is recommended for areas with thicker skin (eg, hands, feet) or when the dermatitis is severe.</p>
</li>
<li>
<p>Low-potency (eg, hydrocortisone 0.5% cream) or medium-potency (eg, triamcinolone 0.1% cream) corticosteroid therapy is recommended for areas with thinner skin (eg, face, eyelids, flexural areas) or when the dermatitis is mild.</p>
</li>
</ul>
</li>
<li>
<p>Systemic (oral) corticosteroid therapy is indicated for acute, severe, and widespread dermatitis (&gt;20% of total body surface area) that makes application of topical corticosteroid therapy impractical.&nbsp; It is also used when severe dermatitis involves areas prone to skin atrophy (eg, face, genitalia), where topical high-potency corticosteroid therapy is less preferable.&nbsp; Systemic steroid therapy is usually given for 2-3 weeks to prevent rebound dermatitis with early therapy discontinuation.</p>
</li>
</ul>
</li>
<li>
<p><strong>Topical tacrolimus</strong>:&nbsp; Tacrolimus is a calcineurin inhibitor (<a> figure</a>) that is typically reserved for ACD involving the face and intertriginous areas when extended therapy (eg, &gt;2 weeks) is required.&nbsp; Unlike topical corticosteroid therapy, topical tacrolimus does not cause skin atrophy or hypopigmentation, even with extended use, and its onset of action is shorter; however, it is more expensive.</p>
</li>
<li>
<p><strong>Emollients</strong>:&nbsp; Skin barrier compromise is characteristic of chronic ACD; emollients to soften lichenified plaques and restore the skin barrier are often used in conjunction with topical corticosteroid therapy for chronic ACD.</p>
</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Complications</strong></span></p>
<p>Secondary bacterial infections (eg, <em><em>Staphylococcus aureus)</em></em> can complicate ACD and ICD due to skin barrier disruption and microorganism inoculation through scratching.&nbsp; Infection should be suspected when yellow crusts (<a> image</a>) (indicative of impetigo) are present on the rash or if the rash is resistant to standard therapy.&nbsp; Treatment includes topical or systemic antibiotics.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Prognosis</strong></span></p>
<p>The prognosis of contact dermatitis is generally favorable once the allergen or irritant is identified and avoided. Symptoms can recur upon reexposure.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Summary</strong></span></p>
<p>Irritant contact dermatitis (ICD) and allergic contact dermatitis (ACD) are the 2 forms of contact dermatitis.&nbsp; In patients with ICD, irritants (eg, chemical or physical irritant) disrupt the skin barrier, causing nonspecific inflammation.&nbsp; In patients with ACD, allergens trigger a type IV (delayed-type) hypersensitivity reaction.&nbsp; Acutely, both manifest with redness, edema, itching, burning, and blistering; chronically, both present with lichenified plaques.&nbsp; Diagnosis is largely clinical.&nbsp; Treatment predominantly involves corticosteroid therapy and avoiding the irritant or allergen.</p>
</div>
</div>
</div>
<div class="content-related-articles-separator ng-star-inserted">&nbsp;</div>
<div class="related-articles ng-star-inserted">
<div class="related-articles-title">RELATED ARTICLES</div>
</div>
<p>&nbsp;</p>
<div class="related-articles ng-star-inserted">
<div class="related-articles-item d-flex align-items-center ng-star-inserted">
<div>Atopic dermatitis</div>
</div>
</div>`
    },
    {
        articleId:3,
        title:'Anaphylaxis',
        htmlContent:
        `<div id="article-html-content" class="content article-html-content">
<div class="ng-star-inserted">
<div>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Introduction</strong></span></p>
<p>Anaphylaxis is a potentially life-threatening allergic reaction characterized by rapid onset and systemic involvement.&nbsp; The reaction involves acute degranulation of mast cells triggered by immunologic (ie, IgE-mediated, such as food allergy) or nonimmunologic (eg, non-IgE-mediated, such as radiocontrast allergy) mechanisms.&nbsp; Because of its variable presentation, anaphylaxis can sometimes be difficult to recognize.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Pathogenesis</strong></span></p>
<p>Anaphylaxis involves massive degranulation of mast cells, basophils, and eosinophils in various organs (especially skin, airway, and gastrointestinal tract), releasing preformed mediators (eg, histamine, serotonin, leukotrienes).&nbsp; The mast cell degranulation can be triggered by immunologic and nonimmunologic mechanisms (<a><span class="article-icon article-icon-table" data-element-text="table#: ">&nbsp;</span>table</a>).</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Immunologic (IgE-mediated)</span></p>
<p>Immunologic anaphylaxis involves classic Type 1 (IgE-mediated) hypersensitivity (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>figure</a>).&nbsp; It involves 2 phases:</p>
<ul class="article-body-unordered-list">
<li>Initial <strong>sensitization</strong> (asymptomatic, typically in early life):&nbsp; Exposure to allergen causes immune skewing toward an allergic (TH<sub>2</sub>) phenotype, driving production of specific IgE.&nbsp; The IgEs dock onto mast cell surface receptors (Fc&epsilon;RI), where they remain in a primed but inactive (uncross-linked) configuration until reexposed to the original allergen.</li>
<li><strong>Re-exposure</strong> (symptomatic):&nbsp; Upon contact with the original allergen, the primed mast cell IgEs cross-link, resulting in degranulation and release of vasoactive mediators (eg, serotonin, histamine, leukotriene).&nbsp; Their effects include increased vascular permeability (eg, urticaria, angioedema), acute vasodilation (eg, hypotension), and others (eg, bronchospasm, intestinal hypersecretion).</li>
</ul>
<p>Each episode of immunologic anaphylaxis tends to be worse than the previous one, likely due to additional IgE production and mast cell priming.</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Nonimmunologic (non-IgE-mediated)</span></p>
<p>Nonimmunologic anaphylaxis involves mast cell degranulation through mechanisms other than IgE cross-linking.&nbsp; Therefore, it does not require prior sensitization (ie, can occur on first exposure).&nbsp; Nonimmunologic anaphylaxis can be grouped into 3 etiologies:</p>
<ul class="article-body-unordered-list">
<li><strong>Ligand-induced:</strong>&nbsp; Mast cells can be degranulated by several compounds such as radioiodine contrast, vancomycin, opioids, and neuromuscular blockers (eg, succinylcholine).&nbsp; A unique receptor, mas-related G-protein coupled receptor X2 (MRGPRX2), appears to mediate the reaction.</li>
<li><strong>Physical:</strong>&nbsp; Mast cells can be directly degranulated by vigorous activity (eg, exercise) and cold (associated with cold urticaria).&nbsp; The pathogenesis is very poorly understood but may involve a lower mast cell stability threshold.&nbsp; IgE-related mechanisms may play a small role.</li>
<li><strong>Idiopathic:</strong>&nbsp; Mast cells can be degranulated by unknown triggers, even after extensive evaluation.&nbsp; A subclinical wheat (gliadin) allergy potentiated by an external factor (eg, nonsteroidal anti-inflammatory drugs [NSAIDs], exercise, cold) may be responsible.</li>
</ul>
<p><strong>"Anaphylactoid reactions"</strong> is a retired term for these forms of nonimmunologic anaphylaxis.&nbsp; Despite the more benign-sounding name, the reactions can be as severe as IgE-mediated anaphylaxis (eg, may require treatment with epinephrine).</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Potentiators</span></p>
<p>Several variables can potentiate anaphylaxis.</p>
<ul class="article-body-unordered-list">
<li><strong>Medications:</strong>&nbsp; Endogenous counterresponses (eg, epinephrine, angiotensin-II, vasopressin) can help limit the anaphylactic cascade, possibly explaining why some patients improve spontaneously.&nbsp; Medications that inhibit sympathetic activity (eg, &beta;-blockers), ACE inhibitors, or angiotensin receptor blockers can increase the risk of severe anaphylaxis.&nbsp; NSAIDs (especially aspirin) can increase leukotriene production (<a><span class="article-icon article-icon-figure" data-element-text="figure#: ">&nbsp;</span>figure</a>), contributing to mast cell destabilization.</li>
<li><strong>Comorbidities:</strong>&nbsp; Both extremes of age (children, elderly), atopic disorders (especially asthma), and other preexisting cardiopulmonary conditions (eg, coronary artery disease) increase the risk of severe or fatal anaphylaxis.</li>
<li><strong>Mast cell disorders:</strong>&nbsp; Rarely, patients may have underlying systemic mastocytosis (increased mast cell number) or mast cell activation syndrome (increased mast cell activity), leading to more severe (or even spontaneous) anaphylaxis.&nbsp; Other relevant factors include recent alcohol intake and menses, which can increase histamine release.</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Clinical presentation</strong></span></p>
<p>Anaphylaxis is a systemic reaction that can affect several organ systems (<a><span class="article-icon article-icon-table" data-element-text="table#: ">&nbsp;</span>table</a>):</p>
<ul class="article-body-unordered-list">
<li><strong>Skin:</strong>&nbsp; Most mast cells are located in the dermis.&nbsp; Therefore, urticaria (pruritic wheals in the dermal layer) (<a><span class="article-icon article-icon-image" data-element-text="image#: ">&nbsp;</span>image</a>) and angioedema (nonpruritic swelling in the subcutaneous tissues) (<a><span class="article-icon article-icon-image" data-element-text="image#: ">&nbsp;</span>image</a>) are the most common presentations of anaphylaxis.</li>
<li><strong>Upper airway:</strong>&nbsp; Angioedema of the larynx, pharynx, or tongue can obstruct the upper airway (eg, stridor, respiratory distress).</li>
<li><strong>Lungs:</strong>&nbsp; Airway mast cell degranulation leads to acute bronchospasm (eg, wheezing, hypoxemia).</li>
<li><strong>Cardiovascular:</strong>&nbsp; Systemic vasodilation leads to hypotension and compensatory tachycardia.&nbsp; Endothelial permeability leads to third-spacing of intravascular fluid volume.&nbsp; These processes can progress to anaphylactic (distributive) shock.</li>
<li><strong>Gastrointestinal:</strong>&nbsp; Mucosal mast cell degranulation can lead to bowel edema (eg, cramping, abdominal pain, diarrhea, vomiting).</li>
</ul>
<p>Classic uniphasic anaphylaxis (&gt;90% of cases) typically peaks within ~4 hours and resolves within 24 hours.&nbsp; However, there are several atypical presentations: biphasic anaphylaxis (~5% of cases) has an initial peak at ~4 hours and a second wave between 12-72 hours after apparent recovery; protracted anaphylaxis can persist for several days and may be related to a retained allergen (eg, continued gut absorption, buried wasp stinger); delayed anaphylaxis (eg, red meat allergy) can involve an unusually long latency period (eg, hours instead of minutes) between exposure and symptoms.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Diagnosis</strong></span></p>
<p>Diagnosis of anaphylaxis is based on clinical criteria (<a><span class="article-icon article-icon-table" data-element-text="table#: ">&nbsp;</span>table</a>).&nbsp; Any one of the following scenarios makes the diagnosis:</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Any patient</span></p>
<ul class="article-body-unordered-list">
<li>Rapid onset of an illness with involvement of skin or mucosae (eg, urticaria, angioedema) AND</li>
<li>Respiratory or cardiovascular manifestations (eg, dyspnea, wheezing, hypotension, syncope).</li>
</ul>
<p>These criteria are intended to capture patients with possible anaphylaxis based on high-risk clinical features alone (eg, regardless of known or unknown allergen exposure).</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Patients with exposure to likely allergen</span></p>
<p>Rapid involvement of <strong>&ge;2 organ systems</strong></p>
<ul class="article-body-unordered-list">
<li>Skin or mucosae (eg, urticaria, angioedema)</li>
<li>Respiratory (eg, dyspnea, wheezing, stridor)</li>
<li>Cardiovascular (eg, hypotension, syncope)</li>
<li>Gastrointestinal (eg, cramping, diarrhea, vomiting)</li>
</ul>
<p>For example, urticaria and gastrointestinal distress constitutes anaphylaxis (eg, requires epinephrine), even in the absence of wheezing or hypotension.</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Patient with exposure to known allergen</span></p>
<ul class="article-body-unordered-list">
<li>Rapid onset of hypotension (or other organ system involvement)</li>
</ul>
<p>Anaphylaxis can be easy to miss (<a><span class="article-icon article-icon-table" data-element-text="table#: ">&nbsp;</span>table</a>).&nbsp; Particularly tricky situations include atypical presentations (eg, abdominal symptoms, absence of urticaria), first episode (eg, symptoms mistakenly attributed to food poisoning), and preexisting conditions with overlapping symptoms (eg, asthma or chronic obstructive pulmonary disease exacerbation).</p>
<p>An elevated serum <strong>tryptase</strong> (mast cell mediator) level, especially if obtained within 2 hours of symptom onset, has good specificity but limited sensitivity for anaphylaxis.&nbsp; Therefore, a high tryptase level strongly supports analysis, but a normal level cannot be used to rule it out.&nbsp; In outpatients following up after potential anaphylaxis, skin testing and/or serum IgE panel can also corroborate the diagnosis and identify specific trigger allergens.</p>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Differential diagnosis</strong></span></p>
<p>A potential mimic of anaphylaxis is <strong>pollen-food allergy syndrome</strong> (oral allergy syndrome), thought to be the most common type of food allergy.&nbsp; This <strong>localized</strong> oropharyngeal allergy to vegetables, fruits (eg, peaches, pears), and nuts can develop due to minor cross-reactivity between food antigens and structurally related pollens.&nbsp; Most patients have a history of seasonal allergic rhinitis (hay fever).&nbsp; Symptoms, which may include <strong>throat itching</strong> or tightness, are typically mild; they occur immediately after ingestion of certain uncooked fruits and vegetables and usually resolve once these foods are swallowed.</p>
<p>Other conditions included in the differential diagnosis are:</p>
<ul class="article-body-unordered-list">
<li><strong>Aspirin- or NSAID-exacerbated respiratory disease (AERD):</strong>&nbsp; AERD involves the classic (Samter) triad of asthma, nasal polyps, and NSAID hypersensitivity (eg, flushing, headache, rhinorrhea).&nbsp; Urticaria or angioedema are rare because the symptoms are mediated by leukotrienes instead of histamine or bradykinin.</li>
<li><strong>Scombroid poisoning:</strong>&nbsp; Improperly stored seafood can lead to a buildup of histamine (produced by bacteria).&nbsp; Ingestion leads to acute histaminergic symptoms such as flushing, urticaria, abdominal cramping, nausea, and vomiting.&nbsp; It may be clinically impossible to distinguish from anaphylaxis in the immediate setting, and the diagnosis is made retrospectively (eg, skin test).</li>
<li><strong>Carcinoid syndrome:</strong>&nbsp; Carcinoid syndrome is associated with foregut neuroendocrine tumors that secrete tryptophan and vasoactive mediators (eg, primarily serotonin).&nbsp; Symptoms include paroxysmal flushing, wheezing, and diarrhea.&nbsp; An allergic exposure is absent, and urticaria or angioedema are typically not seen.</li>
<li><strong>Others:</strong>&nbsp; Nonallergic angioedema (eg, ACE inhibitor), food poisoning (eg, staphylococcal preformed toxin), myocardial infarction, sepsis, and paroxysmal vocal cord dysfunction can have acute and dramatic presentations resembling anaphylaxis.</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Management</strong></span><span class="article-body-headers article-body-subheader" data-node-name="h2">Treatment</span></p>
<p>Management of anaphylaxis involves:</p>
<ul class="article-body-unordered-list">
<li>Airway protection (eg, intubation) if there is concern for impending obstruction from angioedema or laryngeal edema</li>
<li><strong>Epinephrine</strong>:
<ul class="article-body-unordered-list">
<li>Adults:&nbsp; Intramuscular (IM) dose of 0.3-0.5 mg in the anterolateral thigh.&nbsp; Repeat up to 3 times.</li>
<li>Children:&nbsp; IM dose of 0.01 mg/kg in the anterolateral thigh.&nbsp; Repeat up to 3 times.</li>
<li>Emergency epinephrine is usually available in prediluted vials at 1 mg/mL (previously called "1:1000"); preloaded epinephrine autoinjectors are also available.</li>
</ul>
</li>
<li>Adjunctive measures:
<ul class="article-body-unordered-list">
<li>Intravenous fluid boluses and vasopressors if necessary for refractory hypotension</li>
<li>Nebulized beta agonist (eg, albuterol) for bronchospasm</li>
<li>Antihistamines (H<sub>1</sub> [eg, diphenhydramine] and H<sub>2</sub> [eg, famotidine] blockers)</li>
<li>Systemic glucocorticoids (eg, IV methylprednisolone)</li>
<li>Glucagon (for patients taking beta blockers who are refractory to epinephrine)</li>
</ul>
</li>
</ul>
<p>Delayed epinephrine is the leading cause of preventable fatality from anaphylaxis (eg, mortality increases by ~2% per minute until first dose given).&nbsp; There is no contraindication to epinephrine.&nbsp; Rare adverse effects can include tachyarrhythmias or cardiac arrhythmias.</p>
<p>Most patients require a short hospital admission for close observation until symptoms completely resolve (eg, 24 hours).&nbsp; Biphasic anaphylaxis (rare) is a concern for those who experienced significant exposure (eg, multiple wasp stings), encountered delays in receiving epinephrine, or required repeated doses of epinephrine.&nbsp; Extended observation may be necessary in these cases.&nbsp; Conversely, patients with mild anaphylaxis in a controlled setting (eg, improvement after early treatment with epinephrine) can be discharged home after education on use of epinephrine autoinjector.</p>
<p><span class="article-body-headers article-body-subheader" data-node-name="h2">Secondary prevention</span></p>
<p>Patients with anaphylaxis have a ~15% annual risk of recurrence.&nbsp; Secondary prevention focuses on allergen avoidance, self-administered epinephrine (ie, autoinjector), and possible immunotherapy for desensitization.</p>
<ul class="article-body-unordered-list">
<li><strong>Allergen avoidance:</strong>&nbsp; Drug anaphylaxis can be avoided through careful medication review, although errors still occur.&nbsp; Food and venom anaphylaxis are more challenging due to the ever-present risk of contamination and insect stings.</li>
<li><strong>Self-administered epinephrine:</strong>&nbsp; Patients are taught how to use an epinephrine auto-injector&nbsp;(<a><span class="article-icon article-icon-image" data-element-text="image#: ">&nbsp;</span>image</a>).&nbsp; Over 50% of patients make critical errors when using the device (eg, wrong end); therefore, routine practice is recommended (eg, blank training pen).&nbsp; An anaphylaxis wallet card, which can be provided to bystanders rendering aid, is also recommended.</li>
<li><strong>Immunotherapy:</strong>&nbsp; For many allergies (eg, peanut, venom), desensitization can be achieved through small, regular doses of the offending antigen via subcutaneous (eg, "allergy shots"), oral, or sublingual routes.&nbsp; The regimen is typically continued for long periods of time (eg, years) to induce immune tolerance.&nbsp; Unfortunately, resensitization is common if the therapy is interrupted (or if patients continue avoiding the allergen after desensitization).</li>
</ul>
<p><span class="article-body-headers article-body-header" data-node-name="h1"><strong>Summary</strong></span></p>
<p>Anaphylaxis (<a><span class="article-icon article-icon-table" data-element-text="table#: ">&nbsp;</span>table</a>) is a potentially fatal system allergic reaction with rapid onset.&nbsp; It is driven by sudden mast cell degranulation, which can be triggered by immunologic (IgE-mediated: eg, food, venom) or nonimmunologic mechanisms (non-IgE-mediated: eg, radiocontrast).&nbsp; Multiple organ systems are affected, including skin (urticaria, angioedema), respiratory (bronchospasm), cardiovascular (vasodilation, hypotension), and gastrointestinal (bowel edema).&nbsp; Recognition can be challenging, especially in patients with atypical presentations (eg, GI-predominant) or overlapping comorbidities (eg, asthma).&nbsp; The first line of treatment is intramuscular epinephrine; adjunctive measures include antihistamines and glucocorticoids.&nbsp; Due to the risk of recurrence, secondary prevention strategies focus on allergen avoidance, self-administration of epinephrine (ie, autoinjectors), and potential immunotherapy for desensitization.</p>
</div>
</div>
</div>
<div class="content-related-articles-separator ng-star-inserted">&nbsp;</div>
<div class="related-articles ng-star-inserted">
<div class="related-articles-title">RELATED ARTICLES</div>
</div>
<p>&nbsp;</p>
<div class="related-articles ng-star-inserted">
<div class="related-articles-item d-flex align-items-center ng-star-inserted">
<div>Angioedema and urticaria</div>
</div>
</div>`
    }
]


export const bookmarked = [
    {
        articleId : 1 ,
        title:'Welcome to the Medical Library',
        addedOn : new Date()
    },
    {
        articleId : 3 ,
        title:'Anaphylaxis',
        addedOn : new Date()
    }
]


export function  getSafeRanges(dangerous: any): any {
    const a = dangerous.commonAncestorContainer;
    // Starts -- Work inward from the start, selecting the largest safe range
    const s = new Array(0), rs = new Array(0);
    if (dangerous.startContainer !== a) {
      for (let i = dangerous.startContainer; i !== a; i = i.parentNode) {
        s.push(i)
        ;
      }
    }
    if (0 < s.length) { for (let i = 0; i < s.length; i++) {
      const xs = document.createRange();
      if (i) {
        xs.setStartAfter(s[i - 1]);
        xs.setEndAfter(s[i].lastChild);
      }
      else {
        xs.setStart(s[i], dangerous.startOffset);
        xs.setEndAfter(
          (s[i].nodeType === Node.TEXT_NODE)
            ? s[i] : s[i].lastChild
        );
      }
      rs.push(xs);
    }
    }

    // Ends -- basically the same code reversed
    const e = new Array(0);
    const re = new Array(0);
    if (dangerous.endContainer !== a) {
      for (let i = dangerous.endContainer; i !== a; i = i.parentNode) {
        e.push(i)
        ;
    }
      }
    if (0 < e.length) { for (let i = 0; i < e.length; i++) {
      const xe = document.createRange();
      if (i) {
        xe.setStartBefore(e[i].firstChild);
        xe.setEndBefore(e[i - 1]);
      }
      else {
        xe.setStartBefore(
          (e[i].nodeType === Node.TEXT_NODE)
            ? e[i] : e[i].firstChild
        );
        xe.setEnd(e[i], dangerous.endOffset);
      }
      re.unshift(xe);
    }
    }
    let xm;
    // Middle -- the uncaptured middle
    if ((0 < s.length) && (0 < e.length)) {
      xm = document.createRange();
      xm.setStartAfter(s[s.length - 1]);
      xm.setEndBefore(e[e.length - 1]);
    }
    else {
      return [dangerous];
    }

    // Concat
    rs.push(xm);
    // Send to Consoole
    return rs.concat(re);
  }