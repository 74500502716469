<div class="sync_questions">
    <div class="header">
        <h1>Sync Questions</h1>
        <div class="page-loader" *ngIf="loader">
            <div class="loader"></div>
        </div>
        <div class="buttons" *ngIf="!loader">
            <button (click)="syncCategories()">Sync Categories</button>
        </div>
    </div>
    <div class="content">
        <h2>Following are the operations you can performed to sync questions:</h2>
        <div class="buttons">
            <div class="item" style="background-color: #389cf2;" (click)="openModal('add')">
                <i class="fa fa-plus"></i>
                <span>Add</span>
            </div>
            <div class="item" style="background-color: #61ce87;" (click)="openModal('edit')">
                <i class="fa fa-pencil"></i>
                <span>Edit</span>
            </div>
            <div class="item" style="background-color: #e63434;" (click)="openModal('delete')">
                <i class="fa fa-trash"></i>
                <span>Delete</span>
            </div>
        </div>
        <div class="table_list">
            <h4>Questions List</h4>
            <div class="buttons">
                <button *ngIf="selectedQuestions.length > 0" style="background-color: #389cf2;" (click)="openConfirmationModal('add')">Add selected</button>
                <button *ngIf="selectedQuestions.length > 0" style="background-color: #61ce87;" (click)="openConfirmationModal('edit')">Edit selected</button>
                <button *ngIf="selectedQuestions.length > 0" style="background-color: #e63434;" (click)="openConfirmationModal('delete')">Delete selected</button>
            </div>
        </div>
        <div class="table">
            <p-table #dt1 [value]="questionsList" [globalFilterFields]="['questionId', 'postId']" responsiveLayout="scroll" [paginator]='true' [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="100">
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'equals')" placeholder="Search by Question ID" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 150px;"></th>
                        <th>Title</th>
                        <th>System Question ID</th>
                        <th>UWorld Question ID</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-question let-i="rowIndex">
                    <tr>
                        <td>
                            <p-checkbox 
                            [(ngModel)]="question.status" 
                            [binary]="true" 
                            inputId="binary"
                            (onChange)="selectQuestions()"></p-checkbox>
                        </td>
                        <td>{{question.title}}</td>
                        <td>{{question.postId}}</td>
                        <td>{{question.uWorldId}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog [header]="(modalType === 'add' ? 'Add' : modalType === 'edit' ? 'Edit' : 'Delete') + ' Questions'" [(visible)]="showModal" [modal]="true" [closable]="true" [style]="{width: '400px'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="questions">
        <h4>Enter postId of questions you want to {{ modalType }}:</h4>
        <p-chips [(ngModel)]="questionIds"></p-chips>
        <div class="page-loader" *ngIf="loader">
            <div class="loader"></div>
        </div>
        <div class="buttons" *ngIf="!loader">
            <button class="cancel" (click)="showModal = false;">Cancel</button>
            <button class="apply" (click)="modalType === 'add' ? addSyncQuestion(false) : modalType === 'edit' ? editSyncQuestion(false) : deleteSyncQuestion(false)" [disabled]="questionIds.length <= 0">Apply</button>
        </div>
    </div>
</p-dialog>

<p-toast></p-toast>

<p-dialog header="Confirmation" [(visible)]="confirmationModal" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p class="p-m-0">Are you sure you want to {{ modalType }} these questions?</p>
    <ng-template pTemplate="footer">
        <div class="page-loader" *ngIf="loader">
            <div class="loader"></div>
        </div>
        <p-button icon="pi pi-check" *ngIf="!loader" (click)="modalType === 'add' ? addSyncQuestion(true) : modalType === 'edit' ? editSyncQuestion(true) : deleteSyncQuestion(true)" label="Yes" class="p-button-text"></p-button>
        <p-button icon="pi pi-times" *ngIf="!loader" (click)="confirmationModal = false" label="No"></p-button>
    </ng-template>
</p-dialog>