<p-toast position="top-right"></p-toast>
<div class="eup_quiz_generator">
    <app-overlay-loader *ngIf="APIloader"></app-overlay-loader>
    <app-overlay-loader *ngIf="loader"></app-overlay-loader>
    <div class="generateQuiz_parent">
        <div class="genrateQuiz_wrapper">
            <div class="heading">
                Test Mode
            </div>
            <div class="generateQuiz_Switches">
                <!-- NEW SWITCH -->
                <div class="switch6">
                    <label class="switch6-light">
                        <input type="checkbox">
                        <span>
                            <span (click)="changeMode()" [ngClass]="[tutorMode == true ? 'white_color' : '']">Tutor
                                Mode</span>
                            <span (click)="changeMode()" [ngClass]="[tutorMode == true ? '' : 'white_color']">Exam
                                Mode</span>
                        </span>
                        <a class="btn btn-primary"></a>
                    </label>
                </div>
                <!-- NEW SWITCH ENDS -->
                <div class="single_switch">
                    <label class="switch">
                        <input type="checkbox">
                        <span class="slider round" (click)="changeTimeMode()"></span>
                    </label>
                    <div class="switch_text">Timed Mode</div>
                </div>
            </div>


            <div class="heading mt-5">
                Quiz Title
            </div>
            <div class="Quiz_title_text">
                <input type="text" [(ngModel)]="quizTitle"
                    style="min-width: 350px; padding-top: 8px; padding-bottom: 8px; padding-left: 15px;">
            </div>
            <div class="question_mode">
                <div class="switch6">
                    <label class="switch6-light">
                        <input type="checkbox">
                        <span>
                            <span (click)="changeQuestionMode()" [ngClass]="[questionMode == true ? 'white_color' : '']">Standard</span>
                            <span (click)="changeQuestionMode()" [ngClass]="[questionMode == true ? '' : 'white_color']">Custom</span>
                        </span>
                        <a class="btn btn-primary"></a>
                    </label>
                </div>
            </div>



            <div class="system_wrapper" *ngIf="!isCustomQuestion">
                <div class="heading heading_search_wrapper">
                    <div class="multiple_heading" >
                        <div [ngClass]="(IsCourses == true && i == 0)?'highlight_heading':(isdemo && IsSubjects == true && i == 1)||(!isdemo && IsSubjects == true && i == 2)? 'highlight_heading':(isdemo&&IsSystems == true && i == 2)||(!isdemo&&IsSystems == true && i == 3)? 'highlight_heading':(!isdemo&&IsTopics == true && i == 4)||(isdemo&&IsTopics == true && i == 3)? 'highlight_heading':IsQbank == true && i == 1? 'highlight_heading':''"
                            class="pl-1 pr-1 mr-3 heading_text" (click)="setHeading(i)"
                            *ngFor="let heading of headingArray; let i = index">{{heading}}</div>
                    </div>
                <div class="d-flex">
                    <div class="field-checkbox  mr-3" *ngIf="IsSubjects == true">
                        <p-checkbox (onChange)="selectAllCheckBox()" name="group1" value="Selected" [(ngModel)]="selectAll" inputId="ny" [disabled]="!category[0]"></p-checkbox>
                        <label class="pt-2" for="ny"> Select All</label>
                    </div>
                    <div class="field-checkbox  mr-3" *ngIf="IsSystems == true">
                        <p-checkbox (onChange)="selectAllCheckBox()" name="group1" value="Selected" [(ngModel)]="selectAll" inputId="ny" [disabled]="!category[1]"></p-checkbox>
                        <label class="pt-2" for="ny"> Select All</label>
                    </div>
                    <div class="field-checkbox  mr-3" *ngIf="IsTopics == true">
                        <p-checkbox (onChange)="selectAllCheckBox()" name="group1" value="Selected" [(ngModel)]="selectAll" inputId="ny" [disabled]="!category[2]"></p-checkbox>
                        <label class="pt-2" for="ny"> Select All</label>
                    </div>
                    <span class="p-input-icon-left pr-2" *ngIf="IsTopics == true">
                        <i class="pi pi-search"></i>
                        <input (keyup)="searchTopics()" type="text" pInputText placeholder="Search"
                            [(ngModel)]="searchText" />
                    </span>
                </div>
                </div>
                
                <div class="checkboxes" *ngIf="IsCourses == true">

                    <div *ngFor="let category of courses" class="p-field-checkbox">
                        <p-radioButton name="groupname" [value]="category" [(ngModel)]="selectedCourse"
                            (click)="filterTheQuestions(category, 'course')"></p-radioButton>
                        <!-- <p-checkbox name="group2" [value]="category"  [(ngModel)]="selectedCategories" (click)="getTotalQuestions(1, 1), 1"></p-checkbox> -->
                        <label [for]="category.key" class="category_label">{{category}}</label>
                        <!-- <div class="numberOfQuestions">9</div> -->
                    </div>
                </div>
                <div class="checkboxes" *ngIf="IsQbank == true">
                    <ng-container *ngFor="let category of qBank; let i=index">
                        <div *ngIf="category.Questions > 0" class="p-field-checkbox mt-1" >
                            <p-checkbox class="box" name="group5" [disabled]="category.Questions == 0"
                                [value]="category.Title" [(ngModel)]="selectedQbank"
                                (click)="filterTheQuestions(category.Title, 'qbank')"></p-checkbox>
                            <div class="text">
                                <label [ngClass]="(category.Questions == 0)?'disabledClrClass':''"
                                class="category">{{category.Title}}</label>
                            </div>
                        <label [ngClass]="(category.Questions == 0)?'disabledClrClass ':'labelNumber'"
                                class="category_label1">{{category.Questions}}</label>
                        </div>
                    </ng-container>
                </div>

                <div class="checkboxes" *ngIf="IsSubjects == true">
                    <ng-container *ngFor="let subject of subjects; let i=index">
                        <div *ngIf="subject.Questions > 0" class="p-field-checkbox mt-1">
                            <p-checkbox class="box" name="group2" [disabled]="subject.Questions == 0"
                                [value]="subject.Title" [(ngModel)]="selectedSubjects"
                                (click)="filterTheQuestions(subject, 'subject')"></p-checkbox>
                            <div class="text">
                                <label [ngClass]="(subject.Questions == 0)?'disabledClrClass':''"
                                class="category">{{subject.Title}}</label>
                            </div>
                            <label [ngClass]="(subject.Questions == 0)?'disabledClrClass ':'labelNumber'"
                                class="category_label1">{{subject.Questions}}</label>
                        </div>
                    </ng-container>
                </div>

                <div class="checkboxes" *ngIf="IsSystems == true">
                    <ng-container *ngFor="let system of systems; let i=index">
                        <div *ngIf="system.Questions > 0" class="p-field-checkbox mt-1">
                            <p-checkbox class="box" name="group2" [disabled]="system.Questions == 0" [value]="system.Title"
                                [(ngModel)]="selectedSystems" (click)="filterTheQuestions(system, 'system')">
                            </p-checkbox>
                            <div class="text">
                                <label [ngClass]="(system.Questions == 0)?'disabledClrClass':''"
                                class="category">{{system.Title}}</label>
                            </div>
    
                            <label [ngClass]="(system.Questions == 0)?'disabledClrClass ':'labelNumber'"
                                class="category_label1">{{system.Questions}}</label>
                        </div>
                    </ng-container>
                </div>

                <div class="checkboxes" *ngIf="IsTopics == true">
                    <ng-container *ngFor="let topic of topics; let i=index">
                        <div *ngIf="topic.Questions > 0" class="p-field-checkbox mt-1">
                            <p-checkbox class="box" name="group2" [disabled]="topic.Questions == 0" [value]="topic.Title"
                                [(ngModel)]="selectedTopics" (click)="filterTheQuestions(topic, 'topic')">
                            </p-checkbox>
                            <div class="text">
                                <label [ngClass]="(topic.Questions == 0)?'disabledClrClass':''"
                                class="category">{{topic.Title}}</label>
                            </div>
                            <label [ngClass]="(topic.Questions == 0)?'disabledClrClass ':'labelNumber'"
                                class="category_label1">{{topic.Questions}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="system_wrapper Question_difficulty_wrapper" *ngIf="!isCustomQuestion">
                <div class="left_Question_tabs">
                    <div class="heading Question_Heading">
                        <div class="pl-1 pr-3">Question Types</div>
                    </div>

                    <div class="Question_type_radiobtns">
                        <div *ngFor="let question of QuestionTypes" class="p-field-checkbox">
                            <p-radioButton [disabled]="question.name == Custom" [inputId]="question.key" name="questiontype" [value]="question"
                                [(ngModel)]="selectedQuestion" (click)="questionsFilteration(1,0)"></p-radioButton>
                            <label [for]="question.key">{{question.name}}</label>
                        </div>
                    </div>
                </div>
                <div class="right_difficulty_tab">
                    <div class="heading Question_Heading">
                        <div class="pl-1 pr-3">Difficulty</div>
                    </div>
                    <div class="progress_numbers">
                        <div class="single_num_progress" [ngClass]="diffOne == true? 'activeprogressNum': ''"
                            (click)="difficultylevel(1)">1</div>
                        <div class="single_num_progress" [ngClass]="diffTwo == true? 'activeprogressNum': ''"
                            (click)="difficultylevel(2)">2</div>
                        <div class="single_num_progress" [ngClass]="diffThree == true? 'activeprogressNum': ''"
                            (click)="difficultylevel(3)">3</div>
                        <div class="single_num_progress" [ngClass]="diffFour == true? 'activeprogressNum': ''"
                            (click)="difficultylevel(4)">4</div>
                        <div class="single_num_progress" [ngClass]="diffFive == true? 'activeprogressNum': ''"
                            (click)="difficultylevel(5)">5</div>
                    </div>
                </div>
            </div>
            <!-- CUSTOM QUESTIONS -->
            <div class="system_wrapper Question_difficulty_wrapper mb-5" *ngIf="isCustomQuestion">
                <div class="left_Question_tabs">
                    <div class="heading Question_Heading">
                        <div class="pl-1 pr-3">Instructions</div>
                    </div>

                    <div class="Question_type_instructions pl-2 pr-2">
                        This mode is intended to allow for faculty/group review; the faculty/group leader can designate
                        the question IDs to allow the entire group to create the same test within each subscription.
                        <br><br>

                        If you are a group member (not the faculty/group leader), you will need to enter the list of
                        question IDs provided by your group leader. Separate each question ID with a comma (,) without
                        spaces or other characters. <br>
                        If you were not provided a list of question IDs but were given a test ID instead, enter the test
                        ID and click “Retrieve Questions” to automatically generate the list of question IDs. <br>
                        You can enter only numbers or commas (,) in the space provided. Please do not enter spaces or
                        other characters as this will result in an error prompt. <br><br>

                        The maximum number of questions you can select is the same as the maximum number allowed per
                        block/test. <br><br>

                        You will not be able to include the question if it has been deactivated or if its ID is invalid.
                        <br><br>

                        Some questions are part of a question set and must be included within the same test. In such
                        cases, you must provide the question IDs for each question in the set. If you omit one or more
                        question IDs in the set, the software will not generate the test. Instead, it will provide a
                        list of question IDs that must be entered to complete the question set. <br><br>

                        If you are a group leader or member of a study group and need additional assistance using this
                        feature, please contact us at our facebook page.


                    </div>
                </div>
                <div class="right_difficulty_tab">
                    <div class="heading Question_Heading">
                        <div class="pl-1 pr-3">Question ID's</div>
                    </div>
                    <div class="quiz_id_wrapper mb-3">
                        <input type="text" pInputText [(ngModel)]="quizIdInput" placeholder="Test Id"> 
                        <button class="bt btn_retrieve" (click)="retrieveQuizQuestions()">Retrieve</button>
                    </div>
                    <div class="or_wrapper mb-3">
                        <h3>OR</h3>
                    </div>
                    <div class="progress_numbers">
                        <textarea rows="5" [(ngModel)]="inputQuestionIds"
                            pInputTextarea></textarea>
                    </div>
                    <div class="mt-3 message">
                        {{message}}
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="col-sm-12 pt-4 pb-4 message_Question_Number" *ngIf="!isCustomQuestion">
                <div [ngClass]="(numberOfQuestion>40)? 'redClr': ''"> <i class="pi pi-exclamation-circle mr-2"></i> You
                    cannot select more than 40 questions</div>
            </div>
            <div class="GenerateQuestionWrapper">
                <div class="genrateQuestions">
                    <div class="questiontext">Generate </div>
                    <div class="inputFieldForQuestions">
                        <!--  -->
                        <p-inputNumber (onInput)="changingQuesNumber($event)" [style]="{'width': '25px'}"
                            [(ngModel)]="numberOfQuestion" mode="decimal" [disabled]="isCustomQuestion"
                            [showButtons]="true" inputId="minmax-buttons" [min]="0" [max]="totalQuestion">
                        </p-inputNumber>
                    </div>
                    <div class="questiontext"> / </div>
                    <div class="questiontext questiontext_Number"> &nbsp; {{totalQuestion}} </div>
                    <div class="questiontext"> &nbsp; Questions</div>
                </div>
            </div>
            <div class="generateQuizBtnWrapper mb-5">
                <button
                    [disabled]="((numberOfQuestion>40 || numberOfQuestion==0 || numberOfQuestion > totalQuestion || loader)&&!isCustomQuestion)?true:false"
                    class="btn GenerateTestBtn" (click)="QuizGenerate()">Generate Test</button>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Warning" [(visible)]="displayInvalidModal" [modal]="true" [style]="{width: '450px'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="invalid_questions">
        <h4>Based on the subscription type, the following Question Ids are not accessed in your qbank.</h4>
        <ul>
            <li *ngFor="let question of invalidQuestions">{{ question }}</li>
        </ul>
        <h4 style="margin-top: 1rem;">Please remove these questions or replace them with other unused questions to generate the quiz.</h4>
    </div>
</p-dialog>

<p-toast position="top-right"></p-toast>