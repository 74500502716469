<div class="linked_wrapper">
  <div class="linked">
    <h1>Link Uworld Questions</h1>
      <form method="post">
        <input type="number" name="u" [(ngModel)]="systemQuestionId" placeholder="System Question ID" min="0" oninput="validity.valid||(value='');" required="required" />
        <input type="number" name="p" [(ngModel)]="uworldQuestionId" placeholder="UWorld Question ID" min="0" oninput="validity.valid||(value='');" required="required" />
        <button type="submit" (click)="linkQuestions()" class="btn btn-block btn-large" style="margin-top: 1rem;">Link</button>
        <div class="message" [ngClass]="(messageColor?'green':'red')">{{message}}</div>
      </form>
  </div>
  
  <div class="table">
  <p-table #dt1 [value]="linkedQuestions" [globalFilterFields]="['PostID', 'UWorldID']"
  responsiveLayout="scroll" [paginator]='true' [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="10">
      <ng-template pTemplate="caption">
          <div class="flex">
              <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'equals')" placeholder="Search by Question ID" />
              </span>
          </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
              <th>#</th>
              <th>System Question ID</th>
              <th>UWorld Question ID</th>
              <th style="text-align: center;">Actions</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-question let-i="rowIndex">
          <tr>
              <td>{{i+1}}</td>
              <td>{{question.PostID}}</td>
              <td>{{question.UWorldID}}</td>
              <td class="text-center">
                <i (click)="openEditModal(question)" class="pi pi-pencil" style="cursor: pointer;"></i>
                <i (click)="openDeleteModal(question)" class="pi pi-trash" style="cursor: pointer; margin-left: 1.25rem;"></i>
              </td>
          </tr>
      </ng-template>
  </p-table>
  </div>
  
  </div>
  
  
<p-dialog header="Edit Linked Question" [(visible)]="editLinkedModal" [modal]="true" [closable]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
  <div class="">
    <input type="number" name="u" [(ngModel)]="editSystemQuestionId" min="0" oninput="validity.valid||(value='');" required="required" />
    <input type="number" name="p" [(ngModel)]="editUworldQuestionId" min="0" oninput="validity.valid||(value='');" required="required" />
    <button type="submit" (click)="updateLinkedQuestion()" class="btn btn-block btn-large" style="margin-top: 1rem;">Update</button>
    <div class="message" *ngIf="message !== ''" [ngClass]="(messageColor?'green':'red')">{{message}}</div>
  </div>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="deleteModal" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Are you sure you want to delete this linked question?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="deleteLinkedQuestion()" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="deleteModal=false" label="No"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<app-overlay-loader *ngIf="loader"></app-overlay-loader>
